<div class="insurer-card" [ngClass]="first ? 'highlight' : ''">
  <img
    *ngIf="first && partnerCode == 'ps' && translate.currentLang === 'en'"
    class="cheapest-tag"
    src="assets/image/insurer-card-cheapest-tag.png"
  />
  <img
    *ngIf="first && partnerCode != 'ps' && translate.currentLang === 'en'"
    class="cheapest-tag"
    src="assets/image/insurer-card-cheapest-tag-affiliate.png"
  />
  <img
    *ngIf="first && partnerCode == 'ps' && translate.currentLang !== 'en'"
    class="cheapest-tag"
    src="https://storage.googleapis.com/ps-asset/affiliate/logo/Tag-bm.png"
  />
  <img
    *ngIf="first && partnerCode != 'ps' && translate.currentLang !== 'en'"
    class="cheapest-tag"
    src="https://storage.googleapis.com/ps-asset/affiliate/logo/Tag-bm.png"
  />
  <div class="insurer-card__header" [ngClass]="first ? 'highlight' : ''">
    <div class="insurer-card__header-logo">
      <img [src]="item?.image" />
    </div>
    <div class="insurer-card__pricing">
      <div>
        <span>RM</span>
        <span class="price-value" *ngIf="partnerCode == 'ps'">{{
          totalBasePremiumAfterRebate | number : "1.2-2"
        }}</span>
        <span class="price-value" *ngIf="partnerCode != 'ps'">{{
          item?.total | number : "1.2-2"
        }}</span>
      </div>

      <div
        class="rebate"
        [ngClass]="first ? 'highlight' : ''"
        *ngIf="item?.rebate"
      >
        <span>
          {{ "common.priceBeforeRebate" | translate }} :
          <span class="strikethrough"
            >RM{{ totalBasePremium | number : "1.2-2" }}</span
          >
        </span>
        <span class="rebate-note">
          {{ "common.epp" | translate }}
        </span>
      </div>

      <div class="sum-insured">
        <span
          >{{ "common.sumInsured" | translate }} ({{
            "common." + item.typeOfSum | translate
          }}) </span
        ><span>RM {{ item.sumInsured | number : "1.2-2" }}</span>
      </div>
    </div>
  </div>

  <div class="insurer-card__content">
    <div class="insurer-card__benefit">
      <div class="banefits-title">
        <h3>{{ "benefits.benefits" | translate | titlecase }}</h3>
        <span class="tnc-note">{{ "benefits.benefits_tnc" | translate }}</span>
      </div>
      <ul>
        <ng-container *ngFor="let benefit of benefits">
          <li
            *ngIf="
              hasTranslation(benefit.description) && benefit.type == 'driver'
            "
          >
            <img src="assets/image/protection-drivers.svg" />
            <span
              >{{ "benefits.protectedDriver" | translate }}:
              <b class="color-dark-green">{{
                benefit.description | translate
              }}</b>
            </span>
          </li>

          <li
            *ngIf="
              hasTranslation(benefit.description) && benefit.type == 'towing'
            "
          >
            <img src="assets/image/towing-service.svg" />

            <span
              >{{ "benefits.towingService" | translate }}
              <b class="color-dark-green">{{
                benefit.description | translate
              }}</b></span
            >
          </li>

          <li
            *ngIf="
              hasTranslation(benefit.description) &&
              benefit.type == '24hrTowing'
            "
          >
            <img src="assets/image/towing-service.svg" />

            <span
              >{{ "insurerDetails.24hrTowingService" | translate }}
              <b class="color-dark-green">{{
                benefit.description | translate
              }}</b></span
            >
          </li>

          <li
            *ngIf="
              hasTranslation(benefit.description) && benefit.type == 'free'
            "
          >
            <mat-icon class="color-dark-green">check_circle</mat-icon>
            <span
              ><b class="color-dark-green">{{ "benefits.free" | translate }}</b>
              {{ benefit.description | translate }}</span
            >
          </li>

          <li
            *ngIf="
              hasTranslation(benefit.description) && benefit.type == 'check'
            "
          >
            <mat-icon class="color-dark-green">check_circle</mat-icon>
            <span>{{ benefit.description | translate }}</span>
          </li>

          <li *ngIf="hasTranslation(benefit.description) && benefit.type == ''">
            <mat-icon class="color-yellow">star</mat-icon>
            <span>{{ benefit.description | translate }}</span>
          </li>

          <li
            *ngIf="
              hasTranslation(benefit.description) &&
              benefit.type == 'sectionTitle'
            "
          >
            <span>{{ benefit.description | translate }}</span>
          </li>
        </ng-container>
      </ul>

      <button
        class="btn-text btn-text--blue mb-sm"
        (click)="toggleMoreBenefits()"
        *ngIf="totalBenefits > 4"
      >
        {{
          showMoreBenefits
            ? ("benefits.lessBenefits" | translate)
            : ("benefits.seeAll" | translate) +
              totalBenefits +
              " " +
              ("benefits.benefits" | translate)
        }}
        ...
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="insurer-card__footer">
    <button mat-flat-button class="full-btn" (click)="handleBuyNow()">
      {{ "comparison.buyNow" | translate }}
    </button>
    <div class="mb-md">
      <div
        class="instalment-note"
        *ngIf="eppEligibility && eppEligibility.isEligible"
      >
        {{ "common.pay" | translate }}
        <b
          >RM{{
            totalBasePremiumAfterRebate / eppEligibility.months
              | number : "1.2-2"
          }}</b
        >
        <span> {{ "common.everyMonth" | translate }}</span>
        <span>
          {{
            eppEligibility.months == 6
              ? ("common.duration6" | translate)
              : ("common.duration12" | translate)
          }}
          <br
        /></span>
        <span>
          {{ "common.epp" | translate }}
        </span>
      </div>
      <div class="rebate-note" *ngIf="item?.rebate">
        <img src="assets/image/rebate-crown-icon.png" />
        {{ "common.includedRebate" | translate }}
        <b>RM{{ item?.rebate | number : "1.2-2" }}</b>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div class="insurer-card__documents">
      <ng-container
        *ngFor="let doc of documents; let i = index; let last = last"
      >
        <a class="btn-text" [href]="doc.url" target="_blank">
          <mat-icon>open_in_new</mat-icon>
          <span>{{ doc.name }}</span>
        </a>
        <span *ngIf="!last" class="document-divider">|</span></ng-container
      >
    </div>
  </div>
</div>

<div
  class="main-container"
  [ngStyle]="showSuccessGif ? { 'min-width': '0px', 'min-height': '0px' } : {}"
  [ngClass]="
    stepper?.selectedIndex === 1 && !isCompany ? 'main-container-step-2' : ''
  "
>
  <ng-container *ngIf="quotationSubmitted; else notSubmitted">
    <app-loading-quotation
      [inputData]="quotationInputData"
      [formData]="quotationFormData"
      (resetFlag)="updateResetFlag($event)"
    ></app-loading-quotation>
  </ng-container>

  <ng-template #notSubmitted>
    <ng-container *ngIf="showSuccessGif; else hideSuccessGif">
      <div class="gif-loading-container">
        <div class="gif-loading-view">
          <img
            *ngIf="successGifType == 'Email'"
            src="/assets/gif/EMAIL--Searching-Insurer-For-You-19.gif"
            alt=""
          />
          <img
            *ngIf="successGifType == 'Mobile'"
            src="/assets/gif/WA--Searching-Insurer-For-You-19.gif"
            alt=""
          />
          <div class="gif-loading-view__title">
            {{ "common.findingTheBestPrice" | translate }}
          </div>
          <div class="gif-loading-view__body-text">
            <span *ngIf="successGifType == 'Email'">
              {{ "dashboard.preferredChannelEmail" | translate }}
            </span>
            <span *ngIf="successGifType == 'Mobile'">
              {{ "dashboard.preferredChannelMobile2" | translate }}
            </span>
            <br />
            <span style="font-weight: bold">{{ contactInfo }}</span>
            <br />
            {{ "dashboard.preferredChannelDuration" | translate }}
          </div>
          <button
            class="gif-loading-view__button btn--yellow"
            (click)="onHomepage()"
            mat-flat-button
          >
            {{ "common.homePage" | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #hideSuccessGif>
      <div class="forms-container">
        <div class="forms">
          <div
            class="forms__back-button"
            *ngIf="stepper.selectedIndex === 1"
            (click)="stepper.previous()"
            [style.pointer-events]="isLoading ? 'none' : 'auto'"
          >
            <img src="/assets/image/stepper-back-button.png" alt="" />
            <span> {{ "common.back" | translate }} </span>
          </div>
          <div class="forms__title">
            <span *ngIf="stepper.selectedIndex === 0">
              {{ "dashboard.titleNew" | translate }}
            </span>
            <span *ngIf="stepper.selectedIndex === 1">
              {{ "dashboard.title3New" | translate }}
            </span>
          </div>

          <mat-stepper #stepper linear>
            <!-- Vehicle Form -->
            <mat-step [stepControl]="vehicleFormGroup">
              <form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
                <div class="cta-container">
                  <div class="cta-banner">
                    <ng-container *ngIf="mobileView; else notMobile">
                      <ng-container *ngFor="let banner of availableBanners">
                        <ng-container
                          *ngIf="
                            banner.refType.startsWith('mobile_long') &&
                            translate.currentLang == banner.language
                          "
                        >
                          <img [src]="banner.imgUrl" [alt]="banner.imgUrl" />
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-template #notMobile>
                      <ng-container *ngFor="let banner of availableBanners">
                        <ng-container
                          *ngIf="
                            banner.refType.startsWith('desktop_long') &&
                            translate.currentLang == banner.language
                          "
                        >
                          <img [src]="banner.imgUrl" [alt]="banner.imgUrl" />
                        </ng-container>
                      </ng-container>
                    </ng-template>
                    <ng-container *ngIf="!availableBanners.length">
                      <ng-container *ngFor="let x of customBanner.ctaBanner">
                        <img
                          [src]="x.src"
                          [alt]="x.src"
                          *ngIf="translate.currentLang == x.lang"
                        />
                      </ng-container>
                    </ng-container>
                  </div>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.typeOfInsurance" | translate
                  }}</mat-label>
                  <mat-select
                    disableOptionCentering
                    formControlName="insuranceType"
                    required
                  >
                    <mat-option
                      *ngFor="let item of insuranceTypeList"
                      [value]="item.id"
                    >
                      {{ item.description | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    >{{ "common.typeOfInsurance" | translate }}
                    {{
                      vehicleFormGroup.controls.insuranceType.errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <mat-icon
                  class="tooltip-icon"
                  [matMenuTriggerFor]="typeTooltip"
                  #typeTrigger="matMenuTrigger"
                  (mouseenter)="typeTrigger.openMenu()"
                >
                  info
                </mat-icon>
                <mat-menu
                  #typeTooltip="matMenu"
                  [overlapTrigger]="false"
                  class="tooltip-popover dashboard"
                >
                  <span (mouseleave)="typeTrigger.closeMenu()">
                    <div class="title">
                      {{ "common.COM" | translate }}
                    </div>
                    {{ "common.comprehensiveInsDesc" | translate }}
                    <br />
                    <br />
                    <div class="title">
                      {{ "common.TPFT" | translate }}
                    </div>
                    {{ "common.fireTheftInsDesc" | translate }}
                  </span>
                </mat-menu>

                <mat-form-field appearance="outline" class="upperc-input">
                  <mat-label>{{ "common.carPlateNo" | translate }}</mat-label>
                  <input
                    matInput
                    required
                    formControlName="carPlate"
                    mask="A{255}"
                    [validation]="false"
                    autocomplete="off"
                  />
                  <mat-error
                    *ngIf="vehicleFormGroup.get('carPlate').errors?.required"
                  >
                    {{ "common.carPlateNo" | translate }}
                    {{
                      vehicleFormGroup.controls.carPlate.errors
                        | inputError
                        | translate
                    }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                  <ngx-mat-intl-tel-input
                    [enableSearch]="true"
                    [preferredCountries]="['my']"
                    formControlName="mobileNo"
                    [required]="true"
                  >
                  </ngx-mat-intl-tel-input>
                  <mat-error
                    *ngIf="vehicleFormGroup.get('mobileNo').errors?.required"
                    >{{ "common.mobileNo" | translate }}
                    {{
                      vehicleFormGroup.get("mobileNo").errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{ "common.emailAddress" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="email"
                    autocomplete="email"
                  />
                  <mat-error
                    *ngIf="vehicleFormGroup.controls.email?.errors?.email"
                  >
                    {{ "error.invalidEmail" | translate }}</mat-error
                  >
                  <mat-error
                    *ngIf="vehicleFormGroup.get('email').errors?.required"
                    >{{ "common.emailAddress" | translate }}
                    {{
                      vehicleFormGroup.get("email").errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <!-- Details for foreigner only -->
                <mat-form-field appearance="outline" *ngIf="!isMalaysian">
                  <mat-label>{{ "common.nationality" | translate }}</mat-label>
                  <mat-select formControlName="nationality">
                    <mat-option>
                      <ngx-mat-select-search
                        [placeholderLabel]="'common.nationalityPH' | translate"
                        [ngModelOptions]="{ standalone: true }"
                        [searching]="!countryList.length"
                        noEntriesFoundLabel="{{
                          'common.nationalityXrecord' | translate
                        }}"
                        (ngModelChange)="filterCountry($event)"
                        ngModel
                        ngDefaultControl
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let item of filterCountryList"
                      [value]="item"
                    >
                      {{ item }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    >{{ "common.nationality" | translate }}
                    {{
                      vehicleFormGroup.controls.nationality.errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{ getNameLabel() | translate }}</mat-label>
                  <input matInput autocomplete="name" formControlName="name" />
                  <mat-error
                    >{{ getNameLabel() | translate }}
                    {{
                      vehicleFormGroup.get("name").errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <div class="forms__identity">
                  <div class="forms__identity__dropdown">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="psIdentityType">
                        <mat-option
                          *ngFor="let item of idTypeList"
                          [value]="item.id"
                        >
                          {{ item.name | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="forms__identity__input">
                    <ng-container
                      *ngIf="
                        vehicleFormGroup.get('psIdentityType').value !== 4;
                        else policeInput
                      "
                    >
                      <mat-form-field appearance="outline">
                        <mat-label>{{
                          getIdentityNoLabel() | translate
                        }}</mat-label>
                        <input
                          matInput
                          required
                          formControlName="psIdentityNo"
                          [mask]="getIdentityNoMasking()"
                          autocomplete="off"
                          [maxLength]="isMalaysian && !isCompany ? 12 : 99"
                        />
                        <mat-error
                          >{{ getIdentityNoLabel() | translate }}
                          {{
                            vehicleFormGroup.controls.psIdentityNo.errors
                              | inputError
                              | translate
                          }}</mat-error
                        >
                      </mat-form-field>
                    </ng-container>

                    <ng-template #policeInput>
                      <mat-form-field appearance="outline">
                        <mat-label>{{
                          "common.policeOrArmyIcNo" | translate
                        }}</mat-label>
                        <input matInput formControlName="psPolicIdentityNo" />
                      </mat-form-field>
                    </ng-template>
                  </div>
                </div>

                <!-- Selected Identity Type = Police/Army -->
                <mat-form-field
                  appearance="outline"
                  *ngIf="vehicleFormGroup.get('psIdentityType').value === 4"
                >
                  <mat-label>{{ "common.icNoLabel" | translate }}</mat-label>
                  <input
                    matInput
                    formControlName="psIdentityNo"
                    [mask]="getIdentityNoMasking()"
                    autocomplete="off"
                    [maxLength]="12"
                  />
                </mat-form-field>

                <!-- 
                  Show dob and gender if selected identity type :-
                  1) Malaysian
                  2) Police / Army
                  3) Commercial (Individual)
                -->
                <ng-container
                  *ngIf="
                    [1, 4, 5].includes(
                      vehicleFormGroup.get('psIdentityType').value
                    ) && vehicleFormGroup.get('psIdentityNo').valid
                  "
                >
                  <div class="demographic">
                    <div>
                      {{ "common.dob" | translate }}:
                      <span class="color-black"
                        >{{ vehicleFormGroup.value.dob | date : "dd MMM yyyy" }}
                      </span>
                    </div>

                    <div>
                      {{ "common.gender" | translate }}:
                      <span class="color-black">{{
                        vehicleFormGroup.value.gender === genderOption.male
                          ? ("common.M" | translate)
                          : vehicleFormGroup.value.gender ===
                            genderOption.female
                          ? ("common.F" | translate)
                          : ""
                      }}</span>
                    </div>
                  </div>
                </ng-container>

                <mat-form-field appearance="outline" *ngIf="!isMalaysian">
                  <mat-label>{{ "common.dob" | translate }}</mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    formControlName="dob"
                    readonly
                    (click)="picker.open()"
                    [max]="maxDob"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    >{{ "common.dob" | translate }}
                    {{
                      vehicleFormGroup.controls.dob.errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>

                <div class="box-selection" *ngIf="!isMalaysian">
                  <!-- Foreigner -->
                  <div>
                    <mat-label>{{ "common.gender" | translate }}</mat-label>
                    <mat-radio-group
                      class="b2c-radio"
                      aria-label="Select an option"
                      formControlName="gender"
                    >
                      <mat-radio-button [value]="genderOption.male"
                        >{{ "common.M" | translate }}
                      </mat-radio-button>
                      <mat-radio-button [value]="genderOption.female"
                        >{{ "common.F" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.residentialPostcode" | translate
                  }}</mat-label>
                  <input
                    matInput
                    required
                    formControlName="psPostCode"
                    mask="00000"
                    autocomplete="postal-code"
                  />
                  <mat-error>
                    {{ "error.invalidPostcode" | translate }}
                  </mat-error>
                </mat-form-field>

                <section class="mt-sm">
                  <mat-checkbox
                    [checked]="pdpaAgreement"
                    (change)="pdpaAgreement = !pdpaAgreement"
                    class="checkbox-align-top dashboardCheckbox"
                    [disabled]="isLoading"
                  >
                    <span class="color-info">
                      {{ "common.pdpa" | translate }}
                    </span>

                    <a
                      [href]="'common.pdpaHref' | translate"
                      target="blank"
                      style="text-decoration: none; color: #000"
                      [ngClass]="pdpaAgreement ? 'secondary-color' : ''"
                    >
                      <span>
                        {{ "common.pdpaLink" | translate }}
                      </span>
                    </a>
                  </mat-checkbox>
                </section>

                <button
                  class="forms__next-btn btn--yellow"
                  mat-flat-button
                  matStepperNext
                  [disabled]="vehicleFormGroup.invalid || !pdpaAgreement"
                  (click)="submitInsight()"
                >
                  {{ "common.next" | translate }}
                </button>
              </form>
            </mat-step>

            <!-- Customer Form -->
            <mat-step [stepControl]="custFormGroup">
              <form
                [formGroup]="custFormGroup"
                (ngSubmit)="submitQuotation()"
                #custForm="ngForm"
              >
                <div
                  class="forms__checkbox-container"
                  *ngIf="
                    vehicleFormGroup.get('psIdentityType').value === 1 &&
                    vehicleFormGroup.get('insuranceType').value === 'COM'
                  "
                >
                  <div>
                    <mat-label
                      >{{ "common.carUsedForEHailing" | translate }}.
                    </mat-label>
                    <mat-radio-group
                      class="b2c-radio ehailing-radio"
                      formControlName="eHailingUsed"
                    >
                      <mat-radio-button [value]="true"
                        >{{ "common.yes" | translate }}
                      </mat-radio-button>
                      <mat-radio-button [value]="false"
                        >{{ "common.no" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>

                <div class="box-selection">
                  <div *ngIf="!isCompany">
                    <mat-label
                      >{{ "common.maritalStatus" | translate }}:</mat-label
                    >
                    <mat-radio-group
                      class="b2c-radio"
                      aria-label="Select an option"
                      formControlName="maritalStatus"
                    >
                      <mat-radio-button [value]="maritalStatusOption.single"
                        >{{ "common.Single" | translate }}
                      </mat-radio-button>
                      <mat-radio-button [value]="maritalStatusOption.married"
                        >{{ "common.Married" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div>
                    <mat-label
                      >{{ "common.preferredContact" | translate }}:</mat-label
                    >
                    <mat-radio-group
                      class="b2c-radio"
                      aria-label="Select an option"
                      formControlName="contactMethod"
                    >
                      <mat-radio-button value="Email"
                        >{{ "common.email" | translate }}
                      </mat-radio-button>
                      <mat-radio-button value="Mobile"
                        >{{ "common.WhatsApp" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>

                  <div
                    *ngIf="
                      [5, 6].includes(
                        vehicleFormGroup.get('psIdentityType').value
                      )
                    "
                    class="upload-car-grant"
                  >
                    <div>
                      <input
                        id="carGrant"
                        name="carGrant"
                        type="file"
                        [accept]="acceptFile"
                        (change)="handleFileInput($event)"
                        style="display: none"
                      />

                      <div class="mdDesc-f bold">
                        {{ "dashboard.uploadCarGrant" | translate }}
                      </div>

                      <button mat-stroked-button type="button">
                        <label for="carGrant" class="upload-button">
                          <mat-icon color="primary">add_circle</mat-icon
                          >{{ "common.upload" | translate }}
                        </label>
                      </button>
                    </div>

                    <div *ngIf="carGrant">
                      <div>
                        <span class="underlined-blue">
                          {{ carGrant.name }}
                        </span>
                        <button mat-icon-button (click)="deleteFile()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                      <div class="uploaded-status">
                        <img src="/assets/image/tick-icon.png" />
                        <div class="smTitle-f color-dark-green">
                          {{ "common.completed" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="forms__submit-container">
                  <button
                    class="forms__submit-container__btn btn--yellow"
                    mat-flat-button
                    [disabled]="
                      vehicleFormGroup.invalid ||
                      custFormGroup.invalid ||
                      ([5, 6].includes(
                        vehicleFormGroup.get('psIdentityType').value
                      ) &&
                        !carGrant) ||
                      isLoading
                    "
                  >
                    <div>
                      {{ "common.findTheBestPrice" | translate }}
                    </div>
                    <mat-icon *ngIf="isLoading"
                      ><mat-spinner diameter="20"></mat-spinner
                    ></mat-icon>
                  </button>
                </div>
              </form>
            </mat-step>
          </mat-stepper>
        </div>
      </div>

      <div class="swiper-container">
        <swiper #swiper [config]="swiperOptions">
          <!-- Custom banner  -->
          <ng-container *ngIf="mobileView; else notMobileView">
            <ng-container *ngFor="let banner of availableBanners">
              <ng-template
                *ngIf="
                  banner.refType.startsWith('mobile_square') &&
                  translate.currentLang == banner.language
                "
                swiperSlide
              >
                <img swiperSlide [src]="banner.imgUrl" [alt]="banner.imgUrl" />
              </ng-template>
            </ng-container>
            <ng-container *ngFor="let x of customBanner.mobileMainBanner">
              <ng-template
                *ngIf="
                  !availableBanners.length && translate.currentLang == x.lang
                "
                swiperSlide
              >
                <img
                  swiperSlide
                  [src]="x.src"
                  [alt]="x.src"
                  *ngIf="translate.currentLang == x.lang"
                />
              </ng-template>
            </ng-container>
          </ng-container>
          <ng-template #notMobileView>
            <ng-container *ngFor="let banner of availableBanners">
              <ng-template
                *ngIf="
                  banner.refType.startsWith('desktop_square') &&
                  translate.currentLang == banner.language
                "
                swiperSlide
              >
                <img swiperSlide [src]="banner.imgUrl" [alt]="banner.imgUrl" />
              </ng-template>
            </ng-container>
            <ng-container *ngFor="let x of customBanner.mainBanner">
              <ng-template
                swiperSlide
                *ngIf="
                  !availableBanners.length && translate.currentLang == x.lang
                "
              >
                <img
                  swiperSlide
                  [src]="x.src"
                  [alt]="x.src"
                  *ngIf="translate.currentLang == x.lang"
                />
              </ng-template>
            </ng-container>
          </ng-template>

          <!-- Normal banners  -->
          <ng-container *ngFor="let banner of banners">
            <ng-template
              *ngIf="translate.currentLang === banner.lang && banner.show"
              swiperSlide
            >
              <img
                (error)="banner.show = false"
                [src]="banner.src"
                [alt]="banner.alt"
              />
            </ng-template>
          </ng-container>
        </swiper>
      </div>
    </ng-template>
  </ng-template>
</div>

<div class="line-separator">
  <mat-divider></mat-divider>
</div>

<div class="usp-section">
  <p class="usp-section__heading">
    <span class="usp-section--desktop">{{
      "dashboard.uspTitle" | translate
    }}</span>
    <span class="usp-section--mobile">{{
      "dashboard.uspTitle" | translate
    }}</span>
  </p>
  <p class="usp-section__subheading">
    <span class="usp-section--desktop">{{
      "dashboard.uspTitle2" | translate
    }}</span>
    <span class="usp-section--mobile">{{
      "dashboard.uspTitle2" | translate
    }}</span>
  </p>

  <div class="usp-section__row">
    <div class="text-center" *ngFor="let usp of uspList">
      <img [src]="usp.icon" alt="" />
      <p class="usp-section__row__title">{{ usp.title | translate }}</p>
      <p class="usp-section__row__desc">{{ usp.desc | translate }}</p>
    </div>
  </div>
</div>

<div class="testimonial-section">
  <p class="testimonial-section__title">
    {{ "dashboard.testimonialTitle" | translate }}
  </p>
  <p class="testimonial-section__title">
    {{ "dashboard.testimonialTitle2" | translate }}
  </p>

  <a
    href="https://www.google.com/maps/place/PolicyStreet+-+making+insurance+purposeful+and+simple/@3.1140979,101.6615233,17z/data=!4m8!3m7!1s0x31cc49822dc77c3f:0x5b805916b9922ba8!8m2!3d3.1140979!4d101.6640982!9m1!1b1!16s%2Fg%2F11grvjz1bs?entry=ttu&g_ep=EgoyMDI0MTAyMC4xIKXMDSoASAFQAw%3D%3D"
    target="_blank"
    rel=""
  >
    <div class="testimonial-section__link">
      <p class="testimonial-section__ratings">4.9</p>
      <img src="/assets/icons/landing/5stars.png" alt="" />
      <p>2,300+ {{ "dashboard.review" | translate }}</p>
    </div>
  </a>

  <div class="swiper-container">
    <swiper
      class="multiple"
      [slidesPerView]="4"
      [slidesPerGroup]="1"
      [grabCursor]="true"
      [spaceBetween]="40"
      [autoplay]="{
        delay: 8000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }"
      [pagination]="false"
      [navigation]="true"
      [breakpoints]="{
        '300': {
          slidesPerView: 1
        },
        '600': {
          slidesPerView: 2,
          spaceBetween: 150
        },
        '800': {
          slidesPerView: 2,
          spaceBetween: 100
        },
        '900': {
          slidesPerView: 3,
          spaceBetween: 180
        },
        '1000': {
          slidesPerView: 3,
          spaceBetween: 110
        },
        '1200': {
          slidesPerView: 4,
          spaceBetween: 150
        },
        '1300': {
          slidesPerView: 4,
          spaceBetween: 40
        }
      }"
    >
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-1.png"
          alt="d2c-google-review-1"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-2.png"
          alt="d2c-google-review-2"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-3.png"
          alt="d2c-google-review-3"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-4.png"
          alt="d2c-google-review-4"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-5.png"
          alt="d2c-google-review-5"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-6.png"
          alt="d2c-google-review-6"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-7.png"
          alt="d2c-google-review-7"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-8.png"
          alt="d2c-google-review-8"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-9.png"
          alt="d2c-google-review-9"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-10.png"
          alt="d2c-google-review-10"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-11.png"
          alt="d2c-google-review-11"
        />
      </ng-template>
      <ng-template swiperSlide>
        <img
          src="https://storage.googleapis.com/ps-asset/reviews/d2c-google-review-12.png"
          alt="d2c-google-review-12"
        />
      </ng-template>
    </swiper>
  </div>
</div>

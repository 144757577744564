import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PsxtraTagComponent } from '../../psxtra-tag/psxtra-tag.component';
import { FileFunction } from 'src/app/share/function/file.function';

@Component({
  selector: 'app-view-details-dialog',
  templateUrl: './view-details-dialog.component.html',
  styleUrls: ['./view-details-dialog.component.scss']
})
export class ViewDetailsDialogComponent implements OnInit {
  title = "";
  brochure = "";
  brochureMobile = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string;
      brochure: string;
      brochureMobile: string;
    },
  ) { 
    if (data.title) this.title = data.title;
    if (data.brochure) this.brochure = data.brochure;
    if (data.brochureMobile) this.brochureMobile = data.brochureMobile;
  }

  ngOnInit(): void {
  }

  onDownload(pdfUrl: string): void {
    FileFunction.downloadPdf(pdfUrl, 'PSXTRA-Brochure');
  }
}

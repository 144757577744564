// Angular Core
import {
  Component,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators
} from "@angular/forms";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Observable, Subscription, tap } from "rxjs";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
// Services
import { AlertService } from "../../../share/service/alert.service";
import { MasterDataDataService } from "../../../share/data-service/master-data.data.service";
import {
  CheckDuplicateRequest,
  CheckDuplicateResponse,
  MotorQuotationDataService
} from "../../../share/data-service/motor/motor-quotation.data.service";
import { PartnerService } from "src/app/share/service/partner.service";
// Interfaces
import { MotorQuotationInterface } from "../../../share/interface/motor-quotation.interface";
import { DropdownItem } from "../../../share/interface/common.interface";
// Components
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { SubmitFailDialogComponent } from "src/app/dashboard/submit-fail-dialog/submit-fail-dialog.component";
import { BeforeExpiredDialogComponent } from "../../../share/component/before-expired-dialog/before-expired-dialog.component";
// Utilities
import SwiperCore, {
  Pagination,
  Autoplay,
  Navigation,
  SwiperOptions
} from "swiper";
import { SwiperComponent } from "swiper/angular";
import { Overlay } from "@angular/cdk/overlay";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ValidatorFunction } from "../../../share/function/validator.function";
import { INSURED_TYPE } from "src/app/share/constants/common.types";
import { RequestStatusDialogComponent } from "src/app/d2c/ui/request-status-dialog/request-status-dialog.component";
import * as moment from "moment";
import { FileService } from "src/app/share/service/file.service";
import { SubmitSuccessAvDialogComponent } from "src/app/share/component/submit-success-av-dialog/submit-success-av-dialog.component";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
  selector: "app-affiliates-landing",
  templateUrl: "./affiliates-landing.component.html",
  styleUrls: ["./affiliates-landing.component.scss"]
})
export class AffiliateslandingComponent implements OnInit, OnDestroy {
  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("swiper", { static: true }) swiper: SwiperComponent;
  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    grabCursor: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    pagination: {
      clickable: true
    }
  };

  partnerCode: string;
  bannerView: boolean = false;
  partnerBannerViewList = ["carsome", "wapcar", "grab", "ktmb"];

  @ViewChild("vehicleForm") vehicleForm: NgForm;
  @ViewChild("custForm") custForm: NgForm;
  vehicleFormGroup: FormGroup;
  custFormGroup: FormGroup;

  countryList: string[] = [];
  filterCountryList: string[] = [];

  isCompany: boolean = false;
  isMalaysian: boolean = true;
  pdpaAgreement: boolean = false;
  mobileView: boolean = false;

  banners: Banner[];
  loadedBanners: number = 0;

  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  maxDob: Date = new Date();
  contactInfo: string;
  successGifType: string;

  quotationSubmitted: boolean = false;
  quotationInputData: MotorQuotationInterface;
  quotationFormData: any;
  validPostcodes: string[] = [];
  mileagePlans: DropdownItem[] = [];

  idTypeList: DropdownItem[] = [
    { id: 1, description: "common.icNo" },
    { id: 2, description: "common.foreigner" },
    { id: 3, description: "common.company" }
  ];

  typeOfInsuranceList = [
    { id: "TPFT", name: "common.TPFT" },
    { id: "COM", name: "common.COM" }
  ];

  showWhatsappBtn: Boolean = true;
  hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist", "ktmb"];

  showMileagePlans: Boolean = false;
  showMileagePlansList: string[] = ["ktmb"];

  showAgreeOrMarketValueBtn: Boolean = false;
  showAgreeOrMarketValueBtnList: string[] = [
    "wapcar",
    "carsome",
    "carlist",
    "ktmb"
  ];

  showEHailingOption: Boolean = true;
  hideEHailingOptionList: string[] = ["ktmb"];

  typeOfSumOption = INSURED_TYPE;

  carGrant: any;
  acceptFile: string = ".pdf,.png,.jpg,.jpeg";
  maxLength: number = 1; // Maximum number of files
  fileSize: number = 10; // Maximum file size
  fileList: File[] | any = [];

  subscriptions: Subscription[] = [];

  constructor(
    gtmService: GoogleTagManagerService,
    public translate: TranslateService,
    public overlay: Overlay,
    private formBuilder: FormBuilder,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private partnerService: PartnerService,
    private masterDataDataService: MasterDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private fileService: FileService
  ) {
    gtmService.pushTag({
      event: "car_lp"
    });
    this.getParams();
    this.initForm();
    this.listenChanges();
  }

  ngOnInit(): void {
    if (window.screen.width < 960) {
      this.mobileView = true;
    }

    if (this.showMileagePlans) {
      this.vehicleFormGroup
        .get("mileagePlan")
        .setValidators([Validators.required]);
      this.vehicleFormGroup.get("mileagePlan").updateValueAndValidity();
    }

    if (this.partnerCode === "ktmb") {
      this.idTypeList.splice(2);
      this.vehicleFormGroup.get("insuranceType").disable();
    }

    if (this.partnerCode === "carsome") {
      this.idTypeList.push(
        { id: 4, description: "common.commercialInd" },
        { id: 5, description: "common.commercialComp" }
      );
    }

    this.setMalaysian();
    this.fetchMasterData();
  }

  updateResetFlag(value: boolean): void {
    if (!value) return;

    this.vehicleFormGroup
      .get("insuranceType")
      .patchValue(this.typeOfInsuranceList[0].id);

    this.quotationSubmitted = false;
  }

  private getParams(): void {
    let snapshotParam = this.activatedRoute.snapshot.queryParams;
    let paramsPartnerCode = snapshotParam.partnerCode;
    let lang: string = snapshotParam.lang;

    this.partnerCode = this.partnerService.getPartnerCode();
    this.initPartnerBanners();
    if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
      this.showWhatsappBtn = false;
    }

    if (this.showMileagePlansList.includes(this.partnerCode)) {
      this.showMileagePlans = true;
    }

    if (this.partnerBannerViewList.includes(this.partnerCode)) {
      this.bannerView = true;
    }

    if (this.showAgreeOrMarketValueBtnList.includes(this.partnerCode)) {
      this.showAgreeOrMarketValueBtn = true;
    }

    if (this.hideEHailingOptionList.includes(this.partnerCode)) {
      this.showEHailingOption = false;
    }

    !this.partnerCode ? (window.location.href = "/") : null;

    if (lang) this.translate.use(lang);
    this.utmSource = snapshotParam.utm_source;
    this.utmMedium = snapshotParam.utm_medium;
    this.utmCampaign = snapshotParam.utm_campaign;
    this.utmContent = snapshotParam.utm_content;

    if (!this.utmSource) {
      let searchEngineList = ["google", "yahoo", "bing"];
      let referrer = document.referrer;
      if (
        referrer != window.location.href ||
        referrer.includes("policystreet.com")
      ) {
        let searchEngine = searchEngineList.find((x) =>
          referrer.toLowerCase().includes(x)
        );
        if (searchEngine) {
          this.utmSource = searchEngine;
          this.utmMedium = "organic";
        } else {
          if (!referrer) this.utmSource = "direct";
          else {
            let url = new URL(referrer);
            this.utmSource = url.hostname;
            this.utmMedium = "referral";
          }
        }
      } else {
        this.utmSource = "direct";
      }
    }

    // if coming from affiliates with domain names, remove route name
    if (!paramsPartnerCode) {
      this.removeUrlRouteName();
    }
  }

  private initForm(): void {
    this.vehicleFormGroup = this.formBuilder.group({
      insuranceType: ["COM", Validators.required],
      carPlate: [null, Validators.required],
      postcode: [
        null,
        [
          Validators.required,
          (control: FormControl) => this.postcodeValidator(control)
        ]
      ],
      insuredType: ["MV", Validators.required],
      includeRoadTax: [false],
      eHailingUsed: [false],
      mileagePlan: [null]
    });

    this.custFormGroup = this.formBuilder.group({
      idType: [this.idTypeList[0].id],
      identityNo: [null, Validators.required],
      name: [null, Validators.required],
      policIdentityNo: [null],
      nationality: [null, Validators.required],
      dob: [null],
      gender: [null],
      maritalStatus: ["Single", Validators.required],
      contactMethod: ["Email"],
      mobileNo: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });
  }

  listenChanges(): void {
    this.subscriptions.push(
      this.custFormGroup.get("identityNo").valueChanges.subscribe((value) => {
        if (this.custFormGroup.get("identityNo").invalid) {
          ["dob", "gender"].forEach((key) => {
            this.custFormGroup.get(key).reset();
          });
        }

        if (
          this.custFormGroup.get("identityNo").valid &&
          this.isMalaysian &&
          !this.isCompany
        ) {
          this.getGenderAndDate(value);
        }
      }),

      this.custFormGroup.get("idType").valueChanges.subscribe((value) => {
        switch (value) {
          case 1:
          case 4:
            this.setMalaysian();
            break;

          case 2:
            this.setNonMalaysian();
            break;

          case 3:
          case 5:
            this.setIsCompany();
            break;
        }

        // Reset Vehicle Grant if selection out from commercial option
        if ([1, 2, 3].includes(value)) {
          this.carGrant = null;
        }
      }),

      this.vehicleFormGroup
        .get("insuranceType")
        .valueChanges.subscribe((value) => {
          if (value === "COM") return;
          this.vehicleFormGroup.get("eHailingUsed").patchValue(false);
        }),

      this.vehicleFormGroup
        .get("eHailingUsed")
        .valueChanges.subscribe((value) => {
          if (this.partnerCode !== "carsome") return;

          const idType = this.custFormGroup.get("idType");

          if (value) {
            this.idTypeList.splice(3);

            if ([4, 5].includes(idType.value)) {
              idType.patchValue(this.idTypeList[0].id);
            }
          } else {
            // Stop pushing commercial options if already existed
            if (this.idTypeList.length === 5) return;

            this.idTypeList.push(
              { id: 4, description: "common.commercialInd" },
              { id: 5, description: "common.commercialComp" }
            );
          }
        })
    );
  }

  private setMalaysian(): void {
    this.isCompany = false;
    this.isMalaysian = true;

    this.custFormGroup
      .get("identityNo")
      .setValidators([Validators.required, ValidatorFunction.nricValidator()]);
    this.custFormGroup.get("nationality").clearValidators();

    ["dob", "gender"].forEach((key) => {
      this.custFormGroup.get(key).disable();
    });

    ["identityNo", "nationality"].forEach((key) => {
      this.custFormGroup.get(key).updateValueAndValidity();
    });

    this.setNonCompanyValidation();
  }

  private setNonMalaysian(): void {
    this.isCompany = false;
    this.isMalaysian = false;

    this.getCountryList();

    ["dob", "gender"].forEach((key) => {
      this.custFormGroup.get(key).enable();
    });

    ["identityNo", "nationality"].forEach((key) => {
      this.custFormGroup.get(key).setValidators(Validators.required);
      this.custFormGroup.get(key).updateValueAndValidity();
    });

    this.setNonCompanyValidation();
  }

  private setIsCompany(): void {
    this.isCompany = true;
    this.isMalaysian = true;

    this.custFormGroup.get("identityNo").setValidators([Validators.required]);
    this.custFormGroup.get("name").clearValidators();

    ["identityNo", "name"].forEach((key) => {
      this.custFormGroup.get(key).updateValueAndValidity();
    });

    this.setCompanyValidation();
  }

  private postcodeValidator(control: FormControl): ValidationErrors | null {
    if (control.value === null || control.value.length !== 5) return null;

    return this.validPostcodes.includes(control.value)
      ? null
      : { customError: true };
  }

  private removeUrlRouteName(): void {
    const currentUrl = this.location.path();
    const updatedUrl = currentUrl.replace("/affiliates", "");

    this.location.replaceState(updatedUrl);
  }

  private getGenderAndDate(value): void {
    let date = moment(value.substring(0, 6), "YYMMDD");
    let lastChar = value.substring(11);
    let gender = lastChar % 2 ? "M" : "F";

    if (moment().isBefore(date)) {
      date.subtract(100, "year");
    }

    this.custFormGroup.patchValue({
      dob: date.toDate(),
      gender: gender
    });
  }

  private setNonCompanyValidation(): void {
    ["dob", "gender", "maritalStatus"].forEach((key) => {
      this.custFormGroup.get(key).setValidators(Validators.required);
      this.custFormGroup.get(key).updateValueAndValidity();
    });
  }

  private setCompanyValidation(): void {
    ["dob", "gender", "maritalStatus", "nationality"].forEach((key) => {
      this.custFormGroup.get(key).clearValidators();
      this.custFormGroup.get(key).updateValueAndValidity();
    });
  }

  private getCountryList() {
    if (this.countryList.length != 0) {
      this.filterCountry();
      return;
    }

    this.masterDataDataService.getCountrySelection().subscribe({
      next: (x) => {
        const exclude = [
          "Iran",
          "Syria",
          "North Korea",
          "Sudan",
          "Crimea",
          "Venezuela",
          "Cuba",
          "Israel",
          "Russian Federation",
          "Ukraine"
        ];
        this.countryList = x.filter((country) => !exclude.includes(country));
        this.filterCountry();
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      }
    });
  }

  getIdentityNoLabel(): string {
    let identityLabel: string;

    if (this.isMalaysian && !this.isCompany) {
      identityLabel = "common.icNoLabel";
    } else if (!this.isMalaysian) {
      identityLabel = "common.passportLabel";
    } else {
      identityLabel = "common.businessRegistrationNoLabel";
    }

    return identityLabel;
  }

  getNameLabel(): string {
    let nameLabel: string;

    if (this.isMalaysian && !this.isCompany) {
      nameLabel = "common.namePerIc";
    } else if (!this.isMalaysian) {
      nameLabel = "common.namePerPassport";
    } else {
      nameLabel = "common.companyName";
    }

    return nameLabel;
  }

  getIdentityNoMasking(): string {
    if (this.isMalaysian && !this.isCompany) return "000000000000";
    return "";
  }

  filterCountry(val: string = null): void {
    if (val) {
      let txt = val.trim().toLowerCase();
      this.filterCountryList = this.countryList.filter((x) =>
        x.trim().toLowerCase().startsWith(txt)
      );
    } else {
      this.filterCountryList = Object.assign([], this.countryList);
    }
  }

  async submit(): Promise<void> {
    if (this.vehicleFormGroup.invalid || this.custFormGroup.invalid) return;

    let vehicleForm = this.vehicleFormGroup.getRawValue();
    let custForm = this.custFormGroup.getRawValue();

    this.successGifType = custForm.contactMethod;
    custForm.contactMethod == "Mobile"
      ? (this.contactInfo = custForm.mobileNo)
      : (this.contactInfo = custForm.email);

    let contactMethod =
      custForm.contactMethod == "Mobile"
        ? "Mobile (Whatsapp)"
        : custForm.contactMethod;

    let input: MotorQuotationInterface = {
      insuranceType: vehicleForm.insuranceType,
      vehicleNo: vehicleForm.carPlate,
      typeofSum: vehicleForm.insuredType,
      includeRoadTax: vehicleForm.includeRoadTax,
      eHailingUsed: vehicleForm.eHailingUsed,
      isMalaysian: this.isMalaysian,
      isCompany: this.isCompany,
      identityNo: custForm.identityNo,
      postcode: vehicleForm.postcode,
      policIdentityNo: custForm.policIdentityNo,
      preferredChannel: contactMethod,
      name: custForm.name,
      nationality: custForm.nationality,
      dob: custForm.dob,
      gender: custForm.gender,
      maritalStatus: custForm.maritalStatus,
      mobileNo: custForm.mobileNo,
      email: custForm.email,
      language: this.translate.currentLang,
      utmSouce: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmContent: this.utmContent,
      isRenewal: false,
      motorLinkId: null,
      ...(vehicleForm.mileagePlan
        ? { motorMileagePlanId: vehicleForm.mileagePlan }
        : {}),
      vehicleGrant: this.carGrant,
      isCommercialIndividual: custForm.idType === 4 ? true : false,
      isCommercialCompany: custForm.idType === 5 ? true : false
    };

    let duplicateRequest: CheckDuplicateRequest = {
      vehicleNo: vehicleForm.carPlate,
      identityNo: custForm.identityNo,
      email: custForm.email,
      mobileNo: custForm.mobileNo,
      postcode: vehicleForm.postcode,
      eHailingUsed: vehicleForm.eHailingUsed,
      preferredChannel: contactMethod
    };

    const isDuplicateQuote = await this.checkDuplicateQuote(duplicateRequest);
    if (isDuplicateQuote) return;

    let form: any = new FormData();
    for (const [key, value] of Object.entries(input) as [string, any][]) {
      if (value === null || value === undefined || value === "") continue;

      if (key === "dob") {
        form.append(key, value.toISOString());
      } else {
        form.append(key, value);
      }
    }

    if (
      this.noIQAffiliates ||
      input.typeofSum === this.typeOfSumOption.agreedValue
    ) {
      this.quotationSubmitted = false;
      this.ngxSpinnerService.show();

      /*
        [06/12/2024] - Temporary solution applied to handle client side (Carsome) submission issue
        If got vehicleGrant call submitRequest API with FromForm else call submit API with FromBody
      */
      if (input.vehicleGrant) {
        this.motorQuotationDataService.submitRequest(form).subscribe({
          next: (x) => {
            this.ngxSpinnerService.hide();

            if (x.isSuccess) {
              if (input.typeofSum === this.typeOfSumOption.agreedValue) {
                this.handleSuccessSubmitAVQuotation(x.redirect_url);
              } else {
                this.handleSuccessSubmitQuotation(x.data, input, x.redirect_url);
              }
              this.resetForm();
            } else {
              let dialogRef: any;

              if (x.isVehicleOverage || x.isTpftVehicleOverage) {
                dialogRef = this.dialog.open(SubmitFailDialogComponent, {
                  data: {
                    isAffiliate: true,
                    isWithEHailing: x.isEhailing,
                    isTpftVehicleOverage: x.isTpftVehicleOverage,
                    partnerCode: this.partnerCode
                  },
                  panelClass: "fail-dialog"
                });

                dialogRef.afterClosed().subscribe(() => {
                  this.vehicleFormGroup
                    .get("insuranceType")
                    .patchValue(this.typeOfInsuranceList[0].id);
                  this.stepper.previous();
                });
              } else {
                this.dialog.open(BeforeExpiredDialogComponent, {
                  data: { expiryDate: x.data }
                });

                this.resetForm();
              }
            }
          },
          error: (err) => {
            this.ngxSpinnerService.hide();
            this.alertService.openSnackBar("Please contact policy street.");
          }
        });
      } else {
        this.motorQuotationDataService.submit(input).subscribe({
          next: (x) => {
            this.ngxSpinnerService.hide();

            if (x.isSuccess) {
              if (input.typeofSum === this.typeOfSumOption.agreedValue) {
                this.handleSuccessSubmitAVQuotation(x.redirect_url);
              } else {
                this.handleSuccessSubmitQuotation(x.data, input, x.redirect_url);
              }
              this.resetForm();
            } else {
              let dialogRef: any;

              if (x.isVehicleOverage || x.isTpftVehicleOverage) {
                dialogRef = this.dialog.open(SubmitFailDialogComponent, {
                  data: {
                    isAffiliate: true,
                    isWithEHailing: x.isEhailing,
                    isTpftVehicleOverage: x.isTpftVehicleOverage,
                    partnerCode: this.partnerCode
                  },
                  panelClass: "fail-dialog"
                });

                dialogRef.afterClosed().subscribe(() => {
                  this.vehicleFormGroup
                    .get("insuranceType")
                    .patchValue(this.typeOfInsuranceList[0].id);
                  this.stepper.previous();
                });
              } else {
                this.dialog.open(BeforeExpiredDialogComponent, {
                  data: { expiryDate: x.data }
                });

                this.resetForm();
              }
            }
          },
          error: (err) => {
            this.ngxSpinnerService.hide();
            this.alertService.openSnackBar("Please contact policy street.");
          }
        });
      }
    } else {
      this.quotationSubmitted = true;
    }

    this.quotationInputData = input;
    this.quotationFormData = form;
  }

  async checkDuplicateQuote(request: CheckDuplicateRequest): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.motorQuotationDataService.checkDuplicateRequest(request).subscribe({
        next: (x: CheckDuplicateResponse) => {
          if (x.isExist === "Y") {
            this.dialog.open(RequestStatusDialogComponent, {
              panelClass: "no-padding-border-radius-dialog",
              data: {
                content: request.vehicleNo
              }
            });
            resolve(true);
          }

          resolve(false);
        },
        error: (_error: any) => {
          resolve(true);
        }
      });
    });
  }

  async handleFileInput(e: any): Promise<void> {
    const files = (e.target as HTMLInputElement).files;
    const fileType = (e.target as HTMLInputElement).name;

    if (files.length === 0) return;

    for (let file of Array.from(files)) {
      const isUploadedFilesValid = await this.fileService.isUploadedFilesValid({
        file: file,
        files: files,
        currLength: this.fileList.length,
        maxLength: this.maxLength,
        accept: this.acceptFile,
        name: fileType,
        size: this.fileSize
      });

      if (!isUploadedFilesValid) {
        e.target.value = null;
        return;
      }

      this[fileType] = file;
    }

    e.target.value = null;
  }

  deleteFile(): void {
    this.carGrant = null;
  }

  whatsppRedirect(): void {
    const whatsappNumber = 60182822320;
    const entityName = "Policystreet";
    let whatsappContent = "";
    let lang = this.translate.currentLang == "en" ? "en" : "ms";
    whatsappContent =
      lang == "ms"
        ? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
        : `Hi ${entityName}, I would like to know more about road tax and car insurance`;

    let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappContent
    )}&source=&data=&app_absent=&lang=${lang}`;

    window.open(url, "_blank");
  }

  countLoadedBanners(): void {
    this.loadedBanners++;

    if (this.loadedBanners > 1) {
      this.swiper.swiperRef.setGrabCursor();
    }
  }

  private fetchMasterData(): void {
    const obs$: Observable<any>[] = [this.selectAllPostcodes$()];

    if (this.showMileagePlans) {
      obs$.push(this.getMotorMileagePlans$());
    }

    forkJoin(obs$).subscribe({
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      }
    });
  }

  private selectAllPostcodes$(): Observable<string[]> {
    return this.masterDataDataService.selectAllPostcodes().pipe(
      tap((postcodes) => {
        this.validPostcodes = postcodes;
      })
    );
  }

  private getMotorMileagePlans$(): Observable<DropdownItem[]> {
    return this.masterDataDataService.getMotorMileagePlans().pipe(
      tap((mileagePlans) => {
        this.mileagePlans = mileagePlans;
      })
    );
  }

  private handleSuccessSubmitQuotation(
    expiryDate: string,
    customerInput: MotorQuotationInterface,
    redirectUrl: string
  ): void {
    const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
      panelClass: "success-dialog",
      data: { expiryDate, customerInput }
    });
    dialogRef.afterClosed().subscribe(() => this.backToHome(redirectUrl));
  }

  private handleSuccessSubmitAVQuotation(redirectUrl: string) {
    const dialogRef = this.dialog.open(SubmitSuccessAvDialogComponent, {
      panelClass: "success-dialog"
    });
    dialogRef.afterClosed().subscribe(() => this.backToHome("/affiliates?partnerCode="+this.partnerCode));
  }

  backToHome(redirectUrl: string) {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      window.location.href = "/";
    }
  }

  private get noIQAffiliates(): boolean {
    const noIQAffiliatesList = ["grab", "ktmb"];
    return noIQAffiliatesList.includes(this.partnerCode);
  }

  private initPartnerBanners(): void {
    this.banners = [
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_1-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_2-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_3-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_4-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_5-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "en",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_6-en.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_1-bm.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_2-bm.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_3-bm.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_4-bm.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_5-bm.png`,
        show: true,
        alt: ""
      },
      {
        lang: "bm",
        src: `https://storage.googleapis.com/ps-asset/affiliate/landing/${this.partnerCode}-banner_6-bm.png`,
        show: true,
        alt: ""
      }
    ];
  }

  private resetForm(): void {
    this.pdpaAgreement = false;
    this.carGrant = null;
    this.vehicleFormGroup.reset();
    this.custFormGroup.reset();

    this.custFormGroup.controls.mobileNo.setValue(" ");
    this.custFormGroup.controls.mobileNo.updateValueAndValidity();
    this.initForm();
    this.listenChanges();
    this.stepper.reset();
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.mobileView = width < 960;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
}

interface Banner {
  lang: string;
  src: string;
  show: boolean;
  alt: string;
}

import {
  STEPPER_GLOBAL_OPTIONS,
  StepperSelectionEvent,
} from "@angular/cdk/stepper";
import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, Subscription, takeUntil, Subject } from "rxjs";
import { CarVariantDialogComponent } from "src/app/share/component/car-variant-dialog/car-variant-dialog.component";
import { INSURER_FULL_NAME } from "src/app/share/constants/common.info";
import {
  PAYMENT_OPTION_EPP,
  PAYMENT_OPTION_EWALLET,
  PAYMENT_OPTION_FP,
  PAYMENT_OPTION_FPX,
  PAYMENT_OPTION_RAZER,
  PAYMENT_OPTION_SHOPBACK,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_ATOME,
  PAYMENT_OPTION_BNPL
} from "src/app/share/constants/payment-options";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { CommonFunction } from "src/app/share/function/common.function";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import {
  D2CQuotationInterface,
  EPPChargeInterface,
  MotorRequestInterface,
  OtherInsurerInterface,
  ProductAddOnInterface,
  ProductDocumentInterface,
  PromoCodeInterface,
  QuotationInterface,
  BNPLRateListInterface
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { FooterMarginService } from "src/app/share/service/footer-margin.service";
import { PartnerService } from "src/app/share/service/partner.service";
import { ScreenSizeService } from "src/app/share/service/screen-size.service";
import { BenefitDialogComponent } from "../benefit-dialog/benefit-dialog.component";
import { CarDetailsDialogComponent } from "../car-details-dialog/car-details-dialog.component";
import { CarPlanDialogComponent } from "../car-plan-dialog/car-plan-dialog.component";
import { EditQuotationComponent } from "../edit-quotation/edit-quotation.component";
import { SubmitSuccessDialogComponent } from "../submit-success-dialog/submit-success-dialog.component";
import { MotorQuotationDataService } from "src/app/share/data-service/motor/motor-quotation.data.service";
import { MotorQuotationInterface } from "src/app/share/interface/motor-quotation.interface";
import { FieldData } from "src/app/share/interface/common.interface";
import { SignalrService } from "src/app/services/signalr.service";
import { TimerService } from "src/app/share/service/timer.service";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: "app-comparison",
  templateUrl: "./comparison.component.html",
  styleUrls: ["./comparison.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    DecimalPipe
  ]
})
export class ComparisonComponent implements OnInit, OnDestroy {
  partnerCode: string = "ps";

  quotationFrmGroup: FormGroup;
  addOnFrmGroup: FormGroup;
  infoFrmGroup: FormGroup;
  agreementFrmGroup: FormGroup;
  paymentFrmGroup: FormGroup;
  paymentFormGroup: FormGroup;

  id: string;
  requestData: MotorRequestInterface;
  quotationList: D2CQuotationInterface[] = [];
  quotationId: string;
  selectedQuotation: QuotationInterface;
  stepperIndex: number = 0;

  @ViewChild("stepper") stepper: MatStepper;

  addOnList: ProductAddOnInterface[] = [];
  fullAddOnList: ProductAddOnInterface[] = [];
  includeAddOnList: ProductAddOnInterface[] = [];
  chubbAddOnList: any[];
  eppChargeList: EPPChargeInterface[] = [];
  bankInstallmentCharge: { 3: number; 6: number; 12: number };
  bnplRateList: BNPLRateListInterface[] = [];

  addOnOptionalCategory: string[] = [];

  selectedAddOn: ProductAddOnInterface[] = [];

  isFloodChecked: boolean;
  isDriverChecked: boolean;
  showRoadTaxList: boolean = false;
  isIncludeDigitalRoadtax: boolean = false;
  includeDigitalRoadTax: boolean;
  isVBETChecked: boolean;
  isInsurancePlaceholderEnabled: boolean = false;
  isChubbInstantQuotation: boolean = false;
  requirePremiumRecalculation: boolean = false;

  subsVar: Subscription;
  paymentTypeChangeSub: Subscription;
  paymentChannelSub: Subscription;

  merwPriceList: number[];

  // Insurance placeholder
  additionalInsurerList = [];
  selectedAdditionalInsurer = [];
  otherInsurerList: OtherInsurerInterface[] = [];
  selectedOtherInsurers: string[] = [];

  // static
  insurerPlaceholderEnabledList = [
    "carsome",
    "wapcar",
    "carlist", "grab", "ktmb"
  ]
  relationshipList = [
    { id: "Parent/Parent-in-law", name: "common.parents" },
    { id: "Spouse", name: "common.spouse" },
    { id: "Son/Daughter", name: "common.child" },
    { id: "Sibling/Sibling-in-law/Cousin/Relative", name: "common.sibling" },
    { id: "Friend/Co-worker", name: "common.friend" }
  ];
  paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
  paymentOptionShopback: string = PAYMENT_OPTION_SHOPBACK;
  paymentOptionEPP: string = PAYMENT_OPTION_EPP;
  paymentOptionAtome: string = PAYMENT_OPTION_ATOME;
  paymentOptionBNPL: string = PAYMENT_OPTION_BNPL;

  paymentOptionVisa: string = PAYMENT_OPTION_VISA;
  paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
  paymentOptionFpx: string = PAYMENT_OPTION_FPX;
  paymentOptionFullPrice: string = PAYMENT_OPTION_FP;
  affiliateBannerData: string[] = [];

  promoCode: string;
  promo: PromoCodeInterface;
  promoError: string;

  stateList: string[] = [
    "Selangor",
    "Wilayah Persekutuan Kuala Lumpur",
    "Wilayah Persekutuan Labuan",
    "Wilayah Persekutuan Putrajaya",
    "Johor",
    "Kedah",
    "Kelantan",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Perak",
    "Perlis",
    "Pulau Pinang",
    "Sarawak",
    "Sabah",
    "Terengganu"
  ];

  filterStateList: string[] = Object.assign([], this.stateList);
  filterDeliveryStateList: string[] = Object.assign([], this.stateList);

  productDocumentInterface: ProductDocumentInterface[] = [];

  selectedPayment: string = "Razer";
  allowEditQuotation: boolean = false;

  isSameAddressCheck: boolean;
  isDifferentAddressCheck: boolean = false;

  isGrabSingleQuotation: boolean = false;

  otherInsurersList: string[] = [
    "Allianz",
    "am",
    "greateastern",
    "kurnia",
    "liberty",
    "longpac",
    "Msig",
    "Rhb",
    "tokio"
  ];

  uniquePageView: any[] = [
    { index: 0, pageTitle: "comparison page" },
    { index: 1, pageTitle: "add ons page" },
    { index: 2, pageTitle: "user details page" },
    { index: 3, pageTitle: "order summary page" }
  ];

  idTypeList: FieldData[] = [
    { codeValue: "IC_NO", codeDesc: "IC Number" },
    { codeValue: "PASSPORT", codeDesc: "Passport" },
    { codeValue: "POLICE/ARMY", codeDesc: "Police/Army" }
  ];

  maxAdditionalDriver: number;
  maxFreeDriver: number;

  toggleRecommendedAddOnsState: boolean = true;

  numberOfLltpSeats: number = 0;

  showMileagePlans: Boolean = false;
  showMileagePlansList: string[] = ["ktmb"];

  maxDob: Date = new Date();
  private timerSubscription: Subscription | null = null;
  private timerUnsubscribe$ = new Subject<void>();

  constructor(
    private gtmService: GoogleTagManagerService,
    public translate: TranslateService,
    public screenSizeService: ScreenSizeService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private ngxSpinnerService: NgxSpinnerService,
    public signalRService: SignalrService,
    private partnerService: PartnerService,
    private timerService: TimerService,
    private loadingService: LoadingOverlayService,
    private cd: ChangeDetectorRef,
    private motorDataDataService: MotorDataDataService,
    private router: Router,
    private dialog: MatDialog,
    private motorQuotationService: MotorQuotationDataService,
    private _decimalPipe: DecimalPipe,
    private footerMarginService: FooterMarginService
  ) {
    this.getPartnerCode();
    this.getAdditionalInsurer(activatedRoute.snapshot.params.id);
    this.initConditionalFields();
    this.initQuotationForm();
    this.initAddOnForm();
    this.initInfoForm();
    this.initAgreementForm();
    this.initPaymentFormGroup();

    if(this.isInsurancePlaceholderEnabled) {
      this.signalRService.startConnection().subscribe((isConnected) => {
        this.updateQuotationMethod(isConnected);
      });
    } 

    this.id = activatedRoute.snapshot.params.id;
    
    if (this.isUUID(this.id)) {
      this.allowEditQuotation =
        activatedRoute.snapshot.queryParams.editQuotation;
      this.getDetailById();
    } else {
      window.location.href = "/";
    }
    this.translate.onLangChange.subscribe((x) => {
      if (this.selectQuotation) {
        this.getProductDocument();
      }
    });
    this.gtmService.pushTag({
      event: "car_step1"
    });

    // Unique page view tracking for certain affiliate (Comparison page on render)
    if (
      this.partnerService.getPartnerAttribute(
        this.partnerCode,
        "uniquePageViews"
      )
    ) {
      window.dataLayer.push({
        event: "virtualPageview",
        pageUrl: window.location.href,
        pageTitle: `${this.partnerCode} comparison page`
      });
    }
  }

  public async updateQuotationMethod(isConnected) {
    // Get new updated quotation main method using signalr
    // If connection fail, only call the fallback method (refresh page every 5mins)   
    if (isConnected) {
      this.signalRService.receiveUpdatedQuotation().subscribe((message) => {
        this.updateQuotationPrice(message);
        this.getDetailById();
      });
    } else {
      await this.checkIfQuotationExpired();
    }
  }

  private async checkIfQuotationExpired(): Promise<boolean> {
    this.loadingService.setLoading(true);
    let quotation;

    try {
      quotation = await this.motorDataDataService
        .getQuotationById(this.id)
        .toPromise();

      if (quotation.hasOwnProperty("expiredDetails")) {
        this.alertService.openSnackBar("Quotation has expired");
      } else {
        this.quotationList = quotation;

        const hasNewQuotation = this.quotationList.some(
          (item) => item.status === "New",
        );

        if (hasNewQuotation) {
          // Start or restart the timer if needed
          this.timerService.startTimer();

          // Ensure only one subscription to the timerExpired$ observable
          if (!this.timerSubscription) {
            this.timerSubscription = this.timerService.timerExpired$
            .pipe(takeUntil(this.timerUnsubscribe$))
              .subscribe(() => {
                // Call the function again after timer expires
                this.checkIfQuotationExpired();
              });
          }
        } else {
          // Stop the timer and clean up subscription if no new quotations
          this.timerService.stopTimer();
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
          }
        }

        this.loadingService.setLoading(false);
        return false;
      }
    } catch (err) {
      this.loadingService.setLoading(false);
      window.location.href = "/";
      return false;
    }

    return true;
  }

  updateQuotationPrice(insurerName) {
    let quotation;
    this.motorDataDataService
      .getQuotationById(this.id).subscribe((x) => {
        quotation = x;
        this.quotationList = quotation;

        this.cd.detectChanges();

        // Stop signalr connection if all quotations are updated
        if (this.isAllQuotationUpdated()) {
          this.signalRService.stopConnection();
        }
      },
      );
  }
  
  isAllQuotationUpdated(): boolean {
    if (this.quotationList.filter((x) => (x.status == "New" || x.status == "On-Hold")).length == 0)
      return true
    else
      return false;
  }

  ngOnInit() {
    this.footerMarginService.updateComponent("ComparisonComponent");
    // Listen for window resize events
    this.screenSizeService.notifyResize();
    window.addEventListener("resize", () =>
      this.screenSizeService.notifyResize()
    );
  }

  ngOnDestroy(): void {
    this.footerMarginService.updateComponent(null);
    this.footerMarginService.updateStepperIndex(null);

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerUnsubscribe$.next();
    this.timerUnsubscribe$.complete();
  }

  get isPaymentProviderShopback() {
    return (
      this.paymentFormGroup?.value?.paymentProvider ==
      this.paymentOptionShopback
    );
  }

  get isPaymentTypeEPP() {
    return this.paymentFormGroup?.value?.paymentType == this.paymentOptionEPP;
  }

  get isPaymentTypeBNPL() {
    return this.paymentFormGroup?.value?.paymentType == "BNPL";
  }

  get isPaymentChannelFPX() {
    return (
      this.paymentFormGroup?.value?.paymentChannel == this.paymentOptionFpx
    );
  }

  get isTotalNotNegative() {
    return this.getTotal() > 0;
  }

  private getPartnerCode() {
    this.partnerCode = this.partnerService.getPartnerCode();
    !this.partnerCode ? (this.partnerCode = "ps") : null;

    if(this.insurerPlaceholderEnabledList.includes(this.partnerCode)) {
      this.isInsurancePlaceholderEnabled = true;
    } else this.isInsurancePlaceholderEnabled = false;
  }

  private initConditionalFields(): void {
    if (this.showMileagePlansList.includes(this.partnerCode)) {
      this.showMileagePlans = true;
    }
  }

  private initQuotationForm() {
    this.quotationFrmGroup = new FormGroup({
      id: new FormControl(this.quotationId, [Validators.required])
    });
  }

  private initAddOnForm() {
    this.addOnFrmGroup = new FormGroup({
      windshield: new FormControl(),
      drivers: new FormArray([]),
      roadTaxPrice: new FormControl(this.requestData?.originalRoadTaxPrice),
      isIncludeRoadtax: new FormControl(),
      isNotBlacklisted: new FormControl(null),
      lltpSeats: new FormControl(this.numberOfLltpSeats),
      merw: new FormControl()
    });

    this.addOnFrmGroup.valueChanges.subscribe(() => {
      this.applyPromoCode();
    });

    this.addOnFrmGroup.controls.isIncludeRoadtax.valueChanges.subscribe((x) => {
      let validators = [];
      let addressValidators = [];
      if (x) {
        validators = [Validators.requiredTrue];
        addressValidators = [Validators.required];
        this.infoFrmGroup.controls.deliveryPostcode.enable();
      } else {
        this.addOnFrmGroup.controls.isNotBlacklisted.reset();
        this.infoFrmGroup.controls.deliveryPostcode.enable();
      }
      this.addOnFrmGroup.controls.isNotBlacklisted.setValidators(validators);
      this.addOnFrmGroup.controls.isNotBlacklisted.updateValueAndValidity();

      // this.infoFrmGroup.controls.deliveryAddress.setValidators(
      //   addressValidators
      // );
      // this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();

      // this.infoFrmGroup.controls.deliveryAddress2.setValidators(
      //   addressValidators
      // );
      // this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();

      // this.infoFrmGroup.controls.deliveryCity.setValidators(addressValidators);
      // this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();

      // this.infoFrmGroup.controls.deliveryPostcode.setValidators(
      //   addressValidators
      // );
      // this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();

      // this.infoFrmGroup.controls.deliveryState.setValidators(addressValidators);
      // this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
    });
  }

  private initInfoForm() {
    this.infoFrmGroup = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      occupation: new FormControl(null, []),
      mobileNo: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      address: new FormControl(null, [Validators.required]),
      address2: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      mileage: new FormControl(null, [Validators.required]),
      mileagePhoto: new FormControl([] as File[]),
      lltpSeats: new FormControl(null, []),

      deliveryAddress: new FormControl(null),
      deliveryAddress2: new FormControl(null),
      deliveryCity: new FormControl(null),
      deliveryPostcode: new FormControl(null),
      deliveryState: new FormControl(null)
    });
    this.infoFrmGroup.controls.postcode.disable();

    if (this.partnerCode != "ktmb") {
      // Only required for KTMB
      this.infoFrmGroup.controls.mileage.setValidators([]);
      this.infoFrmGroup.controls.mileage.updateValueAndValidity();
    }

    if (this.showMileagePlans) {
      this.infoFrmGroup.controls.mileagePhoto.setValidators([
        Validators.required,
        Validators.minLength(1)
      ]);
      this.infoFrmGroup.controls.mileagePhoto.updateValueAndValidity();
    }
  }

  private initAgreementForm() {
    this.agreementFrmGroup = new FormGroup({
      agreeInfo: new FormControl(false, [Validators.requiredTrue]),
      doc: new FormArray([])
    });

    if (this.partnerCode === "ktmb") {
      this.agreementFrmGroup.addControl(
        "acknowledge",
        new FormControl(false, [Validators.requiredTrue])
      );
    }
  }

  private initPaymentFormGroup() {
    this.getEppRateList();
    this.getBNPLRateList();

    this.paymentFormGroup = new FormGroup({
      paymentType: new FormControl(this.paymentOptionFullPrice, [
        Validators.required
      ]),
      paymentProvider: new FormControl(this.paymentOptionRazer, [
        Validators.required
      ]),
      paymentChannel: new FormControl("", []),
      ewalletType: new FormControl("", []),
      // For EPP only
      bank: new FormControl("", []),
      installmentMonth: new FormControl(0, []),
      paymentOptionEPPType: new FormControl("", []),
      paymentOptionFPType: new FormControl(this.paymentOptionRazer, [
        Validators.required
      ])
    });

    let paymentFormVal = this.paymentFormGroup.controls;
    let paymentChannel = this.paymentFormGroup.controls.paymentChannel;
    let paymentProvider = this.paymentFormGroup.controls.paymentProvider;
    let ewalletType = this.paymentFormGroup.controls.ewalletType;

    // Change EPP rate on bank change
    this.paymentFormGroup.controls.bank.valueChanges.subscribe((x) => {
      if (x) {
        this.getIndividualEppRates(x);
      }
    });

    // To toggle e-wallet dropdown to be required/not
    this.paymentChannelSub =
      this.paymentFormGroup.controls.paymentChannel.valueChanges.subscribe(
        (x) => {
          if (x == this.paymentOptionEwallet) {
            ewalletType?.setValidators([Validators.required]);
          } else {
            ewalletType?.setValidators([]);
          }
          ewalletType?.updateValueAndValidity();
        }
      );

    // To toggle other payment type child inputs to be required/not
    this.paymentTypeChangeSub =
      this.paymentFormGroup.controls.paymentType.valueChanges.subscribe((x) => {
        if (x == this.paymentOptionFullPrice) {
          paymentChannel.setValidators([]);
          this.paymentFormGroup.patchValue({
            paymentProvider: this.paymentOptionRazer
          });
          paymentFormVal?.paymentOptionFPType.setValidators([
            Validators.required
          ]);
        } else {
          paymentChannel.setValidators([]);
        }

        if (x == this.paymentOptionEPP) {
          paymentFormVal?.bank.setValidators([Validators.required]);
          paymentFormVal?.installmentMonth.setValidators([Validators.required]);
          paymentProvider.setValidators([Validators.required]);
          paymentFormVal?.paymentOptionEPPType.setValidators([
            Validators.required
          ]);
          paymentFormVal?.paymentOptionFPType.setValidators([]);
          paymentFormVal.ewalletType.reset();
          paymentFormVal.paymentChannel.reset();

          this.paymentFormGroup.patchValue({
            paymentOptionFPType: "",
            bank: "",
            installmentMonth: ""
          });
        } else {
          paymentFormVal?.bank.setValidators([]);
          paymentFormVal?.installmentMonth.setValidators([]);
          paymentFormVal?.paymentOptionEPPType.setValidators([]);
          paymentFormVal.bank.reset();
          paymentFormVal.installmentMonth.reset();
          paymentFormVal.paymentOptionEPPType.reset();
        }

        if (x == this.paymentOptionShopback) {
          paymentChannel.setValidators([]);
          paymentFormVal?.bank.setValidators([]);
          paymentFormVal?.installmentMonth.setValidators([]);
          paymentFormVal?.paymentOptionFPType.setValidators([]);

          paymentFormVal.bank.reset();
          paymentFormVal.installmentMonth.reset();
          paymentFormVal.ewalletType.reset();

          this.paymentFormGroup.patchValue({
            paymentProvider: this.paymentOptionShopback,
            paymentChannel: this.paymentOptionShopback,
            paymentOptionFPType: ""
          });
        }
        
        if (x == this.paymentOptionBNPL){
          paymentChannel.setValidators([]);
          paymentFormVal?.bank.setValidators([]);
          paymentFormVal?.installmentMonth.setValidators([]);
          paymentFormVal?.paymentOptionFPType.setValidators([]);
          paymentFormVal.bank.reset();
          paymentFormVal.installmentMonth.reset();
          paymentFormVal.ewalletType.reset();
          this.paymentFormGroup.patchValue({
            paymentProvider: this.paymentOptionAtome,
            paymentChannel: this.paymentOptionAtome,
            bank: "",
            installmentMonth: "",
            paymentOptionEPPType: "",
            ewalletType: "",
            paymentOptionFPType: ""
          });
        }

        paymentChannel.updateValueAndValidity();
        paymentFormVal?.bank.updateValueAndValidity();
        paymentFormVal?.installmentMonth.updateValueAndValidity();
      });
  }

  private getEppRateList(promoCodeId: string = null) {
    this.motorDataDataService.getEPPRates(promoCodeId).subscribe({
      next: (x: EPPChargeInterface[]) => {
        this.eppChargeList = x;

        // Check if eppRate in the first object is 0
        if (this.eppChargeList.length > 0 && this.eppChargeList[0].eppRate === 0) {
          this.affiliateBannerData = ["carsome"]; // Set to ["carsome"] if eppRate is 0
        } else {
          this.affiliateBannerData = []; // Set to an empty array if eppRate is not 0
        }
      }
    });
  }

  private getBNPLRateList(){
    this.motorDataDataService.getBNPLRateListByPortal('carsome').subscribe({
      next: (x: BNPLRateListInterface[]) => {
        this.bnplRateList = x;
      }
    })
  }

  getEppRate() {
    let bankName = this.paymentFormGroup?.value?.bank;
    let paymentProvider = this.paymentFormGroup?.value?.paymentProvider;
    let installmentPeriod = this.paymentFormGroup?.value?.installmentMonth;

    if (bankName && installmentPeriod && this.eppChargeList?.length) {
      const result = this.eppChargeList?.filter(
        (entry) =>
          entry.bankName == bankName &&
          entry.installmentPeriod == installmentPeriod &&
          entry.provider == paymentProvider
      );
      return result.length > 0 ? result[0]?.eppRate : null;
    } else {
      return null;
    }
  }

  getIndividualEppRates(x) {
    let bankName = x;
    let paymentProvider = this.paymentFormGroup?.value?.paymentProvider;

    if (bankName && this.eppChargeList?.length) {
      const results: any = this.eppChargeList
        .filter(
          (entry) =>
            entry.bankName == bankName && entry.provider == paymentProvider
        )
        .reduce((accumulator, entry) => {
          const periods = [3, 6, 12];
          if (periods.includes(entry.installmentPeriod)) {
            accumulator[entry.installmentPeriod] =
              entry.eppRate * (this.getTotalCharge() - this.getTotalPromo());
          }
          return accumulator;
        }, {});

      this.bankInstallmentCharge = results;
    } else {
      this.bankInstallmentCharge = { 3: null, 6: null, 12: null };
    }
  }

  private isUUID(uuid: string) {
    return uuid.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
  }

  private getDetailById() {
    this.motorDataDataService.getDetailById(this.id).subscribe({
      next: (x) => {
        this.translate.use(x.language);
        this.requestData = x;
        this.addOnFrmGroup.controls.roadTaxPrice.setValue(
          x?.originalRoadTaxPrice
        );
        this.getQuotation();
        this.infoFrmGroup.controls.name.setValue(x.name);
        this.infoFrmGroup.controls.email.setValue(x.email);
        this.infoFrmGroup.controls.mobileNo.setValue(x.mobileNo);
        this.infoFrmGroup.controls.postcode.setValue(x.postcode);
        this.numberOfLltpSeats = x.seat;
        if (this.numberOfLltpSeats <= 4){
          this.numberOfLltpSeats = 5
        }
        this.infoFrmGroup.controls.lltpSeats.setValue(this.numberOfLltpSeats);
        this.addOnFrmGroup.controls.lltpSeats.setValue(x.seat);
      },
      error: () => {
        window.location.href = "/";
      }
    });
  }

  private getQuotation() {
    this.motorDataDataService
      .getQuotationById(this.id, this.partnerCode === "ktmb")
      .subscribe({
        next: (x: any) => {
          this.quotationList = x;

          if (this.activatedRoute.snapshot.queryParams.id) {
            setTimeout(() => {
              this.selectQuotation(this.activatedRoute.snapshot.queryParams.id);
            }, 100);
          }
          if (this.allowEditQuotation) {
            this.editQuotation();
            this.allowEditQuotation = false;
          }
          // Set grab ehailing condition
          this.setGrabSingleQuotationCondition();
        },
        error: () => {
          window.location.href = "/";
        }
      });
  }

  
  private getAdditionalInsurer(id: string) {
    this.motorDataDataService.getAvailableProduct(id).subscribe({
      next: (x: OtherInsurerInterface[]) => {
        this.otherInsurerList = x;
      },
      error: () => {
        // window.location.href = "/";
      },
    });
  }

  private setGrabSingleQuotationCondition() {
    if (
      this.requestData.eHailingUsed &&
      this.partnerCode == "grab" &&
      this.quotationList.some((x) => x.code === "GrabAXA") &&
      this.quotationList.length == 1
    ) {
      this.isGrabSingleQuotation = true;
    } else {
      this.isGrabSingleQuotation = false;
    }
  }

  private getAddOn() {
    this.includeAddOnList = [];

    if (this.isChubbInstantQuotation) {
      this.motorDataDataService.getChubbAddOn(this.quotationId).subscribe({
        next: (x) => {
          this.addOnList = x;

          this.addOnList.forEach((y) => {
            if (y.category == "Windshield") {
              this.addOnFrmGroup.controls.windshield.setValue(
                this.getMinMaxWindscreenPrice(y, "min")
              );
            }
          })

          this.fullAddOnList = x;
          this.addOnOptionalCategory = [
            ...new Set(
              this.addOnList.filter((y) => !y.included).map((z) => z.category)
            )
          ];
        }
      })
    }
    else {
      this.motorDataDataService.getAddOn(this.id, this.quotationId).subscribe({
        next: (x) => {
          this.addOnList = x;

          let vehicleAge: number =
            new Date().getFullYear() - parseInt(this.requestData.year);

          if (vehicleAge > 10 && this.selectedQuotation.name === "Generali") {
            this.addOnList = this.addOnList.filter(
              (obj) => obj.code !== "EHailing"
            );
          }

          if (this.addOnList) {
            this.sortDriversAddonSequence();
          }
          if (this.partnerCode == "ps" && this.toggleRecommendedAddOnsState) {
            this.filterRecommendedAddOns();
          }
          this.fullAddOnList = x;
          this.addOnOptionalCategory = [
            ...new Set(
              this.addOnList.filter((y) => !y.included).map((z) => z.category)
            )
          ];
          this.addOnList.forEach((y) => {
            if (this.partnerCode == "ps" && this.toggleRecommendedAddOnsState) {
              if (y.category == "Windshield" || y.category == "Flood") {
                this.selectedAddOn.push(y);
              }
            }
            if (y.category == "Windshield") {
              this.addOnFrmGroup.controls.windshield.setValue(
                this.getMinMaxWindscreenPrice(y, "min")
              );
            }

            if (y.code == "AdditionalDrivers") {
              this.maxAdditionalDriver = this.getRule(y)?.max ?? null;
              this.maxFreeDriver = !y.condition
                ? this.getRule(y)?.max ?? null
                : null;
            }

            if (y.code == "EHailing" && this.isGrabSingleQuotation) {
              this.selectedAddOn.push(y);
            }

            if (y.code == "MERW") {
              this.merwPriceList = eval(y.evaluate);
              if (this.merwPriceList)
                this.addOnFrmGroup.controls.merw.setValue(this.merwPriceList[0]);
            }

            if (y.included) {
              this.includeAddOnList.push(y);
              this.selectedAddOn.push(y);
            }
          });
        },
        error: () => {
          window.location.href = "/";
        }
      });
    }
  }

  sortDriversAddonSequence() {
    // Define the custom order for specific codes
    const order = ["UnlimitedDrivers", "AdditionalDrivers"];

    this.addOnList.sort((a, b) => {
      // Handle custom sorting logic
      const indexA = order.indexOf(a.code);
      const indexB = order.indexOf(b.code);

      if (indexA > -1 && indexB > -1) {
        return indexA - indexB; // Both are in the order list
      } else if (indexA > -1) {
        return -1; // a should come before b
      } else if (indexB > -1) {
        return 1; // b should come before a
      } else {
        return 0; // Default sorting for other items
      }
    });
  }

  private getProductDocument() {
    if (!this.selectedQuotation) return;
    this.productDocumentInterface = [];
    let frmArray = this.getAgreeFormArray();
    frmArray.clear();
    this.motorDataDataService
      .getProductDocumentById(
        this.selectedQuotation.productId,
        this.translate.currentLang,
        this.requestData.insuranceType
      )
      .subscribe({
        next: (x) => {
          this.productDocumentInterface = x;
          x.forEach((doc) => {
            if (doc.neededUserAgree) {
              let frm = this.formBuilder.group({
                checked: new FormControl(false, [Validators.requiredTrue]),
                name: new FormControl(doc.name),
                url: new FormControl(doc.url)
              });
              frmArray.push(frm);
            }
          });
        }
      });
  }

  private promoCheck() {
    if (!this.promo) return;
    if (
      this.promo.customCode == "RoadTaxDelivery" &&
      !this.addOnFrmGroup.value.isIncludeRoadtax
    ) {
      this.promo = null;
      this.promoError = "InvalidPromoCode";
    }
  }

  onStepChange(e: StepperSelectionEvent) {
    this.updateStepperIndex(e.selectedIndex);
    this.gtmService.pushTag({
      event: "car_step" + (e.selectedIndex + 1)
    });

    this.paymentFrmGroup.controls.payment.setValue(e.selectedIndex == 4);
    if (e.selectedIndex == 3) {
      let value = this.infoFrmGroup.getRawValue();
      if (
        (this.addOnFrmGroup.value?.isIncludeRoadtax &&
          this.isSameAddressCheck) ||
        (this.addOnFrmGroup.value?.isIncludeRoadtax &&
          !this.isDifferentAddressCheck)
      ) {
        this.infoFrmGroup.controls.deliveryAddress.setValue(value.address);
        this.infoFrmGroup.controls.deliveryAddress2.setValue(value.address2);
        this.infoFrmGroup.controls.deliveryCity.setValue(value.city);
        this.infoFrmGroup.controls.deliveryPostcode.setValue(value.postcode);
        this.infoFrmGroup.controls.deliveryState.setValue(value.state);
      } else if (!this.addOnFrmGroup.value?.isIncludeRoadtax) {
        this.infoFrmGroup.controls.deliveryAddress.reset();
        this.infoFrmGroup.controls.deliveryAddress2.reset();
        this.infoFrmGroup.controls.deliveryCity.reset();
        this.infoFrmGroup.controls.deliveryPostcode.reset();
        this.infoFrmGroup.controls.deliveryState.reset();

        this.infoFrmGroup.controls.deliveryAddress.setValidators([]);
        this.infoFrmGroup.controls.deliveryAddress2.setValidators([]);
        this.infoFrmGroup.controls.deliveryCity.setValidators([]);
        this.infoFrmGroup.controls.deliveryPostcode.setValidators([]);
        this.infoFrmGroup.controls.deliveryState.setValidators([]);

        this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();
        this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();
        this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();
        this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();
        this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
      }
    }
    if (e.selectedIndex == 1 || e.selectedIndex == 2) this.applyPromoCode();

    // Unique page view tracking for certain affiliate
    if (
      this.partnerService.getPartnerAttribute(
        this.partnerCode,
        "uniquePageViews"
      )
    ) {
      this.uniquePageView.forEach((x) => {
        if (e.selectedIndex == x.index) {
          window.dataLayer.push({
            event: "virtualPageview",
            pageUrl: window.location.href,
            pageTitle: `${this.partnerCode} ${x.pageTitle}`
          });
        }
      });
    }
  }

  updateStepperIndex(index: number): void {
    this.footerMarginService.updateStepperIndex(index);
  }

  applyPromoCode() {
    if (!this.promoCode) return;
    this.ngxSpinnerService.show();
    this.promoError = null;
    this.promo = null;

    let quotationId = null;

    // Send quotationId if carsome affiliate, (to not allow promocode if x logic)
    if (this.partnerCode == "carsome") {
      quotationId = this.selectedQuotation.id;
    }

    this.motorDataDataService
      .applyPromoCode(
        this.id,
        this.promoCode,
        "",
        "",
        this.getGrossPremium(),
        quotationId
      )
      .subscribe({
        next: (x) => {
          this.promo = x;
          this.ngxSpinnerService.hide();
          this.promoCheck();

          if (this.promo) {
            this.getEppRateList(this.promo.id);
          }
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.promoError = err.error.message;
        }
      });
  }

  clearPromoCode() {
    this.promoCode = null;
    this.promoError = null;
    this.promo = null;
    this.getEppRateList();
  }

  getFormArray() {
    return this.addOnFrmGroup.get("drivers") as FormArray;
  }

  getAgreeFormArray() {
    return this.agreementFrmGroup.get("doc") as FormArray;
  }

  addDriver() {
    let frmArray = this.getFormArray();
    let form = this.formBuilder.group({
      name: [null, Validators.required],
      idType: [this.idTypeList[0].codeValue, Validators.required],
      identityNo: [
        null,
        [Validators.required, ValidatorFunction.nricValidator()]
      ],
      dob: [null, Validators.required],
      gender: ["M", Validators.required],
      maritalStatus: ["Single", Validators.required],
      relationship: [null, Validators.required],
      isMalaysian: [true]
    });

    form.get("idType").valueChanges.subscribe((value) => {
      if (value === "IC_NO") {
        form
          .get("identityNo")
          .setValidators([
            Validators.required,
            ValidatorFunction.nricValidator()
          ]);
      } else {
        form.get("identityNo").setValidators(Validators.required);
      }

      if (["IC_NO", "POLICE/ARMY"].includes(value)) {
        form.get("isMalaysian").patchValue(true);
      } else {
        form.get("isMalaysian").patchValue(false);
      }

      form.get("identityNo").reset();
      form.get("identityNo").updateValueAndValidity();
    });

    form.get("identityNo").valueChanges.subscribe((value) => {
      if (
        form.get("identityNo").invalid &&
        form.get("idType").value === "IC_NO"
      ) {
        form.get("dob").reset();
      }

      if (
        form.get("identityNo").valid &&
        form.get("idType").value === "IC_NO"
      ) {
        let date = moment(value.substring(0, 6), "YYMMDD");
        let lastChar = value.substring(11);
        let gender = lastChar % 2 ? "M" : "F";

        if (moment().isBefore(date)) {
          date.subtract(100, "year");
        }
        form.patchValue({
          dob: date.toDate(),
          gender: gender
        });
      }
    });

    frmArray.push(form);
  }

  deleteDriver(i: number) {
    let frmArray = this.getFormArray();
    frmArray.removeAt(i);
  }

  selectQuotation(id: string) {
    let lastQuotationId = this.quotationId;
    this.quotationFrmGroup.controls.id.setValue(id);
    this.quotationId = id;
    this.selectedQuotation = this.quotationList.find((x) => x.id == id);
    this.isChubbInstantQuotation = (this.selectedQuotation.isInstantQuotation && this.selectedQuotation.name === 'Chubb')
    this.checkIfNeedRecalculatePricing();
    this.router.navigate([], {
      queryParams: {
        id: id,
        partnerCode: this.partnerCode
      }
    });
    if (this.addOnList.length == 0) this.getAddOn();
    else if (lastQuotationId != this.quotationId) {
      this.addOnList = [];
      this.initAddOnForm();
      this.getAddOn();
      this.selectedAddOn = [];
      this.isDriverChecked = false;
      this.isFloodChecked = false;
      this.isVBETChecked = false;
    }
    this.getProductDocument();
    this.stepper.next();
  }

  checkIfNeedRecalculatePricing(){
    // for some instant quotation cases, Order Summary will be hidden if:
    //  - the quotation's pricing required some pricing recalculation (with or without addon selected)
    //  - the add on items are retrieved from insurer's API (not from our database), so the premium is unknown until recalculation
    this.requirePremiumRecalculation = this.isChubbInstantQuotation;
  }

  editQuotation() {
    let dialogRef = this.dialog.open(EditQuotationComponent, {
      data: {
        id: this.id,
        request: this.requestData,
        quotationList: this.quotationList,
        grabSingleQuotation:
          this.partnerCode == "grab" &&
          this.requestData.eHailingUsed &&
          this.quotationList.length == 1
            ? true
            : false
      },
      panelClass: "edit-quote-dialog"
    });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.dialog.open(SubmitSuccessDialogComponent, {
          data: {
            editQuotations: true
          },
          panelClass: "success-dialog"
        });
      }
    });
  }

    // -- Other insurer section
    updateSelectedInsurers(id: string) {
      const index = this.selectedOtherInsurers.indexOf(id);
      if (index === -1) {
        this.selectedOtherInsurers.push(id);
      } else {
        this.selectedOtherInsurers.splice(index, 1);
      }
    }
  
    submitOtherInsurer() {
      let typeOfSum = this.requestData.typeOfSum ?? "MV";
  
      this.motorQuotationService
        .submitExtraProduct(this.id, typeOfSum, this.selectedOtherInsurers)
        .subscribe({
          next: (x) => {
            this.handleSuccessSubmitQuotation({
              preferredChannel: this.requestData?.preferredChannel,
              email: this.requestData.email,
              mobileNo: this.requestData.mobileNo,
            });
          },
        });
    }
    // -- Other insurer section

    get hideStepperLabel() {
      return this.stepper?.selectedIndex === 0 && this.isInsurancePlaceholderEnabled ? "hide-stepper" : "";
    }  
  
    get sortedQuotationList() {
      return this.quotationList.sort((a, b) => {
        if (a.status === "New" && b.status !== "New") {
          return -1; // "New" always on top
        }
        if (a.status !== "New" && b.status === "New") {
            return 1; // Place "New" above others
        }
        if (a.status === "Rejected" && b.status !== "Rejected") {
            return -1; // "Rejected" comes after "New" but before others
        }
        if (a.status !== "Rejected" && b.status === "Rejected") {
            return 1; // Place "Rejected" below "New"
        }
        return 0;
      });
    }

    get disableEditQuotationBtn() {
      const availableCount = this.quotationList.filter(
        (x) => x.status == "Available",
      ).length;
      return availableCount == 0;
    }
  
    private handleSuccessSubmitQuotation(
      customerInput: Partial<MotorQuotationInterface>,
    ): void {
      const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
        panelClass: "success-dialog",
        data: { customerInput },
      });
  
      dialogRef.afterClosed().subscribe(() => location.reload());
    }

  carDetails() {
    let dialogRef = this.dialog.open(CarDetailsDialogComponent, {
      data: this.requestData
    });

    dialogRef.afterClosed().subscribe((x) => {});
  }

  carPlan() {
    let dialogRef = this.dialog.open(CarPlanDialogComponent, {
      data: this.requestData
    });

    dialogRef.afterClosed().subscribe((x) => {});
  }

  getAddOnListByCategory(val: string) {
    return this.addOnList.filter((x) => x.category == val);
  }

  isAddOnSelected(item: ProductAddOnInterface) {
    if (item) {
      return this.selectedAddOn.some((x) => x.id == item.id);
    }

    return null;
  }

  getPrice(item: ProductAddOnInterface, val?: any) {
    try {
      let evaluate = item.evaluate;
      let condition = item.condition;

      if (val) {
        evaluate = evaluate.replace("{val}", val);

        if (condition) {
          condition = condition.replace("{val}", val);
          if (item.isConditionFirst) {
            let status = Boolean(eval(condition));
            if (status) {
              return eval(evaluate);
            } else {
              return 0;
            }
          }
        }
      }

      return eval(evaluate);
    } catch {
      return 0;
    }
  }

  toggleAddOn(e: MatCheckboxChange) {
    let value: ProductAddOnInterface = e.source.value as any;
    if (e.checked) {
      if (value.code === "EHailing") {
        this.toggleAddonPlanCard("none", "roadTaxRenewalClass");
      }
      if (value.category === "LLTP") {
        this.addOnFrmGroup.controls.lltpSeats.setValue(this.numberOfLltpSeats);
      }
      this.selectedAddOn.push(value);
    } else {
      if (value.code === "EHailing") {
        this.toggleAddonPlanCard("block", "roadTaxRenewalClass");
      }
      let index = this.selectedAddOn.findIndex((x) => x.id == value.id);
      if (index >= 0) {
        this.selectedAddOn.splice(index, 1);
      }
      if (value.category === "Windshield") {
        this.addOnFrmGroup.controls.windshield.setValue(
          this.getMinMaxWindscreenPrice(value, "min")
        );
      }
    }
    if (value.category == "Drivers") {
      let frmArray = this.getFormArray();
      frmArray.reset();
      frmArray.clear();
      if (e.checked && value.code == "AdditionalDrivers") this.addDriver();
    }
  }

  paymentTypeChange(paymentType: string) {
    this.selectedPayment = paymentType;
  }

  radioChange(e: ProductAddOnInterface, isCheckbox: boolean = false) {
    let index = this.selectedAddOn.findIndex((x) => x.category == e.category);
    if (index >= 0) this.selectedAddOn.splice(index, 1);
    if (!isCheckbox || index == -1) {
      this.selectedAddOn.push(e);
    }

    let frmArray = this.getFormArray();
    if (frmArray.length == 0 && e.code == "AdditionalDrivers") this.addDriver();
    else {
      frmArray.reset();
      frmArray.clear();
    }
  }

  toggleRoadTax(e: MatCheckboxChange) {
    let value: any = e.source.value as any;
    if (e.checked) {
      this.toggleAddonPlanCard("none", "EHailing");
      this.showRoadTaxList = true;
      this.isIncludeDigitalRoadtax = true;
    }
    if (
      !this.requestData.isMalaysian ||
      this.requestData.isCompany ||
      !e.checked
    ) {
      this.showRoadTaxList = false;
      this.isIncludeDigitalRoadtax = false;
      this.addOnFrmGroup.controls.roadTaxPrice.setValue(
        this.requestData.originalRoadTaxPrice
      );
    }
    if (!e.checked) this.toggleAddonPlanCard("flex", "EHailing");
  }

  toggleAddonPlanCard(display: string, className: string) {
    const elements = document.getElementsByClassName(className);
    for (let i = 0; i < elements.length; i++) {
      (elements[i] as HTMLElement).style.display = display;
    }
    if (display === "none") {
      if (className === "roadTaxRenewalClass") {
        this.callToggleRoadTax(false);
      } else {
        this.selectedAddOn = this.selectedAddOn.filter(
          (x) => x.category !== className
        );
      }
    }
  }

  callToggleRoadTax(checked: boolean) {
    const event: MatCheckboxChange = {
      checked: checked,
      source: {
        value: "RoadTaxCheckbox"
      } as any
    };
    this.toggleRoadTax(event);
  }

  isRoadTaxSelected(item: any) {
    if (item) {
      return (this.showRoadTaxList = true);
    }
    return (this.showRoadTaxList = false);
  }

  radioDigitalRoadTaxChange(e: any, isChecked: boolean = false) {
    if (e.value == undefined) {
      this.isIncludeDigitalRoadtax = true;
      this.addOnFrmGroup.controls.roadTaxPrice.setValue(
        this.requestData.originalRoadTaxPrice
      );
    } else {
      this.isIncludeDigitalRoadtax = false;
      this.addOnFrmGroup.controls.roadTaxPrice.setValue(
        this.requestData.originalRoadTaxPrice + 15
      );
    }
  }

  getRule(e: ProductAddOnInterface) {
    if (e.rules) {
      return JSON.parse(e.rules);
    }
    return null;
  }

  hasSelectedCode(val: string) {
    return this.selectedAddOn.some((x) => x.code == val);
  }

  hasSelectedCategory(val: string) {
    return this.selectedAddOn.some((x) => x.category == val);
  }

  // -- Not used anymore at #freeDriverText
  // getConditionMax(e: ProductAddOnInterface) {
  //   if (e.code != 'AdditionalDrivers' || !e.condition) return 0;
  //   let count = 1;
  //   for (let index = 1; index < 9999; index++) {
  //     let condition = e.condition.replace('{val}', index.toString());
  //     let status = Boolean(eval(condition));
  //     if (status) {
  //       count = index - 1;
  //       break;
  //     }
  //   }
  //   return count;
  // }

  getGrossPremium() {
    if (!this.requestData && !this.selectedQuotation) return 0;

    return this.selectedQuotation?.totalBasePremium + this.getTotalAddOnPrice();
  }

  getTotalAddOnPrice() {
    let total = 0;

    this.selectedAddOn.forEach((x) => {
      if (this.isChubbInstantQuotation) {
        total += this.getPrice(x);
      }
      else {
        if (x.code == "AdditionalDrivers")
          total += this.getPrice(x, this.getFormArray().length);
        else if (x.category == "Windshield")
          total += this.getPrice(x, this.addOnFrmGroup.value.windshield);
        else if (x.category == "LLTP")
          total += this.getPrice(x, this.numberOfLltpSeats);
        else if (x.category == "MERW") {
          if (this.addOnFrmGroup.value.merw) {
            total += this.addOnFrmGroup.value.merw;
          } else {
            total += this.getPrice(x, "0");
          }
        } else total += this.getPrice(x);
      }
    });
    return total;
  }

  getTax() {
    if (!this.selectedQuotation) return 0;
    let total = this.getGrossPremium();

    return total * this.selectedQuotation.taxRate;
  }

  getTotalNetPremium() {
    var total =
      Number(this.getGrossPremium().toFixed(2)) +
      Number(this.getTax().toFixed(2)) +
      this.selectedQuotation?.stampDutyPrice;
    total =
      this.selectedQuotation &&
      this.selectedQuotation.code.toLowerCase() === "takaful_malaysia"
        ? CommonFunction.customSTMBRound(total)
        : total;

    return total;
  }

  getPlatformDiscountRate() {
    return (
      this.selectedQuotation?.platformDiscountRate * this.getGrossPremium()
    );
  }

  getTotalPromo() {
    if (!this.promo) return 0;

    let evaluate = this.promo.evaluate;
    evaluate = evaluate.replace(
      "{TotalGrossPremium}",
      this.getGrossPremium().toString()
    );
    return Number.parseFloat(eval(evaluate));
  }

  getPremium() {
    // Total without serviceFee
    let totalBeforeCharges = this.getTotalCharge() - this.getTotalPromo();
    return totalBeforeCharges;
  }

  getShopbackInstalmentCharges() {
    let charges = (1.5 / 100) * this.getPremium();
    return charges;
  }

  getBankInstalmentCharges() {
    return this.getPremium() * this.getEppRate();
  }

  getBNPLCharges() {
    // return the BNPL Service Tax Rate
    //  - Total Service Tax Rate - Portal Service Tax Rate = BNPL Service Tax Rate
    let paymentProvider = this.paymentFormGroup?.value?.paymentProvider;
    const bnplRate = this.bnplRateList?.filter(
      (entry) => entry.paymentMethod == paymentProvider
    );
    if (bnplRate.length > 0){
      let bnplServiceTaxRate = bnplRate[0].isSubsidized ? 0 : bnplRate[0].totalServiceTaxRate - this.selectedQuotation.serviceTaxRate;
      let bnplServiceFee = bnplServiceTaxRate * (this.getTotalCharge() - this.getTotalPromo());
      return Number(bnplServiceFee.toFixed(2));
    }
    else {
      return 0;
    }
  }

  getFinalTotalBeforeCharges() {
    // Total with serviceFee, without instalmentCharges
    return this.getTotalCharge() - this.getTotalPromo() + this.getServiceFee() + this.getBNPLCharges();
  }

  getTotalCharge() {
    let total = this.getTotalNetPremium() - this.getPlatformDiscountRate();
    if (this.addOnFrmGroup.value?.isIncludeRoadtax) {
      total = total + this.requestData.originalRoadTaxPrice;

      // If digital road tax is not selected, add delivery price
      if (!this.isIncludeDigitalRoadtax) {
        total = total + this.requestData.roadTaxDeliveryPrice;
      }
    }

    return total;
  }

  getServiceFee() {
    if (!this.selectedQuotation) return 0;

    //Requested by Siew Wai to increase the Service Fee rate by 0.2% if Digital Road Tax is selected. (Removed for now)
    //const digiRoadTaxServiceFeeRateIncrease = 0.002;
    //let serviceFeeDigital  = (this.selectedQuotation.serviceTaxRate + digiRoadTaxServiceFeeRateIncrease) * (this.getTotalCharge() - this.getTotalPromo())
    let serviceFee =
      this.selectedQuotation.serviceTaxRate *
      (this.getTotalCharge() - this.getTotalPromo());

    if (
      this.isPaymentChannelFPX &&
      (this.partnerCode === "carsome" ||
        this.partnerCode === "wapcar" ||
        this.partnerCode === "carlist")
    ) {
      serviceFee = 1;
      return Number(serviceFee.toFixed(2));
    } else {
      // if (this.isIncludeDigitalRoadtax) {
      // 	return Number(serviceFeeDigital.toFixed(2));
      // } else {
      return Number(serviceFee.toFixed(2));
      //}
    }
  }

  getTotal() {
    let totalBeforeCharges =
      this.getTotalCharge() + this.getServiceFee() + this.getBNPLCharges() - this.getTotalPromo();

    if (this.isPaymentTypeEPP) {
      return totalBeforeCharges + this.getBankInstalmentCharges();
    } else {
      // If not shopback
      return totalBeforeCharges;
    }
  }

  getPaymentDurationLabel() {
    let paymentFGroup = this.paymentFormGroup?.value;
    if (paymentFGroup?.paymentType == this.paymentOptionEPP) {
      if (paymentFGroup?.installmentMonth == 3) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getTotal() / 3,
          "1.2-2"
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for3months")}`;
      } else if (paymentFGroup?.installmentMonth == 6) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getTotal() / 6,
          "1.2-2"
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for6months")}`;
      } else if (paymentFGroup?.installmentMonth == 12) {
        return `<span class='bold'>RM ${this._decimalPipe.transform(
          this.getTotal() / 12,
          "1.2-2"
        )}</span>
				${this.translate.instant("common.perMonth")}
				${this.translate.instant("paymentOptions.for12months")}`;
      }
    }
    return "";
  }

  getIdentityNoLabel() {
    if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
      return "common.icNo";
    else if (!this.requestData?.isMalaysian) return "common.passport";
    return "common.businessRegistrationNo";
  }

  getNameLabel() {
    if (this.requestData?.isMalaysian && !this.requestData?.isCompany)
      return "common.namePerIc";
    else if (!this.requestData?.isMalaysian) return "common.namePerPassport";
    return "common.companyName";
  }

  isAddressValid() {
    return !(
      this.infoFrmGroup.controls.address.invalid ||
      this.infoFrmGroup.controls.address2.invalid ||
      this.infoFrmGroup.controls.city.invalid ||
      this.infoFrmGroup.controls.state.invalid
    );
  }

  isSameAddressChecked() {
    if (!this.isAddressValid()) {
      return false;
    }
    let value = this.infoFrmGroup.getRawValue();
    let addr = `${value.address || ""}${value.address2 || ""}${
      value.city || ""
    }${value.postcode || ""}${value.state || ""}`;
    let deliveryAddr = `${value.deliveryAddress || ""}${
      value.deliveryAddress2 || ""
    }${value.deliveryCity || ""}${value.deliveryPostcode || ""}${
      value.deliveryState || ""
    }`;
    return addr == deliveryAddr;
  }

  addressIsChecked(e: MatCheckboxChange) {
    this.isSameAddressCheck = e.checked;
    let validatorList = e.checked ? [] : [Validators.required];
    if (!e.checked) {
      this.infoFrmGroup.controls.deliveryAddress.reset();
      this.infoFrmGroup.controls.deliveryAddress2.reset();
      this.infoFrmGroup.controls.deliveryCity.reset();
      this.infoFrmGroup.controls.deliveryPostcode.reset();
      this.infoFrmGroup.controls.deliveryState.reset();
    }

    this.infoFrmGroup.controls.deliveryAddress.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryAddress2.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryCity.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryPostcode.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryState.setValidators(validatorList);

    this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
  }

  differentAddressIsChecked(e: MatCheckboxChange) {
    this.isDifferentAddressCheck = e.checked;
    let validatorList = e.checked ? [Validators.required] : [];

    if (e.checked) {
      this.infoFrmGroup.controls.deliveryAddress.reset();
      this.infoFrmGroup.controls.deliveryAddress2.reset();
      this.infoFrmGroup.controls.deliveryCity.reset();
      this.infoFrmGroup.controls.deliveryPostcode.reset();
      this.infoFrmGroup.controls.deliveryState.reset();
    }

    this.infoFrmGroup.controls.deliveryAddress.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryAddress2.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryCity.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryPostcode.setValidators(validatorList);
    this.infoFrmGroup.controls.deliveryState.setValidators(validatorList);

    this.infoFrmGroup.controls.deliveryAddress.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryAddress2.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryCity.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryPostcode.updateValueAndValidity();
    this.infoFrmGroup.controls.deliveryState.updateValueAndValidity();
  }

  submit() {
    if (this.agreementFrmGroup.invalid) return;
    this.router.navigate([], {
      queryParams: {
        id: this.quotationId,
        pay: true
      }
    });
    this.ngxSpinnerService.show();
    this.motorDataDataService
      .generatePayment(this.id, this.buildGeneratePaymentFormData())
      .subscribe({
        next: (x) => {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              id: x.id,
              partnerCode: this.partnerCode
            }
          };
          this.ngxSpinnerService.hide();
          this.router.navigate(["/payment"], navigationExtras);
        },
        error: (err) => {
          this.alertService.openSnackBar(err.error);
          this.ngxSpinnerService.hide();
        }
      });
  }

  private buildGeneratePaymentFormData(): FormData {
    const { windshield, drivers, isIncludeRoadtax, merw, lltpSeats } =
      this.addOnFrmGroup.value;
    const {
      occupation,
      address,
      address2,
      city,
      state,
      deliveryAddress,
      deliveryAddress2,
      deliveryCity,
      deliveryPostcode,
      deliveryState,
      name,
      email,
      mobileNo,
      mileage
    } = this.infoFrmGroup.value;
    const formData = new FormData();

    formData.append("quotationId", this.quotationId);
    if (windshield) {
      formData.append("windShield", windshield);
    }
    drivers?.forEach((driver, index) => {
      const {
        dob,
        gender,
        idType,
        identityNo,
        maritalStatus,
        name,
        relationship,
        isMalaysian
      } = driver;
      formData.append(
        `additionalDriverList[${index}].Dob`,
        (<Date>dob)?.toISOString() || ""
      );
      formData.append(`additionalDriverList[${index}].Gender`, gender);
      formData.append(`additionalDriverList[${index}].IdType`, idType);
      formData.append(`additionalDriverList[${index}].IdentityNo`, identityNo);
      formData.append(
        `additionalDriverList[${index}].MaritalStatus`,
        maritalStatus
      );
      formData.append(`additionalDriverList[${index}].Name`, name);
      formData.append(
        `additionalDriverList[${index}].Relationship`,
        relationship
      );
      formData.append(
        `additionalDriverList[${index}].IsMalaysian`,
        isMalaysian
      );
    });
    this.selectedAddOn.forEach((addOn, index) => {
      formData.append(`addOnIds[${index}]`, addOn.id);
    });
    formData.append("occupation", occupation);
    formData.append("address", address);
    formData.append("address2", address2);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("deliveryAddress", deliveryAddress || address);
    formData.append("deliveryAddress2", deliveryAddress2 || address2);
    formData.append("deliveryCity", deliveryCity || city);
    formData.append(
      "deliveryPostcode",
      deliveryPostcode || this.requestData?.postcode
    );
    formData.append("deliveryState", deliveryState || state);
    formData.append("includeRoadTax", isIncludeRoadtax ?? false);
    formData.append("includeDigitalRoadTax", `${this.isIncludeDigitalRoadtax}`);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobileNo", mobileNo);
    formData.append("seat", lltpSeats);

    if (this.partnerCode === "ktmb") {
      this.buildMileagePhotoFormData(formData);
    }
    this.buildPaymentFormData(formData);

    if (merw) {
      formData.append("merw", merw);
    }

    if (mileage !== null) {
      formData.append("mileage", mileage);
    }

    if (this.promo?.id) {
      formData.append("promoCodeId", this.promo?.id);
    }

    if (this.isChubbInstantQuotation) {
      this.chubbAddOnList.forEach((item, index) => {
        formData.append(`ChubbAddOnList[${index}].Code`, item.code);
        formData.append(`ChubbAddOnList[${index}].extracovprem`, item.extracovprem.toString());
      });
    }

    return formData;
  }

  private buildPaymentFormData(formData: FormData): void {
    let paymentFGroup = this.paymentFormGroup?.value;
    // To change payment channel for e-wallet and EPP
    if (paymentFGroup?.paymentChannel == this.paymentOptionEwallet)
      paymentFGroup.paymentChannel = this.paymentFormGroup?.value?.ewalletType;
    if (paymentFGroup?.paymentType == this.paymentOptionEPP)
      paymentFGroup.paymentChannel = this.paymentFormGroup?.value?.bank;
    // To change payment type null for shopback
    if (paymentFGroup?.paymentProvider == this.paymentOptionShopback)
      paymentFGroup.paymentType = null;

    formData.append(
      "paymentProvider",
      this.paymentFormGroup?.value?.paymentProvider || this.paymentOptionRazer
    );
    formData.append(
      "paymentType",
      paymentFGroup.paymentType || this.paymentOptionFullPrice
    );
    formData.append(
      "paymentChannel",
      paymentFGroup.paymentChannel || this.paymentOptionVisa
    );

    if (this.paymentFormGroup?.value?.installmentMonth) {
      formData.append(
        "installmentMonth",
        this.paymentFormGroup?.value?.installmentMonth
      );
    }
  }

  private buildMileagePhotoFormData(formData: FormData): void {
    const { mileagePhoto } = this.infoFrmGroup.value;

    formData.append(`kTMBFileLists[0].type`, "Mileage");
    for (const file of mileagePhoto) {
      formData.append(`kTMBFileLists[0].files`, file);
    }
  }

  getFormArrayControlError(i, formControlName) {
    return (this.getFormArray().controls[i] as FormGroup).controls[
      formControlName
    ].errors;
  }

  benefitDialog(item) {
    this.dialog.open(BenefitDialogComponent, {
      panelClass: "benefit-dialog",
      data: {
        productId: item.productId,
        code: item.code,
        lang: this.translate.currentLang,
        insuranceType: this.requestData.insuranceType
      }
    });
  }

  getAddOnCode(code) {
    return `addOn.${code}`;
  }

  hasAddOn() {
    return this.selectedAddOn.filter((x) => !x.hidePriceBreakdown).length != 0;
  }

  addonLabel(value: number) {
    return "RM " + value.toFixed(2).toLocaleString();
  }

  filterRecommendedAddOns() {
    this.addOnList = this.addOnList.filter(
      (x) => x.category == "Flood" || x.category == "Windshield"
    );
    if (!this.requestData.eHailingUsed) {
      this.addOnFrmGroup.controls.isIncludeRoadtax.setValue(true);
      this.isIncludeDigitalRoadtax = true;
    }
  }

  toggleRecommendedAddOns(boolean: any) {
    this.toggleRecommendedAddOnsState = boolean;

    if (boolean) {
      this.getAddOn();
      this.isIncludeDigitalRoadtax = true;
    }
    if (!boolean) {
      this.getAddOn();
      this.addOnFrmGroup.controls.isIncludeRoadtax.setValue(false);
      this.isIncludeDigitalRoadtax = false;
      this.selectedAddOn.splice(
        this.selectedAddOn.findIndex((x) => x.category === "Flood"),
        1
      );
      this.selectedAddOn.splice(
        this.selectedAddOn.findIndex((x) => x.category === "Windshield"),
        1
      );
    }
  }

  openCarVariantDialog(id: string) {
    let dialogRef = this.dialog.open(CarVariantDialogComponent, {
      panelClass: "edit-car-variant-dialog",
      data: {
        requestData: this.requestData,
        linkId: this.id,
        cssClass: this.partnerCode === "ktmb" ? "ktmb-banner" : ""
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.data === true) {
        this.selectQuotation(id);
      }
    });
  }

  backToStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  get insurerFullName(): string {
    if (this.selectedQuotation && this.selectedQuotation.code) {
      return INSURER_FULL_NAME[this.selectedQuotation.code];
    }
    return "";
  }

  getPSComissionPrice() {
    // PS comission, only shown in Allianz quote in order summary section
    let comission = this.getGrossPremium() * (10 / 100);
    return Number(comission.toFixed(2));
  }

  getAgreementLinks(): Record<string, string> {
    switch (this.selectedQuotation?.code?.toUpperCase()) {
      case "MSIG":
        return {
          pds: "https://storage.googleapis.com/ps-asset/motor/policy_docs/MSIG%2BEZ-Mile%2BPDS.pdf",
          faq: "https://storage.googleapis.com/ps-asset/motor/policy_docs/EZ-Mile%2BFAQ%2B(ENG).pdf"
        };
      case "RHB":
        return {
          pds: "https://storage.googleapis.com/ps-asset/motor/policy_docs/Private_Car_PDS_M_PCP.pdf",
          faq: "https://storage.googleapis.com/ps-asset/motor/policy_docs/RHBI_Motor%20Saver_FAQ.pdf"
        };
      case "SOMPO":
        return {
          pds: "https://storage.googleapis.com/ps-asset/motor/policy_docs/SOMPO%20MotorSafe%20PDS.pdf",
          faq: "https://storage.googleapis.com/ps-asset/motor/policy_docs/SOMPO%20MotorSafe%20FAQ%20(EN).pdf"
        };
      default:
        return {
          pds: "",
          faq: ""
        };
    }
  }

  getMinMaxWindscreenPrice(
    addOn: ProductAddOnInterface,
    key: "min" | "max"
  ): number {
    const rule = this.getRule(addOn);
    const { maxWindshieldPrice, minWindshieldPrice } = this.selectedQuotation;

    const config = {
      max: maxWindshieldPrice || rule?.max || 10000,
      min: minWindshieldPrice || rule?.min || 500
    };

    return config[key];
  }

  recalculatePremium(){
    if (this.isChubbInstantQuotation && this.requirePremiumRecalculation) {
      this.ngxSpinnerService.show();
      // for Chubb, the premium will be recalculated (with or without extra coverages selected) through their API
      //  - pass in the whole selectedQuotation and selectedAddOn for data massaging
      const chubbSelectedAddOn = this.selectedAddOn.map(item => {
        if (item.code === 'VV89') {
          return {
            Code: item.code,
            ExtraSum: parseInt(this.addOnFrmGroup.value.windshield)
          };
        } else {
          return {
            Code: item.code,
            ExtraSum: parseInt(item.evaluate)
          };
        }
      });

      this.motorDataDataService.recalculateChubbPremium(this.quotationId, chubbSelectedAddOn).subscribe({
        next: (x) => {
          this.chubbAddOnList = Object.values(x);

          this.selectedAddOn= this.selectedAddOn.map(item => {
            const matchingItem = this.chubbAddOnList.find(xItem => xItem.code === item.code); // Find item in x with matching code
            return {
              ...item, // Keep other properties of the item
              evaluate: matchingItem ? matchingItem.extracovprem : 0 // Update extracovprem if matched, else default to 0
            };
          });
      
          this.ngxSpinnerService.hide();
        },
        error: (err) => {
          this.ngxSpinnerService.hide();
          this.alertService.openSnackBar(err.error);
        }
      })

    }
  }
}

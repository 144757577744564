<div>
	<div class="success-graphic">
		<img src="assets/image/submit-success-dialog.png" alt="" />
		<div>
			{{ "success.thankYou" | translate }}
		</div>
	</div>
	<div class="success-content">
				{{ "success.selected1" | translate }} 
				<span [style.color]="'#49b0ff'"><b>{{ "common.AV" | translate }}</b></span>
				{{ "success.selected2" | translate }} 

				<span *ngIf="onOperation">
					{{ "success.selectedOpenHours" | translate }}
				</span>

				<span *ngIf="!onOperation">
				{{ "success.selectedCloseHours" | translate }}
				</span>
		<button mat-flat-button mat-primary mat-dialog-close>
			{{ "success.close" | translate }}
		</button>
	</div>
</div>

<ng-container *ngIf="quotationSubmitted; else notSubmitted">
  <app-loading-quotation
    [inputData]="quotationInputData"
    [formData]="quotationFormData"
    (resetFlag)="updateResetFlag($event)"
  ></app-loading-quotation>
</ng-container>

<ng-template #notSubmitted>
  <ng-container [ngTemplateOutlet]="bannerView ? banner : normal">
  </ng-container>

  <ng-template #normal>
    <div class="normal-container">
      <div class="normal-form-container">
        <div class="forms">
          <mat-card>
            <div class="title">
              <span *ngIf="stepper.selectedIndex === 0">
                {{ "dashboard.title" | translate }}
              </span>
              <span *ngIf="stepper.selectedIndex === 1">
                {{ "dashboard.title2" | translate }}
              </span>
            </div>
            <mat-stepper #stepper linear>
              <!-- Vehicle Form -->
              <mat-step [stepControl]="vehicleFormGroup">
                <form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
                  <ng-template matStepLabel>
                    <span>{{ "dashboard.stepOne" | translate }}</span>
                  </ng-template>

                  <div class="form-wrapper">
                    <mat-form-field
                      [class.ktmb-disabled]="partnerCode === 'ktmb'"
                      appearance="outline"
                    >
                      <mat-label>{{
                        "common.typeOfInsurance" | translate
                      }}</mat-label>
                      <mat-select
                        disableOptionCentering
                        formControlName="insuranceType"
                        required
                      >
                        <mat-option
                          *ngFor="let item of typeOfInsuranceList"
                          [value]="item.id"
                        >
                          {{ item.name | translate }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        >{{ "common.typeOfInsurance" | translate }}
                        {{
                          vehicleFormGroup.controls.insuranceType.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >

                      <mat-icon
                        class="tooltip-icon"
                        [matMenuTriggerFor]="typeTooltip"
                        #typeTrigger="matMenuTrigger"
                        (mouseenter)="typeTrigger.openMenu()"
                      >
                        info
                      </mat-icon>
                      <mat-menu
                        #typeTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover dashboard"
                      >
                        <span (mouseleave)="typeTrigger.closeMenu()">
                          <div class="title">
                            {{ "common.COM" | translate }}
                          </div>
                          {{ "common.comprehensiveInsDesc" | translate }}
                          <ng-container *ngIf="partnerCode !== 'ktmb'">
                            <br />
                            <br />
                            <div class="title">
                              {{ "common.TPFT" | translate }}
                            </div>
                            {{ "common.fireTheftInsDesc" | translate }}
                          </ng-container>
                        </span>
                      </mat-menu>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="upperc-input">
                      <mat-label>{{
                        "common.carPlateNo" | translate
                      }}</mat-label>
                      <input
                        matInput
                        required
                        formControlName="carPlate"
                        mask="A{255}"
                        [validation]="false"
                        autocomplete="off"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "common.residentialPostcode" | translate
                      }}</mat-label>
                      <input
                        matInput
                        required
                        formControlName="postcode"
                        mask="00000"
                        autocomplete="postal-code"
                      />
                      <mat-error
                        style="display: block !important"
                        [hidden]="
                          !vehicleFormGroup.controls.postcode?.errors
                            ?.customError
                        "
                      >
                        {{ "error.invalidPostcode" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <section
                    *ngIf="
                      vehicleFormGroup.get('insuranceType').value !== 'TPFT'
                    "
                    class="mt-xs"
                  >
                    <mat-label
                      >{{ "common.carUsedForEHailing" | translate }}.
                    </mat-label>
                    <mat-radio-group
                      class="b2c-radio ehailing-radio"
                      formControlName="eHailingUsed"
                    >
                      <mat-radio-button [value]="true"
                        >{{ "common.yes" | translate }}
                      </mat-radio-button>
                      <mat-radio-button [value]="false"
                        >{{ "common.no" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>

                  <section
                    class="box-selection"
                    *ngIf="showAgreeOrMarketValueBtn"
                  >
                    <span class="label">
                      <mat-label>{{
                        "common.insuredType" | translate
                      }}</mat-label>
                    </span>
                    <mat-radio-group
                      class="b2c-radio"
                      aria-label="Select an option"
                      formControlName="insuredType"
                    >
                      <mat-radio-button value="MV"
                        >{{ "common.MV" | translate }}
                      </mat-radio-button>

                      <mat-radio-button
                        value="AV"
                        *ngIf="
                          vehicleFormGroup.get('insuranceType').value !== 'TPFT'
                        "
                        >{{ "common.AV" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>

                  <button
                    class="nextBtn"
                    mat-flat-button
                    matStepperNext
                    [disabled]="vehicleFormGroup.invalid"
                  >
                    {{ "common.next" | translate }}
                  </button>
                </form>
              </mat-step>

              <!-- Customer Form -->
              <mat-step [stepControl]="custFormGroup">
                <form
                  [formGroup]="custFormGroup"
                  (ngSubmit)="submit()"
                  #custForm="ngForm"
                >
                  <ng-template matStepLabel>
                    <span>{{ "dashboard.stepTwo" | translate }}</span>
                  </ng-template>

                  <div class="form-wrapper">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ "common.idType" | translate }} </mat-label>
                      <mat-select formControlName="idType">
                        <mat-option
                          *ngFor="let item of idTypeList"
                          [value]="item.id"
                        >
                          {{ item.description | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <!-- Detais for foreigner only -->
                    <mat-form-field appearance="outline" *ngIf="!isMalaysian">
                      <mat-label>{{
                        "common.nationality" | translate
                      }}</mat-label>
                      <mat-select formControlName="nationality">
                        <mat-option>
                          <ngx-mat-select-search
                            [placeholderLabel]="
                              'common.nationalityPH' | translate
                            "
                            [ngModelOptions]="{ standalone: true }"
                            [searching]="!countryList.length"
                            noEntriesFoundLabel="{{
                              'common.nationalityXrecord' | translate
                            }}"
                            (ngModelChange)="filterCountry($event)"
                            ngModel
                            ngDefaultControl
                          >
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option
                          *ngFor="let item of filterCountryList"
                          [value]="item"
                        >
                          {{ item }}</mat-option
                        >
                      </mat-select>
                      <mat-error
                        >{{ "common.nationality" | translate }}
                        {{
                          custFormGroup.controls.nationality.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>
                        {{ getIdentityNoLabel() | translate }}
                      </mat-label>
                      <input
                        matInput
                        required
                        formControlName="identityNo"
                        [mask]="getIdentityNoMasking()"
                        autocomplete="off"
                        [maxLength]="isMalaysian && !isCompany ? 12 : 99"
                      />
                      <mat-error
                        >{{ getIdentityNoLabel() | translate }}
                        {{
                          custFormGroup.controls.identityNo.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>

                    <ng-container *ngIf="!isCompany">
                      <!-- Details for malaysian only -->
                      <mat-form-field appearance="outline" *ngIf="isMalaysian">
                        <mat-label>{{
                          "common.policeOrArmyIcNo" | translate
                        }}</mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          formControlName="policIdentityNo"
                        />
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>{{ "common.dob" | translate }}</mat-label>
                        <input
                          matInput
                          [matDatepicker]="picker"
                          formControlName="dob"
                          readonly
                          (click)="picker.open()"
                          [max]="maxDob"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error
                          >{{ "common.dob" | translate }}
                          {{
                            custFormGroup.controls.dob.errors
                              | inputError
                              | translate
                          }}</mat-error
                        >
                      </mat-form-field>

                      <mat-form-field appearance="outline" *ngIf="isMalaysian">
                        <mat-label
                          >{{ "common.gender" | translate }}
                        </mat-label>
                        <mat-select formControlName="gender">
                          <mat-option value="M">{{
                            "common.M" | translate
                          }}</mat-option>
                          <mat-option value="F">{{
                            "common.F" | translate
                          }}</mat-option>
                        </mat-select>
                        <mat-error
                          >{{ "common.gender" | translate }}
                          {{
                            custFormGroup.controls.gender.errors
                              | inputError
                              | translate
                          }}</mat-error
                        >
                      </mat-form-field>

                      <section class="box-selection" *ngIf="!isMalaysian">
                        <mat-label>{{ "common.gender" | translate }}</mat-label>
                        <mat-radio-group
                          class="b2c-radio"
                          aria-label="Select an option"
                          formControlName="gender"
                        >
                          <mat-radio-button value="M"
                            >{{ "common.M" | translate }}
                          </mat-radio-button>
                          <mat-radio-button value="F"
                            >{{ "common.F" | translate }}
                          </mat-radio-button>
                        </mat-radio-group>
                        <mat-error
                          >{{ "common.gender" | translate }}
                          {{
                            custFormGroup.controls.gender.errors
                              | inputError
                              | translate
                          }}</mat-error
                        >
                      </section>
                    </ng-container>

                    <mat-form-field appearance="outline">
                      <mat-label>{{ getNameLabel() | translate }}</mat-label>
                      <input
                        required
                        matInput
                        autocomplete="name"
                        formControlName="name"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                      <ngx-mat-intl-tel-input
                        [enableSearch]="true"
                        [preferredCountries]="['my']"
                        formControlName="mobileNo"
                      >
                      </ngx-mat-intl-tel-input>
                      <mat-error
                        >{{ "common.mobileNo" | translate }}
                        {{
                          custFormGroup.controls.mobileNo.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>{{ "common.email" | translate }}</mat-label>
                      <input
                        matInput
                        formControlName="email"
                        autocomplete="email"
                      />
                      <mat-error
                        style="display: block !important"
                        [hidden]="!custFormGroup.controls.email?.errors?.email"
                      >
                        {{ "error.invalidEmail" | translate }}</mat-error
                      >
                      <mat-error
                        >{{ "common.email" | translate }}
                        {{
                          custFormGroup.controls.email.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>
                  </div>

                  <section class="box-selection" *ngIf="!isCompany">
                    <mat-label
                      >{{ "common.maritalStatus" | translate }}:</mat-label
                    >
                    <mat-radio-group
                      class="b2c-radio"
                      aria-label="Select an option"
                      formControlName="maritalStatus"
                    >
                      <mat-radio-button value="Single"
                        >{{ "common.Single" | translate }}
                      </mat-radio-button>
                      <mat-radio-button value="Married"
                        >{{ "common.Married" | translate }}
                      </mat-radio-button>
                    </mat-radio-group>
                  </section>

                  <div
                    *ngIf="[4, 5].includes(custFormGroup.get('idType').value)"
                    class="upload-car-grant"
                  >
                    <div>
                      <input
                        id="carGrant"
                        name="carGrant"
                        type="file"
                        [accept]="acceptFile"
                        (change)="handleFileInput($event)"
                        style="display: none"
                      />

                      <div class="mdDesc-f bold">
                        {{ "dashboard.uploadCarGrant" | translate }}
                      </div>

                      <button mat-stroked-button type="button">
                        <label for="carGrant" class="upload-button">
                          <mat-icon color="primary">add_circle</mat-icon
                          >{{ "common.upload" | translate }}
                        </label>
                      </button>
                    </div>

                    <div *ngIf="carGrant">
                      <div>
                        <span class="underlined-blue">
                          {{ carGrant.name }}
                        </span>
                        <button mat-icon-button (click)="deleteFile()">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </div>
                      <div class="uploaded-status">
                        <img src="/assets/image/tick-icon.png" />
                        <div class="smTitle-f color-dark-green">
                          {{ "common.completed" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <section class="mt-sm">
                    <mat-checkbox
                      [checked]="pdpaAgreement"
                      (change)="pdpaAgreement = !pdpaAgreement"
                      class="checkbox-align-top dashboardCheckbox"
                    >
                      <span class="color-info">
                        {{ "common.pdpa" | translate }}
                      </span>

                      <a
                        [href]="'common.pdpaHref' | translate"
                        target="blank"
                        style="text-decoration: none; color: #000"
                        [ngClass]="pdpaAgreement ? 'secondary-color' : ''"
                      >
                        <span>
                          {{ "common.pdpaLink" | translate }}
                        </span>
                      </a>
                    </mat-checkbox>
                  </section>

                  <button
                    mat-flat-button
                    [disabled]="
                      !vehicleFormGroup.valid ||
                      !custFormGroup.valid ||
                      ([4, 5].includes(custFormGroup.get('idType').value) &&
                        !carGrant) ||
                      !pdpaAgreement
                    "
                  >
                    {{ "getAFreeQuote" | translate }}
                  </button>
                </form>
              </mat-step>
            </mat-stepper>
            <div
              *ngIf="stepper.selectedIndex === 1"
              class="accept-payment-graphic"
            >
              <span>{{ "dashboard.weAccept" | translate }} :</span>
              <img src="assets/image/accpeted-payment.png" alt="" />
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #banner>
    <div class="banner-container">
      <div class="forms-container">
        <div class="forms">
          <div class="title">
            <span *ngIf="stepper.selectedIndex === 0">
              {{ "dashboard.title" | translate }}
            </span>
            <span *ngIf="stepper.selectedIndex === 1">
              {{ "dashboard.title2" | translate }}
            </span>
          </div>

          <mat-stepper #stepper linear>
            <!-- Vehicle Form -->
            <mat-step [stepControl]="vehicleFormGroup">
              <form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
                <ng-template matStepLabel>
                  <span>{{ "dashboard.stepOne" | translate }}</span>
                </ng-template>

                <div class="form-wrapper">
                  <mat-form-field
                    [class.ktmb-disabled]="partnerCode === 'ktmb'"
                    appearance="outline"
                  >
                    <mat-label>{{
                      "common.typeOfInsurance" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      formControlName="insuranceType"
                      required
                    >
                      <mat-option
                        *ngFor="let item of typeOfInsuranceList"
                        [value]="item.id"
                      >
                        {{ item.name | translate }}
                      </mat-option>
                    </mat-select>

                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="typeTooltip"
                      #typeTrigger="matMenuTrigger"
                      (mouseenter)="typeTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #typeTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover dashboard"
                    >
                      <span (mouseleave)="typeTrigger.closeMenu()">
                        <div class="title">
                          {{ "common.COM" | translate }}
                        </div>
                        {{ "common.comprehensiveInsDesc" | translate }}
                        <ng-container *ngIf="partnerCode !== 'ktmb'">
                          <br />
                          <br />
                          <div class="title">
                            {{ "common.TPFT" | translate }}
                          </div>
                          {{ "common.fireTheftInsDesc" | translate }}
                        </ng-container>
                      </span>
                    </mat-menu>
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="upperc-input">
                    <mat-label>{{ "common.carPlateNo" | translate }}</mat-label>
                    <input
                      matInput
                      required
                      formControlName="carPlate"
                      mask="A{255}"
                      [validation]="false"
                      autocomplete="off"
                    />
                    <mat-error
                      >{{ "common.carPlateNo" | translate }}
                    </mat-error>
                  </mat-form-field>

                  <div class="mileage-plan" *ngIf="showMileagePlans">
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        "common.mileagePlans" | translate
                      }}</mat-label>
                      <mat-select
                        disableOptionCentering
                        formControlName="mileagePlan"
                        required
                      >
                        <mat-option
                          *ngFor="let plan of mileagePlans"
                          [value]="plan.id"
                        >
                          {{ plan.description }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        >{{ "common.typeOfInsurance" | translate }}
                        {{
                          vehicleFormGroup.controls.mileagePlan.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>
                    <span class="mileage-plan__note">{{
                      "common.mileagePlansNote" | translate
                    }}</span>
                  </div>

                  <mat-form-field appearance="outline">
                    <mat-label>{{
                      "common.residentialPostcode" | translate
                    }}</mat-label>
                    <input
                      matInput
                      required
                      formControlName="postcode"
                      mask="00000"
                      autocomplete="postal-code"
                    />
                    <mat-error
                      style="display: block !important"
                      [hidden]="
                        !vehicleFormGroup.controls.postcode?.errors?.customError
                      "
                    >
                      {{ "error.invalidPostcode" | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <section
                  *ngIf="
                    vehicleFormGroup.get('insuranceType').value !== 'TPFT' &&
                    showEHailingOption
                  "
                  class="mt-xs"
                >
                  <mat-label
                    >{{ "common.carUsedForEHailing" | translate }}.
                  </mat-label>
                  <mat-radio-group
                    class="b2c-radio ehailing-radio"
                    formControlName="eHailingUsed"
                  >
                    <mat-radio-button [value]="true"
                      >{{ "common.yes" | translate }}
                    </mat-radio-button>
                    <mat-radio-button [value]="false"
                      >{{ "common.no" | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>

                <section
                  class="box-selection"
                  *ngIf="showAgreeOrMarketValueBtn"
                >
                  <span class="label">
                    <mat-label>{{
                      "common.insuredType" | translate
                    }}</mat-label>
                  </span>
                  <mat-radio-group
                    class="b2c-radio"
                    aria-label="Select an option"
                    formControlName="insuredType"
                  >
                    <mat-radio-button value="MV"
                      >{{ "common.MV" | translate }}
                    </mat-radio-button>

                    <mat-radio-button
                      value="AV"
                      *ngIf="
                        vehicleFormGroup.get('insuranceType').value !== 'TPFT'
                      "
                      >{{ "common.AV" | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>

                <button
                  class="nextBtn"
                  mat-flat-button
                  matStepperNext
                  [disabled]="vehicleFormGroup.invalid"
                >
                  {{ "common.next" | translate }}
                </button>
              </form>
            </mat-step>

            <!-- Customer Form -->
            <mat-step [stepControl]="custFormGroup">
              <form
                [formGroup]="custFormGroup"
                #custForm="ngForm"
                (ngSubmit)="submit()"
              >
                <ng-template matStepLabel>
                  <span>{{ "dashboard.stepTwo" | translate }}</span>
                </ng-template>

                <div class="form-wrapper">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "common.idType" | translate }} </mat-label>
                    <mat-select formControlName="idType">
                      <mat-option
                        *ngFor="let item of idTypeList"
                        [value]="item.id"
                      >
                        {{ item.description | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <!-- Detais for foreigner only -->
                  <mat-form-field appearance="outline" *ngIf="!isMalaysian">
                    <mat-label>{{
                      "common.nationality" | translate
                    }}</mat-label>
                    <mat-select formControlName="nationality">
                      <mat-option>
                        <ngx-mat-select-search
                          [placeholderLabel]="
                            'common.nationalityPH' | translate
                          "
                          [ngModelOptions]="{ standalone: true }"
                          [searching]="!countryList.length"
                          noEntriesFoundLabel="{{
                            'common.nationalityXrecord' | translate
                          }}"
                          (ngModelChange)="filterCountry($event)"
                          ngModel
                          ngDefaultControl
                        >
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let item of filterCountryList"
                        [value]="item"
                      >
                        {{ item }}</mat-option
                      >
                    </mat-select>
                    <mat-error
                      >{{ "common.nationality" | translate }}
                      {{
                        custFormGroup.controls.nationality.errors
                          | inputError
                          | translate
                      }}</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>
                      {{ getIdentityNoLabel() | translate }}
                    </mat-label>
                    <input
                      matInput
                      required
                      formControlName="identityNo"
                      [mask]="getIdentityNoMasking()"
                      autocomplete="off"
                      [maxLength]="isMalaysian ? 12 : 99"
                    />
                    <mat-error
                      >{{ getIdentityNoLabel() | translate }}
                      {{
                        custFormGroup.controls.identityNo.errors
                          | inputError
                          | translate
                      }}</mat-error
                    >
                  </mat-form-field>

                  <ng-container *ngIf="!isCompany">
                    <!-- Details for malaysian only -->
                    <mat-form-field appearance="outline" *ngIf="isMalaysian">
                      <mat-label>{{
                        "common.policeOrArmyIcNo" | translate
                      }}</mat-label>
                      <input
                        matInput
                        autocomplete="off"
                        formControlName="policIdentityNo"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>{{ "common.dob" | translate }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="dob"
                        readonly
                        (click)="picker.open()"
                        [max]="maxDob"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error
                        >{{ "common.dob" | translate }}
                        {{
                          custFormGroup.controls.dob.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>

                    <mat-form-field appearance="outline" *ngIf="isMalaysian">
                      <mat-label>{{ "common.gender" | translate }} </mat-label>
                      <mat-select formControlName="gender">
                        <mat-option value="M">{{
                          "common.M" | translate
                        }}</mat-option>
                        <mat-option value="F">{{
                          "common.F" | translate
                        }}</mat-option>
                      </mat-select>
                      <mat-error
                        >{{ "common.gender" | translate }}
                        {{
                          custFormGroup.controls.gender.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </mat-form-field>

                    <div *ngIf="!isMalaysian">
                      <mat-label>{{ "common.gender" | translate }}</mat-label>
                      <mat-radio-group
                        class="b2c-radio"
                        aria-label="Select an option"
                        formControlName="gender"
                      >
                        <mat-radio-button value="M"
                          >{{ "common.M" | translate }}
                        </mat-radio-button>
                        <mat-radio-button value="F"
                          >{{ "common.F" | translate }}
                        </mat-radio-button>
                      </mat-radio-group>
                      <mat-error
                        >{{ "common.gender" | translate }}
                        {{
                          custFormGroup.controls.gender.errors
                            | inputError
                            | translate
                        }}</mat-error
                      >
                    </div>
                  </ng-container>

                  <mat-form-field appearance="outline">
                    <mat-label>{{ getNameLabel() | translate }}</mat-label>
                    <input
                      required
                      matInput
                      autocomplete="name"
                      formControlName="name"
                    />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                    <ngx-mat-intl-tel-input
                      [enableSearch]="true"
                      [preferredCountries]="['my']"
                      formControlName="mobileNo"
                    >
                    </ngx-mat-intl-tel-input>
                    <mat-error
                      >{{ "common.mobileNo" | translate }}
                      {{
                        custFormGroup.controls.mobileNo.errors
                          | inputError
                          | translate
                      }}</mat-error
                    >
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>{{ "common.email" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="email"
                      autocomplete="email"
                    />
                    <mat-error
                      style="display: block !important"
                      [hidden]="!custFormGroup.controls.email?.errors?.email"
                    >
                      {{ "error.invalidEmail" | translate }}</mat-error
                    >
                    <mat-error
                      >{{ "common.email" | translate }}
                      {{
                        custFormGroup.controls.email.errors
                          | inputError
                          | translate
                      }}</mat-error
                    >
                  </mat-form-field>
                </div>

                <section class="box-selection" *ngIf="!isCompany">
                  <mat-label
                    >{{ "common.maritalStatus" | translate }}:</mat-label
                  >
                  <mat-radio-group
                    class="b2c-radio"
                    aria-label="Select an option"
                    formControlName="maritalStatus"
                  >
                    <mat-radio-button value="Single"
                      >{{ "common.Single" | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="Married"
                      >{{ "common.Married" | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                </section>

                <div
                  *ngIf="[4, 5].includes(custFormGroup.get('idType').value)"
                  class="upload-car-grant"
                >
                  <div>
                    <input
                      id="carGrant"
                      name="carGrant"
                      type="file"
                      [accept]="acceptFile"
                      (change)="handleFileInput($event)"
                      style="display: none"
                    />

                    <div class="mdDesc-f bold">
                      {{ "dashboard.uploadCarGrant" | translate }}
                    </div>

                    <button mat-stroked-button type="button">
                      <label for="carGrant" class="upload-button">
                        <mat-icon color="primary">add_circle</mat-icon
                        >{{ "common.upload" | translate }}
                      </label>
                    </button>
                  </div>

                  <div *ngIf="carGrant">
                    <div>
                      <span class="underlined-blue">
                        {{ carGrant.name }}
                      </span>
                      <button mat-icon-button (click)="deleteFile()">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                    <div class="uploaded-status">
                      <img src="/assets/image/tick-icon.png" />
                      <div class="smTitle-f color-dark-green">
                        {{ "common.completed" | translate }}
                      </div>
                    </div>
                  </div>
                </div>

                <section class="mt-sm">
                  <mat-checkbox
                    [checked]="pdpaAgreement"
                    (change)="pdpaAgreement = !pdpaAgreement"
                    class="checkbox-align-top dashboardCheckbox"
                  >
                    <span class="color-info">
                      {{ "common.pdpa" | translate }}
                    </span>

                    <a
                      [href]="'common.pdpaHref' | translate"
                      target="blank"
                      style="text-decoration: none; color: #000"
                      [ngClass]="pdpaAgreement ? 'secondary-color' : ''"
                    >
                      <span>
                        {{ "common.pdpaLink" | translate }}
                      </span>
                    </a>
                  </mat-checkbox>
                </section>

                <button
                  mat-flat-button
                  [disabled]="
                    vehicleFormGroup.invalid ||
                    custFormGroup.invalid ||
                    ([4, 5].includes(custFormGroup.get('idType').value) &&
                      !carGrant) ||
                    !pdpaAgreement
                  "
                >
                  {{ "getAFreeQuote" | translate }}
                </button>
              </form>
            </mat-step>
          </mat-stepper>

          <div
            *ngIf="stepper.selectedIndex === 1"
            class="accept-payment-graphic"
          >
            <span>{{ "dashboard.weAccept" | translate }} :</span>
            <img src="assets/image/accpeted-payment.png" alt="" />
          </div>
        </div>
      </div>

      <div class="swiper-container">
        <swiper #swiper [config]="swiperOptions">
          <ng-container *ngFor="let banner of banners">
            <ng-template
              *ngIf="translate.currentLang === banner.lang && banner.show"
              swiperSlide
            >
              <img
                (load)="countLoadedBanners()"
                (error)="banner.show = false"
                [src]="banner.src"
                [alt]="banner.alt"
              />
            </ng-template>
          </ng-container>
        </swiper>
      </div>
    </div>
  </ng-template>

  <!-- Fixed whatsapp button in mobile view -->
  <img
    (click)="whatsppRedirect()"
    class="whatsapp-button"
    src="assets/image/whatsapp-icon.png"
    alt=""
    *ngIf="showWhatsappBtn"
  />
</ng-template>

<div class="comparison-form" *ngIf="quotationList.length">
  <mat-stepper
    linear
    labelPosition="bottom"
    #stepper
    (selectionChange)="onStepChange($event)"
    [ngClass]="hideStepperLabel"
  >
    <mat-step [stepControl]="quotationFrmGroup" state="edit">
      <!-- Normal affiliate flow -->
      <ng-container *ngIf="!isInsurancePlaceholderEnabled">
        <form [formGroup]="quotationFrmGroup">
          <ng-template matStepLabel>
            {{ "comparison.step1" | translate }}
          </ng-template>

          <!-- <div fxLayoutGap="8px grid" class="mobile-details-btn-list">
            <div>
              <button mat-button (click)="carDetails()">
                <img src="assets/image/car-icon.png" alt="" />
                {{ "common.carDetails" | translate }}
              </button>
            </div>
  
            <div>
              <button mat-button (click)="carPlan()">
                <img src="assets/image/shield-icon.png" alt="" />
                {{ "common.carProtectionPlan" | translate }}
              </button>
            </div>
  
            <div>
              <button mat-button (click)="editQuotation()">
                <img src="assets/image/edit.png" alt="" />
                {{ "common.quotation" | translate }}
              </button>
            </div>
          </div> -->
          <ng-container [ngSwitch]="partnerCode">
            <ng-container *ngSwitchCase="'ktmb'">
              <div class="ktmb-car-card">
                <app-ktmb-car-details
                  [carDetails]="requestData"
                  (editQuotation)="editQuotation()"
                ></app-ktmb-car-details>
              </div>

              <div class="ktmb-insurer-list">
                <app-ktmb-insurer-card
                  *ngFor="let item of quotationList; let first = first"
                  [quotation]="item"
                  [isFirst]="first"
                  (buyNow)="openCarVariantDialog(item.id)"
                ></app-ktmb-insurer-card>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <mat-card class="border-card">
                <div>
                  <div fxFlex.lt-md="100" fxFlex>
                    <div class="title-f">
                      <img src="assets/image/car-icon.png" alt="" />
                      {{ "common.carDetails" | translate }}
                    </div>
                    <div class="content-wrapper">
                      <div>
                        <div class="label-desc">
                          {{ "common.carNumber" | translate }} :
                        </div>
                        <div class="label-value">
                          {{ requestData.vehicleNo }}
                        </div>
                      </div>
                      <div>
                        <div class="label-desc">
                          {{ "common.carModel" | translate }} :
                        </div>
                        <div class="label-value">
                          {{ requestData.vehicle }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div fxFlex.lt-md="100" fxFlex>
                    <div class="title-f">
                      <img src="assets/image/shield-icon.png" alt="" />
                      {{ "common.carProtectionPlan" | translate }}
                    </div>
                    <div class="content-wrapper">
                      <div>
                        <div class="label-desc">
                          {{ "common.typeOfInsurance" | translate }}
                          :
                        </div>
                        <div class="label-value">
                          {{
                            "common." + requestData.insuranceType | translate
                          }}
                        </div>
                      </div>
                      <div>
                        <div class="label-desc">
                          {{ "common.effectivePeriod" | translate }} :
                        </div>
                        <div class="label-value">
                          {{ requestData.effectiveDate | date : "dd MMM yyyy" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  mat-flat-button
                  class="full-btn"
                  (click)="editQuotation()"
                >
                  {{ "common.editQuotation" | translate }}
                </button>
              </mat-card>

              <div
                class="insurer-list"
                *ngFor="let item of quotationList; let first = first"
                [ngClass]="
                  partnerCode !== 'ps' && first
                    ? 'partner-insurer-list cheapest'
                    : partnerCode !== 'ps'
                    ? 'partner-insurer-list'
                    : first
                    ? 'cheapest'
                    : 'insurer-list'
                "
              >
                <div style="position: relative">
                  <!-- For grab ehailing-->
                  <img
                    *ngIf="first && isGrabSingleQuotation"
                    src="assets/image/cheapest-banner-long.png"
                    class="banner long"
                  />
                  <img
                    *ngIf="first && !isGrabSingleQuotation"
                    class="banner"
                    src="assets/image/cheapest-banner-2.png"
                    alt=""
                  />
                  <div class="banner-text">
                    <span *ngIf="first && !isGrabSingleQuotation">
                      {{ "common.cheapest" | translate }} <br />
                      <span
                        style="font-weight: 400; font-size: 10px; color: #fff"
                      >
                        {{ "common.inMalaysia" | translate }}
                      </span>
                    </span>
                    <!-- For grab ehailing-->
                    <span *ngIf="first && isGrabSingleQuotation">
                      RECOMMENDED <br />
                      <span
                        style="font-weight: 400; font-size: 10px; color: #fff"
                      >
                        for e-Hailing
                      </span>
                    </span>
                  </div>
                </div>

                <div class="insurer-content">
                  <div class="insurer-logo">
                    <img [src]="item.image" />
                  </div>
                  <div class="insurer-details">
                    <div fxLayout="row">
                      <div fxFlex>
                        <div class="label">
                          {{ "common.sumInsured" | translate }} ({{
                            "common." + item?.typeOfSum | translate
                          }}):
                        </div>
                        <div class="value">
                          RM {{ item.sumInsured | number : "1.2-2" }}
                        </div>
                      </div>
                      <button
                        mat-stroked-button
                        class="benefit-btn"
                        (click)="benefitDialog(item)"
                      >
                        {{ "comparison.viewBenefit" | translate }}
                      </button>
                    </div>

                    <div fxLayout="row" class="mt-md">
                      <app-insurer-details
                        [code]="item.code"
                        [first]="first"
                        [insuranceType]="requestData?.insuranceType"
                        class="full-width"
                        *ngIf="
                          requestData?.insuranceType !== 'TPFT' ||
                          item.code === 'Zurich'
                        "
                      >
                      </app-insurer-details>
                    </div>
                  </div>
                  <div class="insurer-select">
                    <div class="insurer-amount">
                      <span>RM</span>{{ item.total | number : "1.2-2" }}
                    </div>
                    <!-- ## SPLIT TAG -->
                    <!-- <div class="insurer-instalment">
                      {{ "common.pay" | translate }}
                      <b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
                      {{ "common.everyMonth" | translate }} <br />{{
                        "common.duration" | translate
                      }}!
                    </div> -->
                    <!-- ## END SPLIT TAG -->
                    <!-- <button
                      mat-flat-button
                      class="buy-btn"
                      (click)="selectQuotation(item.id)"
                    >
                      {{ "comparison.buyNow" | translate }}
                    </button> -->
                    <button
                      mat-flat-button
                      class="buy-btn"
                      (click)="openCarVariantDialog(item.id)"
                    >
                      {{ "comparison.buyNow" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="comparison-graphic" *ngIf="isGrabSingleQuotation">
            <img
              src="assets/image/grab/grab-graphic-desktop.png"
              alt=""
              class="mt-md"
              style="width: 100%"
            />
          </div>
          <ng-container *ngFor="let x of affiliateBannerData">
            <div class="comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-desktop-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
                style="width: 100%"
              />
            </div>
          </ng-container>

          <!-- ## D2C Exclusive (Insurer card design) -->
          <ng-container *ngIf="partnerCode === 'ps'">
            <div
              class="mobile-insurer-list-2"
              [ngClass]="first ? 'cheapest' : ''"
              *ngFor="let item of quotationList; let first = first"
            >
              <img
                *ngIf="first"
                class="banner"
                src="assets/image/cheapest-banner.png"
                alt=""
              />
              <div class="info-section">
                <div fxLayout="row">
                  <div class="insurer-logo">
                    <img [src]="item.image" />
                  </div>
                  <div style="padding: 10px 5px">
                    <div style="font-size: 11px; align-items: center">
                      {{ "common.sumInsured" | translate }} ({{
                        item?.typeOfSum
                      }}):
                    </div>
                    <div class="bold">
                      RM {{ item.sumInsured | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    mat-stroked-button
                    class="view-benefits-btn"
                    (click)="benefitDialog(item)"
                  >
                    {{ "comparison.viewBenefit" | translate }}
                  </button>
                </div>
              </div>
              <mat-divider></mat-divider>
              <div class="insurer-details">
                <app-insurer-details
                  [code]="item.code"
                  [first]="first"
                  [insuranceType]="requestData?.insuranceType"
                  fxFlex
                  *ngIf="
                    requestData?.insuranceType !== 'TPFT' ||
                    item.code === 'Zurich'
                  "
                ></app-insurer-details>
              </div>
              <div class="price-section" [ngClass]="first ? 'cheapest' : ''">
                <div fxLayout="row">
                  <div style="flex: 1.5">
                    <div class="price">
                      <span style="font-size: 16px">RM</span>
                      {{ item.total | number : "1.2-2" }}
                    </div>
                    <!-- ## SPLIT TAG -->
                    <!-- <div class="split-price">
                      {{ "common.pay" | translate }}
                      <b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
                      <br />{{ "common.everyMonth" | translate }}
                      {{ "common.duration" | translate }}!
                    </div> -->
                    <!-- ## END SPLIT TAG -->
                  </div>
                  <div style="flex: 1; padding-top: 35px">
                    <div></div>
                    <!-- <button
                      mat-flat-button
                      class="buy-btn"
                      (click)="selectQuotation(item.id)"
                    >
                      {{ "comparison.buyNow" | translate }}
                    </button> -->
                    <button
                      mat-flat-button
                      class="buy-btn"
                      (click)="openCarVariantDialog(item.id)"
                    >
                      {{ "comparison.buyNow" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- ## D2C Exclusive (Insurer card design) -->

          <!-- ## Affiliate Exclusive (Insurer card design) -->
          <ng-container *ngIf="partnerCode !== 'ps'">
            <ng-container [ngSwitch]="partnerCode">
              <ng-container *ngSwitchCase="'ktmb'"></ng-container>
              <ng-container *ngSwitchDefault>
                <div
                  class="mobile-insurer-list"
                  [ngClass]="first ? 'cheapest' : ''"
                  *ngFor="let item of quotationList; let first = first"
                >
                  <div style="position: relative">
                    <ng-container *ngIf="first && !isGrabSingleQuotation">
                      <img
                        class="banner"
                        src="assets/image/cheapest-banner-2.png"
                        alt=""
                      />
                      <div class="banner-text">
                        <span *ngIf="first">
                          CHEAPEST <br />
                          <span
                            style="
                              font-weight: 400;
                              font-size: 10px;
                              color: #fff;
                            "
                          >
                            IN MALAYSIA
                          </span>
                        </span>
                      </div>
                    </ng-container>
                    <img
                      *ngIf="first && isGrabSingleQuotation"
                      class="banner no-filter"
                      src="assets/image/ehailing-banner.png"
                      alt=""
                    />
                  </div>
                  <div class="insurer-content">
                    <div fxLayout="row" fxLayoutAlign=" center">
                      <div class="insurer-logo">
                        <img [src]="item.image" />
                      </div>

                      <div class="insurer-select">
                        <div class="insurer-amount">
                          <span>RM</span>{{ item.total | number : "1.2-2" }}
                        </div>
                        <!-- ## SPLIT TAG -->
                        <!-- <div class="insurer-instalment">
                          {{ "common.pay" | translate }}
                          <b>RM{{ item.total / 3 | number : "1.2-2" }}</b>
                          {{ "common.everyMonth" | translate }}
                          <br />{{ "common.duration" | translate }}!
                        </div> -->
                        <!-- ## END SPLIT TAG -->
                      </div>
                    </div>

                    <div class="insurer-value">
                      <div fxFlex="50">
                        <div class="label">
                          {{ "common.sumInsured" | translate }} ({{
                            item?.typeOfSum
                          }}):
                        </div>
                        <div class="value">
                          RM {{ item.sumInsured | number : "1.2-2" }}
                        </div>
                      </div>
                      <div fxFlex="50">
                        <!-- <button
                          mat-flat-button
                          class="buy-btn"
                          (click)="selectQuotation(item.id)"
                        >
                          {{ "comparison.buyNow" | translate }}
                        </button> -->
                        <button
                          mat-flat-button
                          class="buy-btn"
                          (click)="openCarVariantDialog(item.id)"
                        >
                          {{ "comparison.buyNow" | translate }}
                        </button>
                      </div>
                    </div>

                    <div class="insurer-details">
                      <div fxLayout="row" fxLayoutAlign=" center">
                        <app-insurer-details
                          [code]="item.code"
                          [first]="first"
                          [insuranceType]="requestData?.insuranceType"
                          fxFlex
                          *ngIf="
                            requestData?.insuranceType !== 'TPFT' ||
                            item.code === 'Zurich'
                          "
                        ></app-insurer-details>
                        <div>
                          <button
                            mat-stroked-button
                            class="benefit-btn"
                            (click)="benefitDialog(item)"
                          >
                            {{ "comparison.viewBenefit" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- ## End Affiliate Exclusive (Insurer card design) -->

          <div class="mobile-comparison-graphic" *ngIf="isGrabSingleQuotation">
            <img
              src="assets/image/grab/grab-graphic-mobile.png"
              alt=""
              class="mt-md"
            />
          </div>
          <ng-container *ngFor="let x of affiliateBannerData">
            <div class="mobile-comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-mobile-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
              />
            </div>
          </ng-container>
        </form>
      </ng-container>
      <!-- Insurance placeholder flow -->
      <ng-container *ngIf="isInsurancePlaceholderEnabled">
        <form [formGroup]="quotationFrmGroup">
          <ng-template matStepLabel>
            {{ "comparison.step1" | translate }}
          </ng-template>

          <ng-container>
            <div
              *ngFor="let x of affiliateBannerData"
              class="cta-banner constraint"
            >
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-desktop-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
                style="width: 100%"
                *ngIf="partnerCode == x"
              />
            </div>
          </ng-container>
          <mat-card class="border-card-ip">
            <div class="border-card-ip__flex">
              <div class="border-card-ip__container">
                <div class="border-card-ip__main-title">
                  <img
                    src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/car-icon.png"
                    alt=""
                  />
                  {{ "common.carDetails" | translate }}
                </div>
                <div class="border-card-ip__content-wrapper">
                  <div class="border-card-ip__label-container">
                    <div class="label-desc">
                      {{ "common.carNumber" | translate }}
                    </div>
                    <div class="label-value">
                      {{ requestData?.vehicleNo }}
                    </div>
                  </div>
                  <div class="border-card-ip__label-container">
                    <div class="label-desc">
                      {{ "common.carModel" | translate }}
                    </div>
                    <div class="label-value">
                      {{ requestData?.vehicle }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="border-card-ip__container">
                <div class="border-card-ip__main-title">
                  <img
                    src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/icons/shield-icon.png"
                    alt=""
                  />
                  {{ "common.carProtectionPlan" | translate }}
                </div>
                <div class="border-card-ip__content-wrapper">
                  <div class="border-card-ip__label-container">
                    <div class="label-desc">
                      {{ "common.typeOfInsurance" | translate }}
                    </div>
                    <div class="label-value">
                      {{ "common." + requestData?.insuranceType | translate }}
                    </div>
                  </div>
                  <div class="border-card-ip__label-container">
                    <div class="label-desc">
                      {{ "common.effectiveDate" | translate }}
                    </div>
                    <div class="label-value">
                      {{ requestData?.effectiveDate | date : "dd MMM yyyy" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              mat-stroked-button
              (click)="editQuotation()"
              class="stroked-btn"
              [matTooltip]="'common.editQuotationDisabledTooltip' | translate"
              [matTooltipDisabled]="!disableEditQuotationBtn"
              [disabled]="disableEditQuotationBtn"
            >
              {{ "common.editQuotation" | translate }}
            </button>
          </mat-card>
          <div class="comparison-section-title">
            <app-section-title
              title="comparison.quotationAvalTxt"
              desc="comparison.quotationAvalDesc"
            >
            </app-section-title>
          </div>
          <div *ngFor="let item of quotationList; let first = first">
            <!--Other insurers card -->
            <app-insurer-card-v2
              [first]="first"
              [item]="item"
              (onSubmit)="openCarVariantDialog(item?.id)"
              *ngIf="item?.status == 'Available'"
            >
            </app-insurer-card-v2>
          </div>
          <ng-container *ngFor="let item of sortedQuotationList">
            <app-loading-insurer-card
              [insurer]="item"
              *ngIf="item?.status == 'New' || item?.status == 'Rejected'"
            ></app-loading-insurer-card>
          </ng-container>
          <div class="other-insurer-section" *ngIf="otherInsurerList.length">
            <app-section-title
              title="plans.step1Content.exploreOther"
              desc="plans.step1Content.exploreOtherDesc"
            ></app-section-title>
            <div class="other-insurer-section__cards">
              <app-other-insurer-card
                (clickEvent)="updateSelectedInsurers($event)"
                *ngFor="let x of otherInsurerList"
                [insurer]="x"
              ></app-other-insurer-card>
            </div>
            <button
              mat-stroked-button
              class="stroked-btn mv-lg"
              (click)="submitOtherInsurer()"
              *ngIf="otherInsurerList.length"
            >
              {{ "comparison.getQuoteNow" | translate }}
            </button>
          </div>

          <!-- <ng-container *ngFor="let x of affiliateBannerData">
            <div class="comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-desktop-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
                style="width: 100%"
              />
            </div>
          </ng-container> -->
          <!-- ## End Affiliate Exclusive (Insurer card design) -->
          <!-- <ng-container *ngFor="let x of affiliateBannerData">
            <div class="mobile-comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-mobile-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
              />
            </div>
          </ng-container> -->
        </form>
      </ng-container>
    </mat-step>

    <mat-step
      [state]="addOnFrmGroup.valid ? 'edit' : 'close'"
      [stepControl]="addOnFrmGroup"
    >
      <ng-template matStepLabel>
        {{ "comparison.step2" | translate }}
      </ng-template>

      <button mat-button matStepperPrevious class="step-back-btn">
        <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
      </button>

      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(100% - 427px)">
          <!-- ## D2C Exclusive (Protection plan card) -->
          <ng-container *ngIf="partnerCode === 'ps'">
            <div class="ps-protection-plan">
              <div class="title-container pb-sm">
                <div class="title-f">
                  {{ "customerPolicy.protectionPlan" | translate }}
                </div>
                <div>
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="editQuotation()"
                  >
                    {{ "common.editQuotation" | translate }}
                  </button>
                </div>
              </div>
              <mat-card style="padding: 15px 20px; margin: 0px auto">
                <div
                  fxLayout="row"
                  fxLayoutAlign="flex-start center"
                  class="info"
                >
                  <img [src]="selectedQuotation?.image" alt="" />
                  <div>
                    <div class="mdTitle-f bold">
                      {{ selectedQuotation?.name }}
                    </div>
                  </div>
                </div>
                <div class="pt-sm">
                  <div class="bold">{{ requestData?.vehicleNo }}</div>
                  {{ requestData?.vehicle }}
                </div>
              </mat-card>
            </div>
          </ng-container>
          <!-- ## End D2C Exclusive (Protection plan card) -->

          <!-- ## Affiliate Exclusive -->
          <div
            class="comparison-graphic mobile"
            *ngIf="partnerCode == 'carsome' && affiliateBannerData.length"
          >
            <img
              src="https://storage.googleapis.com/ps-asset/motor/partner/carsome/carsome-banner-small.png"
              alt=""
              class="mt-md mb-md"
              style="width: 100%"
            />
          </div>
          <ng-container *ngIf="partnerCode !== 'ps'">
            <div class="section-container">
              <div class="title-sec">
                <div class="title-f">{{ "common.carDetails" | translate }}</div>
                <div class="divider"></div>
              </div>
              <div class="content-wrapper">
                <div class="left">
                  <div class="label-desc">
                    {{ "common.carNumber" | translate }} :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">{{ requestData.vehicleNo }}</div>
                </div>
                <div class="left">
                  <div class="label-desc">
                    {{ "common.carModel" | translate }} :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">{{ requestData.vehicle }}</div>
                </div>
              </div>
            </div>

            <div class="section-container">
              <div class="title-sec">
                <div class="title-f">
                  {{ "common.carProtectionPlan" | translate }}
                </div>
                <div class="divider"></div>
              </div>

              <div class="content-wrapper">
                <div class="left">
                  <div class="label-desc">
                    {{ "common.typeOfInsurance" | translate }} :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">
                    {{ "common." + requestData.insuranceType | translate }}
                  </div>
                </div>
                <div class="left">
                  <div class="label-desc">
                    {{ "common.insuranceProvider" | translate }}
                    :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">{{ selectedQuotation?.name }}</div>
                </div>
                <div class="left">
                  <div class="label-desc">
                    {{ "common.sumInsured" | translate }} ({{
                      "common." + selectedQuotation?.typeOfSum | translate
                    }}) :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">
                    RM {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
                  </div>
                </div>
                <div class="left">
                  <div class="label-desc">
                    {{ "common.effectivePeriod" | translate }} :
                  </div>
                </div>
                <div class="right">
                  <div class="label-value">
                    {{ requestData.effectiveDate | date : "dd MMM yyyy" }}
                  </div>
                </div>
              </div>
            </div>

            <button mat-flat-button class="full-btn" (click)="editQuotation()">
              {{ "common.editQuotation" | translate }}
            </button>
          </ng-container>
          <!-- ## End Affiliate Exclusive -->

          <ng-container *ngIf="isGrabSingleQuotation">
            <div class="comparison-graphic">
              <img
                src="assets/image/grab/grab-graphic-desktop.png"
                alt=""
                class="mt-md"
                style="width: 100%"
              />
            </div>
            <div class="mobile-comparison-graphic">
              <img
                src="assets/image/grab/grab-graphic-mobile.png"
                alt=""
                class="mt-md"
              />
            </div>
          </ng-container>

          <ng-container *ngFor="let x of affiliateBannerData">
            <div class="comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-desktop-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
                style="width: 100%"
              />
            </div>
            <div class="mobile-comparison-graphic" *ngIf="partnerCode == x">
              <img
                src="assets/image/{{ x }}/{{ x }}-graphic-mobile-{{
                  translate.currentLang
                }}.png"
                alt=""
                class="mt-md"
              />
            </div>
          </ng-container>

          <div class="section-container mt-sm">
            <div class="title-sec">
              <div class="title-f">{{ "addOn.addOns" | translate }}</div>
              <div class="divider"></div>
              <div class="desc">{{ "addOn.addOnsDesc" | translate }}</div>
            </div>
          </div>

          <!-- include add on -->
          <ng-container *ngFor="let item of includeAddOnList">
            <mat-card class="addon-card included selected">
              <div class="included-banner">
                {{ "addOn.included" | translate }}
                <mat-icon>check_circle </mat-icon>
              </div>
              <div *ngIf="!requestData.isCompany">
                <div class="title">
                  {{ "addOn." + item.code | translate }}
                </div>
                <div class="desc">
                  {{ "addOn." + item.code + "Desc" | translate }}
                </div>
              </div>
              <div *ngIf="requestData.isCompany">
                <div fxLayout="row">
                  <div fxFlex class="title">
                    {{ "addOn." + item.code | translate }}
                  </div>
                  <div class="value selected">
                    <span>RM</span> {{ getPrice(item) | number : "1.2-2" }}
                  </div>
                  <mat-icon
                    class="tooltip-icon"
                    [matMenuTriggerFor]="companyDriverTooltip"
                    #companyDriverTrigger="matMenuTrigger"
                    (mouseenter)="companyDriverTrigger.openMenu()"
                  >
                    info</mat-icon
                  >
                  <mat-menu
                    #companyDriverTooltip="matMenu"
                    [overlapTrigger]="false"
                    class="tooltip-popover"
                  >
                    <span (mouseleave)="companyDriverTrigger.closeMenu()">
                      {{ "addOn.UnlimitedTooltip" | translate }}
                    </span>
                  </mat-menu>
                </div>
                <div class="desc">
                  <b>{{ "addOn.companyDriverCover" | translate }}</b>
                  {{ "addOn.companyDriverCover2" | translate }}
                  <b>RM {{ item.evaluate }}</b
                  >.
                </div>
              </div>
            </mat-card>
          </ng-container>

          <!-- ## D2C Exclusive Recommended add ons -->
          <ng-container *ngIf="partnerCode === 'ps'">
            <div
              fxLayout="row"
              fxLayoutAlign="space-between"
              class="recommended-add-ons"
            >
              <div class="mdTitle-f">
                {{ "addOn.recommendedAddOns" | translate }}
              </div>
              <mat-slide-toggle
                [checked]="true"
                [(ngModel)]="toggleRecommendedAddOnsState"
                (change)="toggleRecommendedAddOns($event.checked)"
              ></mat-slide-toggle>
            </div>
          </ng-container>
          <!-- ## D2C Exclusive End Recommended add ons -->

          <ng-container *ngTemplateOutlet="addOnFrom"> </ng-container>

          <!-- ## D2C Exclusive (Show more coverages button) -->
          <div
            *ngIf="partnerCode === 'ps' && toggleRecommendedAddOnsState"
            class="ps-show-more-coverage"
          >
            <button
              mat-flat-button
              class="color-primary bold"
              (click)="toggleRecommendedAddOns(false)"
            >
              <mat-icon>add</mat-icon>
              {{ "comparison.showMoreCoverages" | translate }}
            </button>
          </div>
          <!-- ## End D2C Exclusive (Show more coverages button) -->

          <div class="comparison-actions">
            <button mat-button matStepperPrevious class="back-btn">
              {{ "common.back" | translate }}
            </button>
            <button
              mat-button
              matStepperNext
              class="proceed-btn"
              [disabled]="addOnFrmGroup.invalid"
              (click)="recalculatePremium()"
            >
              {{ "common.continue" | translate }}
            </button>
          </div>
        </div>

        <div fxFlex.lt-md="100" fxFlex.gt-sm="427px">
          <div class="sidebar-container-affiliate">
            <ng-container *ngTemplateOutlet="insurerBenefits"></ng-container>
            <div class="sticky-order-summary">
              <app-expandable-card [title]="'common.orderSummary' | translate" *ngIf="!requirePremiumRecalculation">
                <ng-container snippet>
                  <ng-container
                    *ngTemplateOutlet="orderSummarySnippet"
                  ></ng-container>
                </ng-container>
                <ng-container hidden>
                  <ng-container *ngTemplateOutlet="priceList"> </ng-container>
                </ng-container>
                <ng-container share>
                  <app-order-summary-note
                    [text]="
                      'comparison.orderSummaryNote'
                        | translate
                          : {
                              commission:
                                getPSComissionPrice() | number : '1.2-2'
                            }
                    "
                    *ngIf="partnerCode != 'carsome'"
                  ></app-order-summary-note>
                  <ng-container *ngTemplateOutlet="promoTemplate">
                  </ng-container>
                  <app-order-summary-note
                    [text]="'comparison.orderSummaryNoteDisclaimer' | translate"
                    *ngIf="partnerCode != 'carsome'"
                  ></app-order-summary-note>
                </ng-container>
              </app-expandable-card>
            </div>
          </div>

          <app-mobile-bottombar
            [disabledCondition]="addOnFrmGroup.invalid"
            [buttonTemplate]="psMobileNextBtn"
            (onSubmit)="backToStep(2)"
          >
            <ng-container *ngTemplateOutlet="priceList"></ng-container>
            <app-order-summary-note
              [text]="
                'comparison.orderSummaryNote'
                  | translate
                    : { commission: getPSComissionPrice() | number : '1.2-2' }
              "
              *ngIf="partnerCode != 'carsome'"
            ></app-order-summary-note>
            <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
            <app-order-summary-note
              [text]="'comparison.orderSummaryNoteDisclaimer' | translate"
              *ngIf="partnerCode != 'carsome'"
            ></app-order-summary-note>
          </app-mobile-bottombar>
        </div>
      </div>
    </mat-step>

    <mat-step
      [state]="infoFrmGroup.valid ? 'edit' : 'close'"
      [stepControl]="infoFrmGroup"
    >
      <ng-template matStepLabel>
        {{ "comparison.step3" | translate }}
      </ng-template>
      <button mat-button matStepperPrevious class="step-back-btn">
        <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
      </button>

      <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(100% - 427px)">
          <ng-template *ngTemplateOutlet="personalInfoFrom"></ng-template>
          <div class="comparison-actions">
            <button mat-button matStepperPrevious class="back-btn">
              {{ "common.back" | translate }}
            </button>
            <button
              mat-button
              matStepperNext
              class="proceed-btn"
              [disabled]="infoFrmGroup.invalid"
            >
              {{ "common.continue" | translate }}
            </button>
          </div>
        </div>
        <div fxFlex.lt-md="100" fxFlex.gt-sm="427px">
          <div class="sidebar-container-affiliate">
            <ng-container *ngTemplateOutlet="insurerBenefits"></ng-container>
            <div class="sticky-order-summary">
              <app-expandable-card [title]="'common.orderSummary' | translate">
                <ng-container snippet>
                  <ng-container
                    *ngTemplateOutlet="orderSummarySnippet"
                  ></ng-container>
                </ng-container>
                <ng-container hidden>
                  <ng-container *ngTemplateOutlet="priceList"> </ng-container>
                </ng-container>
                <ng-container share>
                  <app-order-summary-note
                    [text]="
                      'comparison.orderSummaryNote'
                        | translate
                          : {
                              commission:
                                getPSComissionPrice() | number : '1.2-2'
                            }
                    "
                    *ngIf="partnerCode != 'carsome'"
                  ></app-order-summary-note>
                  <ng-container *ngTemplateOutlet="promoTemplate">
                  </ng-container>
                  <app-order-summary-note
                    [text]="'comparison.orderSummaryNoteDisclaimer' | translate"
                    *ngIf="partnerCode != 'carsome'"
                  ></app-order-summary-note>
                </ng-container>
              </app-expandable-card>
            </div>
          </div>

          <app-mobile-bottombar
            [disabledCondition]="infoFrmGroup.invalid"
            [buttonTemplate]="psMobileNextBtn"
            (onSubmit)="backToStep(3)"
          >
            <ng-container *ngTemplateOutlet="priceList"></ng-container>
            <app-order-summary-note
              [text]="
                'comparison.orderSummaryNote'
                  | translate
                    : { commission: getPSComissionPrice() | number : '1.2-2' }
              "
              *ngIf="partnerCode != 'carsome'"
            ></app-order-summary-note>
            <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
            <app-order-summary-note
              [text]="'comparison.orderSummaryNoteDisclaimer' | translate"
              *ngIf="partnerCode != 'carsome'"
            ></app-order-summary-note>
          </app-mobile-bottombar>
        </div>
      </div>
    </mat-step>

    <mat-step
      [state]="agreementFrmGroup.valid ? 'edit' : 'close'"
      [stepControl]="agreementFrmGroup"
    >
      <form [formGroup]="agreementFrmGroup">
        <ng-template matStepLabel>
          {{ "comparison.step4" | translate }}
        </ng-template>
        <button mat-button matStepperPrevious class="step-back-btn">
          <mat-icon>arrow_back</mat-icon> {{ "common.back" | translate }}
        </button>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <div fxFlex.lt-md="100" fxFlex.gt-sm="calc(100% - 427px)">
            <div class="section-container mb-lg">
              <div class="title-sec">
                <div class="title-f">
                  {{ "common.orderSummary" | translate }}
                </div>
                <div class="desc">
                  {{ "common.orderSummaryDesc" | translate }}
                </div>
              </div>
            </div>

            <!-- ## D2C Exclusive (Order summary display) -->
            <ng-container *ngIf="partnerCode === 'ps'">
              <div class="ps-protection-plan">
                <mat-card style="padding: 15px 20px; margin: 0px auto">
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "common.carProtectionPlan" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 2"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                    </div>
                  </div>

                  <div
                    fxLayout="row"
                    fxLayoutAlign="flex-start center"
                    class="info"
                  >
                    <img [src]="selectedQuotation?.image" alt="" />
                    <div>
                      <div class="mdTitle-f bold">
                        {{ selectedQuotation?.name }}
                      </div>
                    </div>
                  </div>
                  <div class="pt-sm">
                    <div class="car-plan-details">
                      <div class="label">
                        {{ "common.sumInsured" | translate }}
                        ({{
                          "common." + selectedQuotation?.typeOfSum | translate
                        }}) :
                      </div>
                      <div class="value">
                        RM
                        {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
                      </div>
                    </div>
                    <div class="car-plan-details">
                      <div class="label">
                        {{ "common.effectivePeriod" | translate }} :
                      </div>
                      <div class="value">
                        {{ requestData.effectiveDate | date : "dd MMM yyyy" }}
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>

              <mat-expansion-panel
                class="order-summary-card"
                [expanded]="false"
                hideToggle
                #panels
                *ngIf="
                  addOnFrmGroup.value?.isIncludeRoadtax || selectedAddOn.length
                "
              >
                <mat-expansion-panel-header #panel (click)="panel._toggle()">
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "addOn.addOns" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 1"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                      <button mat-icon-button (click)="panel._toggle()">
                        <mat-icon *ngIf="panels.expanded"
                          >keyboard_arrow_up</mat-icon
                        >
                        <mat-icon *ngIf="!panels.expanded"
                          >keyboard_arrow_down</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div>
                  <mat-divider></mat-divider>
                  <div class="pv-sm">
                    <div
                      class="order-details"
                      *ngIf="addOnFrmGroup.value?.isIncludeRoadtax"
                    >
                      <div class="label">{{ "addOn.roadTax" | translate }}</div>
                      <div class="value" *ngIf="isIncludeDigitalRoadtax">
                        RM
                        {{
                          requestData.originalRoadTaxPrice | number : "1.2-2"
                        }}
                      </div>
                      <div class="value" *ngIf="!isIncludeDigitalRoadtax">
                        RM
                        {{
                          requestData.originalRoadTaxPrice + 15.0
                            | number : "1.2-2"
                        }}
                      </div>
                    </div>
                    <div *ngFor="let item of selectedAddOn">
                      <div class="order-details">
                        <div class="label">
                          {{ "addOn." + item.code | translate }}
                        </div>
                        <ng-container [ngSwitch]="item.code">
                          <ng-container *ngSwitchCase="'Windshield'">
                            <div class="value">
                              RM
                              {{
                                getPrice(item, addOnFrmGroup.value.windshield)
                                  | number : "1.2-2"
                              }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="'AdditionalDrivers'">
                            <div class="value">
                              RM
                              {{
                                getPrice(item, getFormArray()?.controls.length)
                                  | number : "1.2-2"
                              }}
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <div class="value">
                              RM {{ getPrice(item) | number : "1.2-2" }}
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                class="order-summary-card"
                [expanded]="false"
                hideToggle
                #panels
              >
                <mat-expansion-panel-header #panel (click)="panel._toggle()">
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "common.carDetails" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 2"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                      <button mat-icon-button (click)="panel._toggle()">
                        <mat-icon *ngIf="panels.expanded"
                          >keyboard_arrow_up</mat-icon
                        >
                        <mat-icon *ngIf="!panels.expanded"
                          >keyboard_arrow_down</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div>
                  <mat-divider></mat-divider>
                  <div class="pv-sm">
                    <div class="order-details">
                      <div class="label">
                        {{ "common.carNumber" | translate }}
                      </div>
                      <div class="value">
                        {{ requestData?.vehicleNo }}
                      </div>
                    </div>

                    <div class="order-details">
                      <div class="label">
                        {{ "common.carModel" | translate }}
                      </div>
                      <div class="value">{{ requestData?.vehicle }}</div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                class="order-summary-card"
                [expanded]="false"
                hideToggle
                #personalDetails
              >
                <mat-expansion-panel-header
                  #pdPanel
                  (click)="pdPanel._toggle()"
                >
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "common.personalDetails" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 2"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                      <button mat-icon-button (click)="pdPanel._toggle()">
                        <mat-icon *ngIf="personalDetails.expanded"
                          >keyboard_arrow_up</mat-icon
                        >
                        <mat-icon *ngIf="!personalDetails.expanded"
                          >keyboard_arrow_down</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div>
                  <mat-divider></mat-divider>
                  <div class="pv-sm">
                    <div class="order-details">
                      <div class="label">
                        {{ getIdentityNoLabel() | translate }}
                      </div>
                      <div class="value">
                        {{ requestData?.identityNo }}
                      </div>
                    </div>

                    <ng-container *ngIf="!requestData?.isCompany">
                      <ng-container *ngIf="requestData?.isMalaysian">
                        <ng-container *ngIf="requestData?.policIdentityNo">
                          <div class="order-details">
                            <div class="label">
                              {{ "common.policeOrArmyIcNo" | translate }}
                            </div>
                            <div class="value">
                              {{ requestData?.policIdentityNo || "-" }}
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>

                      <ng-container *ngIf="!requestData?.isMalaysian">
                        <div class="order-details">
                          <div class="label">
                            {{ "common.nationality" | translate }}
                          </div>
                          <div class="value">
                            {{ requestData?.nationality }}
                          </div>
                        </div>
                      </ng-container>

                      <div class="order-details">
                        <div class="label">
                          {{ "common.gender" | translate }} :
                        </div>
                        <div class="value">
                          {{ "common." + requestData.gender | translate }}
                        </div>
                      </div>
                      <div class="order-details">
                        <div class="label">
                          {{ "common.maritalStatus" | translate }}
                        </div>
                        <div class="value">
                          {{
                            "common." + requestData.maritalStatus | translate
                          }}
                        </div>
                      </div>
                      <div class="order-details">
                        <div class="label">
                          {{ "common.dob" | translate }} :
                        </div>
                        <div class="value">
                          {{ requestData?.dob | date : "dd MMM yyyy" }}
                        </div>
                      </div>
                    </ng-container>

                    <div class="order-details">
                      <div class="label">
                        {{ "common.mobileNo" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.mobileNo }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.email" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.email }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                class="order-summary-card"
                [expanded]="false"
                hideToggle
                #residentialPanel
              >
                <mat-expansion-panel-header #rpanel (click)="rpanel._toggle()">
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "common.residentialAddress" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 2"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                      <button mat-icon-button (click)="rpanel._toggle()">
                        <mat-icon *ngIf="residentialPanel.expanded"
                          >keyboard_arrow_up</mat-icon
                        >
                        <mat-icon *ngIf="!residentialPanel.expanded"
                          >keyboard_arrow_down</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div>
                  <mat-divider></mat-divider>
                  <div class="pv-sm">
                    <div class="order-details">
                      <div class="label">
                        {{ "common.addressLineOne" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.address }}
                      </div>
                    </div>

                    <div class="order-details">
                      <div class="label">
                        {{ "common.addressLineTwo" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.address2 }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.city" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.city }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.postcode" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.getRawValue().postcode }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.state" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.state }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                class="order-summary-card"
                [expanded]="false"
                hideToggle
                #panels
                *ngIf="
                  addOnFrmGroup.value?.isIncludeRoadtax &&
                  !isIncludeDigitalRoadtax
                "
              >
                <mat-expansion-panel-header #panel (click)="panel._toggle()">
                  <div class="title-container">
                    <div class="mdTitle-f">
                      {{ "common.deliveryAddress" | translate }}
                    </div>
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        (click)="stepper.selectedIndex = 2"
                      >
                        {{ "common.edit" | translate }}
                      </button>
                      <button mat-icon-button (click)="panel._toggle()">
                        <mat-icon *ngIf="panels.expanded"
                          >keyboard_arrow_up</mat-icon
                        >
                        <mat-icon *ngIf="!panels.expanded"
                          >keyboard_arrow_down</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div>
                  <mat-divider></mat-divider>
                  <div class="pv-sm">
                    <div class="order-details">
                      <div class="label">
                        {{ "common.addressLineOne" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.deliveryAddress }}
                      </div>
                    </div>

                    <div class="order-details">
                      <div class="label">
                        {{ "common.addressLineTwo" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.deliveryAddress2 }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.city" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.deliveryCity }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.postcode" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.deliveryPostcode }}
                      </div>
                    </div>
                    <div class="order-details">
                      <div class="label">
                        {{ "common.state" | translate }}
                      </div>
                      <div class="value">
                        {{ infoFrmGroup.value.deliveryState }}
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
            <!-- ## End D2C Exclusive (Order summary display) -->

            <!-- ## Affiliate Exclusive (Order summary display) -->
            <ng-container *ngIf="partnerCode !== 'ps'">
              <div class="mb-sm">
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "common.carProtectionPlan" | translate }}
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div fxLayout="row" fxLayoutGap="16px grid">
                  <div fxFlex="50">
                    <mat-card>
                      <img
                        class="full-width"
                        [src]="selectedQuotation?.image"
                        alt=""
                      />
                    </mat-card>
                  </div>
                  <div class="content-wrapper m-t-0" fxFlex="50">
                    <div>
                      <div class="label-desc">
                        {{ "common.insuranceProvider" | translate }}
                        :
                      </div>
                      <div class="label-value">
                        {{ selectedQuotation?.name }}
                      </div>
                    </div>
                    <div>
                      <div class="label-desc">
                        {{ "common.typeOfInsurance" | translate }}
                        :
                      </div>
                      <div class="label-value">
                        {{ "common." + requestData?.insuranceType | translate }}
                      </div>
                    </div>
                    <div>
                      <div class="label-desc">
                        {{ "common.sumInsured" | translate }}
                        ({{
                          "common." + selectedQuotation?.typeOfSum | translate
                        }}) :
                      </div>
                      <div class="label-value">
                        RM
                        {{ selectedQuotation?.sumInsured | number : "1.2-2" }}
                      </div>
                    </div>
                    <div>
                      <div class="label-desc">
                        {{ "common.effectivePeriod" | translate }} :
                      </div>
                      <div class="label-value">
                        {{ requestData.effectiveDate | date : "dd MMM yyyy" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mb-sm"
                *ngIf="
                  addOnFrmGroup.value?.isIncludeRoadtax || selectedAddOn.length
                "
              >
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">{{ "addOn.addOns" | translate }}</div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 1"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="add-ons-container">
                  <div
                    class="add-ons-cover"
                    *ngIf="addOnFrmGroup.value?.isIncludeRoadtax"
                  >
                    {{ "addOn.roadTax" | translate }}
                  </div>
                  <div class="add-ons-cover" *ngFor="let item of selectedAddOn">
                    {{ "addOn." + item.code | translate }}
                  </div>
                </div>
              </div>
              <div class="mb-sm">
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "common.carDetails" | translate }} :
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="content-wrapper">
                  <div class="left label-desc">
                    {{ "common.carNumber" | translate }} :
                  </div>
                  <div class="right label-value">
                    {{ requestData?.vehicleNo }}
                  </div>
                  <div class="left label-desc">
                    {{ "common.carModel" | translate }} :
                  </div>
                  <div class="right label-value">
                    {{ requestData?.vehicle }}
                  </div>
                </div>
              </div>
              <div class="mb-sm">
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "common.personalDetails" | translate }}
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="content-wrapper">
                  <div class="label-desc left">
                    {{ getNameLabel() | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.name }}
                  </div>

                  <ng-container *ngTemplateOutlet="personalDetail">
                  </ng-container>

                  <div class="label-desc left">
                    {{ "common.occupation" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.occupation }}
                  </div>

                  <div class="label-desc left">
                    {{ "common.mobileNo" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.mobileNo }}
                  </div>

                  <div class="label-desc left">
                    {{ "common.email" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.email }}
                  </div>
                </div>
              </div>

              <div class="mb-sm">
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "common.residentialAddress" | translate }}
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="content-wrapper">
                  <div class="label-desc left">
                    {{ "common.addressLineOne" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.address }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.addressLineTwo" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.address2 }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.city" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.city }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.postcode" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.getRawValue().postcode }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.state" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.state }}
                  </div>
                </div>
              </div>

              <div class="mb-sm" *ngIf="addOnFrmGroup.value?.isIncludeRoadtax">
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "common.deliveryAddress" | translate }}
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="content-wrapper">
                  <div class="label-desc left">
                    {{ "common.addressLineOne" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.deliveryAddress }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.addressLineTwo" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.deliveryAddress2 }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.city" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.deliveryCity }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.postcode" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.deliveryPostcode }}
                  </div>
                  <div class="label-desc left">
                    {{ "common.state" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.deliveryState }}
                  </div>
                </div>
              </div>

              <div
                class="mb-sm"
                *ngIf="showMileagePlans && stepper.selectedIndex === 3"
                [formGroup]="infoFrmGroup"
              >
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "Car's Mileage" | translate }}
                  </div>
                  <button
                    mat-stroked-button
                    class="edit-summary-btn"
                    (click)="stepper.selectedIndex = 2"
                  >
                    {{ "common.edit" | translate }}
                  </button>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="content-wrapper">
                  <div class="label-desc left">
                    {{ "Current car's mileage" | translate }} :
                  </div>
                  <div class="label-value right">
                    {{ infoFrmGroup.value.mileage | number }} KM
                  </div>
                </div>
              </div>

              <div
                class="mb-sm"
                *ngIf="showMileagePlans && stepper.selectedIndex === 3"
                [formGroup]="infoFrmGroup"
              >
                <div fxLayout="row" class="mb-sm" fxLayoutAlign=" center">
                  <div class="mdTitle-f">
                    {{ "uploadMileage.document" | translate }}
                  </div>
                  <div
                    class="summary-divider"
                    [ngClass]="{ grey: partnerCode !== 'ps' }"
                    fxFlex
                  ></div>
                </div>
                <div class="section-container">
                  <app-upload-mileage
                    formControlName="mileagePhoto"
                    [summaryMode]="true"
                  ></app-upload-mileage>
                </div>
              </div>
            </ng-container>
            <!-- ## End Affiliate Exclusive (Order summary display) -->
          </div>
          <div fxFlex.lt-md="100" fxFlex.gt-sm="427px">
            <div class="sidebar-container-affiliate">
              <ng-container *ngTemplateOutlet="insurerBenefits"></ng-container>
              <div
                class="sticky-order-summary"
                *ngIf="!(screenSizeService.isMobile$ | async)"
              >
                <app-expandable-card
                  [title]="'common.orderSummary' | translate"
                >
                  <ng-container snippet>
                    <ng-container
                      *ngTemplateOutlet="orderSummarySnippet"
                    ></ng-container>
                  </ng-container>
                  <ng-container hidden>
                    <ng-container *ngTemplateOutlet="priceList"></ng-container>
                  </ng-container>
                  <ng-container share>
                    <app-order-summary-note
                      [text]="
                        'comparison.orderSummaryNote'
                          | translate
                            : {
                                commission:
                                  getPSComissionPrice() | number : '1.2-2'
                              }
                      "
                      *ngIf="partnerCode != 'carsome'"
                    ></app-order-summary-note>
                    <ng-container
                      *ngTemplateOutlet="promoTemplate"
                    ></ng-container>
                    <app-order-summary-note
                      [text]="
                        'comparison.orderSummaryNoteDisclaimer' | translate
                      "
                      *ngIf="partnerCode != 'carsome'"
                    ></app-order-summary-note>
                    <ng-container
                      *ngTemplateOutlet="paymentOption"
                    ></ng-container>
                    <ng-container
                      *ngTemplateOutlet="
                        multiCheckbox;
                        context: { form: agreementFrmGroup }
                      "
                    ></ng-container>
                  </ng-container>
                </app-expandable-card>
              </div>
            </div>

            <!-- Payment options Mobile version -->
            <div
              class="mobile-order-summary"
              *ngIf="screenSizeService.isMobile$ | async"
            >
              <div class="insurance-provider">
                <app-section-title
                  [title]="'common.insuranceProvider' | translate"
                ></app-section-title>
                <ng-container *ngTemplateOutlet="insurerBenefitsContent">
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
              <div class="mt-lg">
                <app-section-title
                  [title]="'common.orderSummary' | translate"
                ></app-section-title>
              </div>
              <ng-container *ngTemplateOutlet="priceList"></ng-container>
              <app-order-summary-note
                [text]="
                  'comparison.orderSummaryNote'
                    | translate
                      : { commission: getPSComissionPrice() | number : '1.2-2' }
                "
                *ngIf="partnerCode != 'carsome'"
              ></app-order-summary-note>
              <ng-container *ngTemplateOutlet="promoTemplate"> </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  multiCheckbox;
                  context: { form: agreementFrmGroup }
                "
              ></ng-container>
            </div>

            <div class="sticky-total-amt">
              <app-mobile-total-amount
                [totalAmount]="getTotal()"
                [label]="getPaymentDurationLabel()"
              ></app-mobile-total-amount>
            </div>
          </div>
        </div>
      </form>
    </mat-step>

    <mat-step state="pay" [aria-labelledby]="'disabled'">
      <ng-template matStepLabel>
        {{ "comparison.step5" | translate }}
      </ng-template>
    </mat-step>

    <ng-template matStepperIcon="edit">
      <mat-icon>edit</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="close">
      <mat-icon>close</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="pay">
      <mat-icon>payment</mat-icon>
    </ng-template>
  </mat-stepper>
</div>
<div class="footer-bnm-bg" *ngIf="stepper?.selectedIndex !== 0">
  {{ "footer.BNM1" | translate : { insurer: insurerFullName } }}<br /><br />
  <span>
    {{
      "comparison.orderSummaryNote"
        | translate : { commission: getPSComissionPrice() | number : "1.2-2" }
    }}. {{ "footer.BNM2" | translate : { insurer: insurerFullName } }}
  </span>
</div>

<ng-template #insurerBenefits>
  <mat-card class="insurer-benefits-card">
    <div class="header">
      {{ "common.insuranceProvider" | translate }}
    </div>
    <div class="insurer-benefits-card__content">
      <ng-container *ngTemplateOutlet="insurerBenefitsContent"></ng-container>
    </div>
  </mat-card>
</ng-template>

<ng-template #insurerBenefitsContent>
  <img [src]="selectedQuotation?.image" class="selected-insurer" alt="" />
  <mat-expansion-panel class="summary-expand">
    <mat-expansion-panel-header
      [collapsedHeight]="'100%'"
      [expandedHeight]="'100%'"
    >
      <mat-panel-title>
        {{ "common.benefits" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider>
    <div class="expand-content">
      <app-benefit
        [code]="selectedQuotation?.code"
        [insuranceType]="requestData?.insuranceType"
        [partnerCode]="partnerCode"
      ></app-benefit>
    </div>
  </mat-expansion-panel>
</ng-template>

<ng-template #priceList>
  <div class="order-summary-container mt-children-md">
    <app-flex-container
      [title]="'common.sumInsured' | translate"
      [value]="selectedQuotation?.sumInsured | number : '1.2-2'"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.basePremium' | translate"
      [value]="selectedQuotation?.basePremium | number : '1.2-2'"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="
        ('common.noClaimDiscount' | translate) +
        '(' +
        (requestData?.ncd * 100 | number : '1.0-2') +
        '%)'
      "
      [value]="
        selectedQuotation?.basePremium -
          selectedQuotation?.totalBasePremium -
          (selectedQuotation?.mileagePlanDiscount || 0) | number : '1.2-2'
      "
      type="discount"
    ></app-flex-container>
    <!-- TODO: Uncomment mileage plan discount if needed in future -->
    <!--    <app-flex-container-->
    <!--      [title]="'common.mileagePlanDiscount' | translate"-->
    <!--      [value]="(selectedQuotation?.mileagePlanDiscount || 0) | number : '1.2-2'"-->
    <!--      type="discount"-->
    <!--    ></app-flex-container>-->
    <div class="divider"></div>
    <app-flex-container
      [title]="'common.totalBasePremium' | translate"
      [value]="selectedQuotation?.totalBasePremium | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>

    <div *ngIf="hasAddOn()" class="tag">
      {{ "addOn.addOns" | translate }}
    </div>

    <ng-container *ngFor="let item of selectedAddOn">
      <ng-container *ngIf="!item.hidePriceBreakdown">
        <ng-container [ngSwitch]="item.code">
          <ng-container *ngSwitchCase="'Windshield'">
            <app-flex-container
              [title]="'addOn.' + item.code | translate"
              [value]="
                getPrice(item, addOnFrmGroup.value.windshield)
                  | number : '1.2-2'
              "
              [currency]="true"
            ></app-flex-container>
          </ng-container>
          <ng-container *ngSwitchCase="'AdditionalDrivers'">
            <app-flex-container
              [title]="'addOn.' + item.code | translate"
              [value]="
                getPrice(item, getFormArray()?.controls.length)
                  | number : '1.2-2'
              "
              [currency]="true"
            ></app-flex-container>
          </ng-container>
          <ng-container *ngSwitchCase="'LLTP'">
            <app-flex-container
              [title]="'addOn.' + item.code | translate"
              [value]="
                getPrice(item, this.numberOfLltpSeats) | number : '1.2-2'
              "
              [currency]="true"
            ></app-flex-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <app-flex-container
              [title]="'addOn.' + item.code | translate"
              [value]="getPrice(item) | number : '1.2-2'"
              [currency]="true"
            ></app-flex-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <div class="divider"></div>

    <app-flex-container
      [title]="'common.totalGrossPremium' | translate"
      [value]="getGrossPremium() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.serviceTax' | translate"
      [value]="getTax() | number : '1.2-2'"
      [currency]="true"
      type="normal"
    ></app-flex-container>
    <app-flex-container
      [title]="'common.stampDuty' | translate"
      [value]="selectedQuotation?.stampDutyPrice | number : '1.2-2'"
      [currency]="true"
      type="normal"
    ></app-flex-container>

    <div class="divider"></div>
    <app-flex-container
      [title]="'common.totalNetPremium' | translate"
      [value]="getTotalNetPremium() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>

    <ng-container *ngIf="getPlatformDiscountRate() !== 0">
      <app-flex-container
        [title]="'common.platformOffer' | translate"
        [value]="getPlatformDiscountRate() | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>
    <ng-container *ngIf="addOnFrmGroup.value?.isIncludeRoadtax">
      <app-flex-container
        [title]="'addOn.roadTaxRenewal' | translate"
        [value]="requestData.originalRoadTaxPrice | number : '1.2-2'"
        [currency]="true"
        type="normal"
      ></app-flex-container>
      <ng-container *ngIf="isIncludeDigitalRoadtax">
        <app-flex-container
          [title]="'digitalRoadTaxFee' | translate"
          [value]="0 | number : '1.2-2'"
          type="normal"
          [currency]="true"
        ></app-flex-container>
      </ng-container>
      <ng-container *ngIf="!isIncludeDigitalRoadtax">
        <app-flex-container
          [title]="'addOn.roadTaxDelivery' | translate"
          type="normal"
          [value]="requestData.roadTaxDeliveryPrice | number : '1.2-2'"
          [currency]="true"
        ></app-flex-container>
      </ng-container>
    </ng-container>
    <div class="divider"></div>

    <app-flex-container
      [title]="'common.totalCharges' | translate"
      [value]="getTotalCharge() | number : '1.2-2'"
      type="total"
      [currency]="true"
    ></app-flex-container>

    <ng-container *ngIf="promo">
      <app-flex-container
        [title]="promo.name"
        [value]="getTotalPromo() | number : '1.2-2'"
        type="discount"
        [currency]="true"
      ></app-flex-container>
    </ng-container>

    <app-flex-container
      [title]="'common.serviceFee' | translate"
      [value]="getServiceFee() | number : '1.2-2'"
      type="normal"
      [currency]="true"
    ></app-flex-container>

    <app-flex-container
      [title]="'common.bankInstalmentCharges' | translate"
      [value]="getBankInstalmentCharges() | number : '1.2-2'"
      *ngIf="isPaymentTypeEPP"
      [type]="getBankInstalmentCharges() == 0 ? 'zeroInstalment' : 'normal'"
      [currency]="true"
    ></app-flex-container>

    <app-flex-container *ngIf="isPaymentTypeBNPL"
      [title]="'common.bnplCharges' | translate"
      [value]="getBNPLCharges() | number : '1.2-2'"
      [currency]="true"
      [type]="getBNPLCharges() == 0 ? 'bnplSubsidized' : 'normal'">
    </app-flex-container>

    <div class="divider"></div>
    <div class="total-amt">
      <div class="title">{{ "common.totalAmount" | translate }}</div>
      <div class="value">RM {{ getTotal() | number : "1.2-2" }}</div>
    </div>
    <div
      class="order-summary-container__note"
      *ngIf="getPaymentDurationLabel()"
      [innerHTML]="getPaymentDurationLabel()"
    ></div>
    <div class="divider"></div>
  </div>
</ng-template>

<ng-template #addOnList>
  <ng-container *ngIf="selectedAddOn.length">
    <ng-container *ngFor="let item of selectedAddOn">
      <ng-container [ngSwitch]="item.code">
        <ng-container *ngSwitchCase="'Windshield'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="
              getPrice(item, addOnFrmGroup.value.windshield) | number : '1.2-2'
            "
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchCase="'AdditionalDrivers'">
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="
              getPrice(item, getFormArray()?.controls.length) | number : '1.2-2'
            "
            [currency]="true"
          ></app-flex-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <app-flex-container
            [title]="'addOn.' + item.code | translate"
            [value]="getPrice(item) | number : '1.2-2'"
            [currency]="true"
          ></app-flex-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #personalDetail>
  <div class="label-desc left">{{ getIdentityNoLabel() | translate }} :</div>
  <div class="label-value right">
    {{ requestData?.identityNo }}
  </div>

  <ng-container *ngIf="!requestData?.isCompany">
    <ng-container *ngIf="requestData?.isMalaysian">
      <ng-container *ngIf="requestData?.policIdentityNo">
        <div class="label-desc left">
          {{ "common.policeOrArmyIcNo" | translate }} :
        </div>
        <div class="label-value right">
          {{ requestData?.policIdentityNo || "-" }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!requestData?.isMalaysian">
      <div class="label-desc left">
        {{ "common.nationality" | translate }} :
      </div>
      <div class="label-value right">
        {{ requestData?.nationality }}
      </div>
    </ng-container>

    <div class="label-desc left">{{ "common.gender" | translate }} :</div>
    <div class="label-value right">
      {{ "common." + requestData.gender | translate }}
    </div>
    <div class="label-desc left">
      {{ "common.maritalStatus" | translate }} :
    </div>
    <div class="label-value right">
      {{ "common." + requestData.maritalStatus | translate }}
    </div>

    <div class="label-desc left">{{ "common.dob" | translate }} :</div>
    <div class="label-value right">
      {{ requestData?.dob | date : "dd MMM yyyy" }}
    </div>
  </ng-container>
</ng-template>

<ng-template #promoTemplate>
  <div class="promo-input" (click)="$event.stopPropagation()">
    <div class="flex">
      <mat-form-field appearance="outline">
        <input
          matInput
          [placeholder]="'common.enterPromoCode' | translate"
          [(ngModel)]="promoCode"
          [readonly]="promo != null"
          autocomplete="off"
          (click)="$event.stopPropagation()"
          [ngModelOptions]="{ standalone: true }"
        />
        <div class="floating-btn">
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); clearPromoCode()"
            *ngIf="promoCode"
          >
            <mat-icon class="color-secondary">cancel</mat-icon>
          </button>

          <button
            type="button"
            mat-icon-button
            matSuffix
            *ngIf="promo && promoCode"
          >
            <mat-icon class="color-green">check_circle</mat-icon>
          </button>
        </div>
        <button
          type="button"
          mat-flat-button
          matSuffix
          (click)="$event.stopPropagation(); applyPromoCode()"
          *ngIf="!promo || !promoCode"
        >
          {{ "common.apply" | translate }}
        </button>

        <button
          type="button"
          mat-flat-button
          matSuffix
          (click)="$event.stopPropagation(); applyPromoCode()"
          *ngIf="promo && promoCode"
        >
          {{ "common.applied" | translate }}
        </button>
      </mat-form-field>
    </div>
    <div class="promo-error" *ngIf="promoError">
      {{ "common." + promoError | translate }}
    </div>
    <div class="promo-valid" *ngIf="promo && promoCode">
      Promo code applied.
    </div>
  </div>
</ng-template>

<ng-template #addOnFrom>
  <!-- include add on -->
  <form [formGroup]="addOnFrmGroup">
    <!-- Road Tax -->
    <mat-card
      class="addon-card roadTaxRenewalClass"
      *ngIf="!requestData.eHailingUsed"
      [class.selected]="addOnFrmGroup.value?.isIncludeRoadtax"
    >
      <mat-checkbox
        formControlName="isIncludeRoadtax"
        (change)="toggleRoadTax($event)"
        [checked]="isRoadTaxSelected(addOnFrmGroup.value?.isIncludeRoadtax)"
      >
        <div fxLayout="row">
          <div fxFlex class="title">
            {{ "dashboard.roadTaxRenewTitle" | translate }}
          </div>
          <div class="value">
            <span>RM</span>
            {{ addOnFrmGroup?.value?.roadTaxPrice | number : "1.2-2" }}
          </div>
          <mat-icon
            class="tooltip-icon"
            [matMenuTriggerFor]="roadTaxTooltip"
            #roadTaxTrigger="matMenuTrigger"
            (mouseenter)="roadTaxTrigger.openMenu()"
          >
            info
          </mat-icon>
          <mat-menu
            #roadTaxTooltip="matMenu"
            [overlapTrigger]="false"
            class="tooltip-popover"
          >
            <span (mouseleave)="roadTaxTrigger.closeMenu()">
              {{ "addOn.roadTaxTooltip" | translate }}
            </span>
          </mat-menu>
        </div>
      </mat-checkbox>
      <mat-radio-group
        *ngIf="showRoadTaxList"
        (change)="radioDigitalRoadTaxChange($event)"
      >
        <mat-radio-button
          [value]="includeDigitalRoadTax"
          [checked]="requestData.isMalaysian && !requestData.isCompany"
          *ngIf="requestData.isMalaysian && !requestData.isCompany"
        >
          <ng-container>
            <div fxLayout="row" fxLayoutAlign=" center">
              <div class="title" fxFlex>
                {{ "addOn.digitalRoadTax" | translate }}
              </div>
              <div class="value sub-right">
                <!-- <del>RM 8.00</del>  -->
                {{ "capitalFree" | translate }}
                <div class="sub-value">
                  {{ "limitedTimeOnly" | translate }}
                </div>
              </div>
            </div>
          </ng-container>
        </mat-radio-button>
        <mat-radio-button
          [value]="!includeDigitalRoadTax"
          [checked]="!requestData.isMalaysian || requestData.isCompany"
        >
          <ng-container>
            <div fxLayout="row" fxLayoutAlign=" center">
              <div class="title" fxFlex>
                {{ "addOn.physicalRoadtax" | translate }}
              </div>
              <div class="value sub-right">
                RM 15.00
                <div class="sub-value">
                  {{ "handlingFee" | translate }}
                </div>
              </div>
            </div>
          </ng-container>
        </mat-radio-button>
      </mat-radio-group>
      <ng-container
        *ngIf="addOnFrmGroup.value?.isIncludeRoadtax && showRoadTaxList"
      >
        <div fxLayout="row">
          <mat-checkbox fxFlex color="warn" formControlName="isNotBlacklisted">
            {{ "addOn.isNotBlacklisted" | translate }}
          </mat-checkbox>
          <!-- <div class="desc sub-right">
            {{ "addOn.deliveryCharge" | translate }} <br />
            <b> RM {{ requestData.roadTaxDeliveryPrice | number : "1.2-2" }}</b>
          </div> -->
        </div>
      </ng-container>
      <div
        *ngIf="addOnFrmGroup.value?.isIncludeRoadtax && showRoadTaxList"
        class="roadtax-content__notice"
      >
        <div class="roadtax-content__notice__title">
          {{ "addOn.roadTaxNoticeTitle" | translate }}
        </div>
        <div class="roadtax-content__notice__desc"
             [innerHTML]="'addOn.roadTaxNoticeDesc' | translate"></div>
      </div>
    </mat-card>
    <!-- Road Tax   -->
    <!-- Add-ons-->
    <ng-container *ngFor="let category of addOnOptionalCategory">
      <ng-template
        #addOn
        [ngTemplateOutlet]="addOn"
        let-addOnList="addOnList"
        [ngTemplateOutletContext]="{
          addOnList: getAddOnListByCategory(category)
        }"
      >
        <ng-container *ngIf="!isChubbInstantQuotation" [ngSwitch]="category">
          <ng-container *ngSwitchCase="'Windshield'">
            <ng-template
              #Windshield
              [ngTemplateOutlet]="Windshield"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div fxFlex class="title">
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{
                        getPrice(addOn, addOnFrmGroup.value.windshield)
                          | number : "1.2-2"
                      }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="windshieldTooltip"
                      #windshieldTrigger="matMenuTrigger"
                      (mouseenter)="windshieldTrigger.openMenu()"
                    >
                      info</mat-icon
                    >
                    <mat-menu
                      #windshieldTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="windshieldTrigger.closeMenu()">
                        {{ "addOn.WindshieldTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                </mat-checkbox>

                <ng-container *ngIf="isAddOnSelected(addOn)">
                  <div class="slider-desc">
                    {{ "addOn.coverage" | translate }} :
                  </div>
                  <app-range-slider
                    [max]="getMinMaxWindscreenPrice(addOn, 'max')"
                    [min]="getMinMaxWindscreenPrice(addOn, 'min')"
                    [step]="50"
                    [variantSmall]="true"
                    formControlName="windshield"
                    [maxError]="
                      'addOn.maxWindscreenError'
                        | translate
                          : {
                              amount:
                                getMinMaxWindscreenPrice(addOn, 'max')
                                | currency : 'RM' : 'symbol' : '1.2-2'
                            }
                    "
                    [minError]="
                      'addOn.minWindscreenError'
                        | translate
                          : {
                              amount:
                                getMinMaxWindscreenPrice(addOn, 'min')
                                | currency : 'RM' : 'symbol' : '1.2-2'
                            }
                    "
                  ></app-range-slider>
                </ng-container>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'Flood'">
            <mat-card
              class="addon-card"
              [class.selected]="hasSelectedCategory('Flood')"
              *ngIf="addOn"
            >
              <ng-container *ngIf="addOnList.length === 1">
                <ng-template
                  #flood
                  [ngTemplateOutlet]="flood"
                  let-addOn="addOn"
                  [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
                >
                  <mat-checkbox
                    [value]="addOn"
                    (change)="toggleAddOn($event)"
                    [checked]="isAddOnSelected(addOn)"
                  >
                    <div fxLayout="row">
                      <div fxFlex class="title">
                        {{ "addOn." + addOn?.code | translate }}
                      </div>
                      <div class="value">
                        <span>RM</span>
                        {{ getPrice(addOn) | number : "1.2-2" }}
                      </div>
                      <mat-icon
                        class="tooltip-icon"
                        [matMenuTriggerFor]="FloodTooltip"
                        #FloodTrigger="matMenuTrigger"
                        (mouseenter)="FloodTrigger.openMenu()"
                      >
                        info</mat-icon
                      >
                      <mat-menu
                        #FloodTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover"
                      >
                        <span (mouseleave)="FloodTrigger.closeMenu()">
                          {{ "addOn.FloodTooltip" | translate }}
                        </span>
                      </mat-menu>
                    </div>

                    <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                      {{ "addOn.coverage" | translate }}:
                      <ul>
                        <li>{{ "addOn.fullFloodDesc" | translate }}</li>
                      </ul>
                    </div>
                  </mat-checkbox>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="addOnList.length !== 1">
                <ng-template
                  #flood
                  [ngTemplateOutlet]="flood"
                  let-addOn="addOn"
                  [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
                >
                  <mat-checkbox
                    [(ngModel)]="isFloodChecked"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="addOn"
                    (change)="toggleAddOn($event)"
                    [checked]="isAddOnSelected(addOn)"
                  >
                    <div fxLayout="row">
                      <div fxFlex class="title">
                        {{ "addOn.Flood" | translate }}
                      </div>
                      <mat-icon
                        class="tooltip-icon"
                        [matMenuTriggerFor]="FloodTooltip"
                        #FloodTrigger="matMenuTrigger"
                        (mouseenter)="FloodTrigger.openMenu()"
                      >
                        info
                      </mat-icon>
                      <mat-menu
                        #FloodTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover"
                      >
                        <span (mouseleave)="FloodTrigger.closeMenu()">
                          {{ "addOn.FloodTooltip" | translate }}
                        </span>
                      </mat-menu>
                    </div>
                  </mat-checkbox>
                </ng-template>
                <mat-radio-group
                  *ngIf="isFloodChecked"
                  (change)="radioChange($event.value)"
                >
                  <mat-radio-button
                    *ngFor="let item of addOnList; let first = first"
                    [value]="item"
                    [checked]="first"
                  >
                    <div fxLayout="row">
                      <div class="title" fxFlex>
                        {{ "addOn." + item.code | translate }}
                      </div>
                      <div class="value">
                        <span>RM</span>
                        {{ getPrice(item) | number : "1.2-2" }}
                      </div>
                    </div>
                    <div class="coverage-desc" *ngIf="isAddOnSelected(item)">
                      {{ "addOn.coverage" | translate }}:
                      <ul>
                        <li *ngIf="item.code === 'FullFloodPeril'">
                          {{ "addOn.fullFloodDesc" | translate }}
                        </li>
                        <li *ngIf="item.code === 'LimitedFloodPeril'">
                          {{ "addOn.limitedFloodDesc" | translate }}
                        </li>
                      </ul>
                    </div>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
            </mat-card>
          </ng-container>

          <ng-container *ngSwitchCase="'EHailing'">
            <ng-template
              #eHailing
              [ngTemplateOutlet]="eHailing"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card EHailing"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                  [disabled]="isGrabSingleQuotation"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="EHailingTooltip"
                      #EHailingTrigger="matMenuTrigger"
                      (mouseenter)="EHailingTrigger.openMenu()"
                    >
                      info</mat-icon
                    >
                    <mat-menu
                      #EHailingTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="EHailingTrigger.closeMenu()">
                        {{ "addOn.EHailingTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>

                  <div
                    class="coverage-desc"
                    style="white-space: normal"
                    *ngIf="isAddOnSelected(addOn)"
                  >
                    {{ "addOn.eHailingDesc" | translate }}
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'LLOPNA'">
            <ng-template
              #LLOPNA
              [ngTemplateOutlet]="LLOPNA"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row wrap">
                    <div class="title" fxFlex="70">
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <span fxFlex></span>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="LLOPNATooltip"
                      #LLOPNATrigger="matMenuTrigger"
                      (mouseenter)="LLOPNATrigger.openMenu()"
                    >
                      info</mat-icon
                    >
                    <mat-menu
                      #LLOPNATooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="LLOPNATrigger.closeMenu()">
                        {{ "addOn.LLOPNATooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <ul>
                      <li>{{ "addOn.LLDesc1" | translate }}</li>
                      <li>{{ "addOn.LLDesc2" | translate }}</li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'SRCC'">
            <ng-template
              #SRCC
              [ngTemplateOutlet]="SRCC"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="SRCCTooltip"
                      #SRCCTrigger="matMenuTrigger"
                      (mouseenter)="SRCCTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #SRCCTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="SRCCTrigger.closeMenu()">
                        {{ "addOn.SRCCTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <ul>
                      <li>
                        {{
                          "addOn.SRCCDesc"
                            | translate
                              : {
                                  sumInsured:
                                    selectedQuotation?.sumInsured
                                    | number : "1.2-2"
                                }
                        }}
                      </li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'LLTP'">
            <ng-template
              #LLTP
              [ngTemplateOutlet]="LLTP"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{
                        getPrice(addOn, this.numberOfLltpSeats)
                          | number : "1.2-2"
                      }}
                    </div>

                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="LLTPTooltip"
                      #LLTPTrigger="matMenuTrigger"
                      (mouseenter)="LLTPTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #LLTPTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="LLTPTrigger.closeMenu()">
                        {{ "addOn.LLTPTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <label class="seat-label">
                      {{ numberOfLltpSeats }} {{ "common.seats" | translate }}
                    </label>
                    <ul>
                      <li>{{ "addOn.LLDesc1" | translate }}</li>
                      <li>{{ "addOn.LLDesc2" | translate }}</li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'UnlimitedTowing'">
            <ng-template
              #UnlimitedTowing
              [ngTemplateOutlet]="UnlimitedTowing"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'Drivers'">
            <mat-card
              class="addon-card"
              [class.selected]="hasSelectedCategory('Drivers')"
              *ngIf="partnerCode !== 'ps' || !toggleRecommendedAddOnsState"
            >
              <ng-container *ngIf="addOnList.length === 1">
                <ng-template
                  #drivers
                  [ngTemplateOutlet]="drivers"
                  let-addOn="addOn"
                  [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
                >
                  <ng-container>
                    <mat-checkbox
                      [value]="addOn"
                      (change)="toggleAddOn($event)"
                      [checked]="isAddOnSelected(addOn)"
                    >
                      <div fxLayout="row">
                        <div class="title" fxFlex>
                          {{ "addOn." + addOn?.code | translate }}
                        </div>
                        <div
                          class="value"
                          *ngIf="addOn?.code !== 'AdditionalDrivers'"
                        >
                          <span>RM</span>
                          {{ getPrice(addOn) | number : "1.2-2" }}
                        </div>
                        <mat-icon
                          class="tooltip-icon"
                          [matMenuTriggerFor]="unlimitedTooltip"
                          #unlimitedTrigger="matMenuTrigger"
                          (mouseenter)="unlimitedTrigger.openMenu()"
                        >
                          info
                        </mat-icon>
                        <mat-menu
                          #unlimitedTooltip="matMenu"
                          [overlapTrigger]="false"
                          class="tooltip-popover"
                        >
                          <span (mouseleave)="unlimitedTrigger.closeMenu()">
                            {{ "addOn.UnlimitedTooltip" | translate }}
                          </span>
                        </mat-menu>
                        <div
                          class="value text-right"
                          *ngIf="addOn?.code === 'AdditionalDrivers'"
                        >
                          {{ "addOn.free" | translate }}
                          <div
                            class="sub-value"
                            [translate]="'addOn.additionalDriverDesc'"
                            [translateParams]="{
                              val:
                                maxFreeDriver ?? translate.instant('number.1')
                            }"
                          ></div>
                        </div>
                      </div>
                    </mat-checkbox>

                    <ng-container> </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="addOnList.length !== 1">
                <mat-checkbox
                  [(ngModel)]="isDriverChecked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="radioChange(addOnList[0], true)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn.AdditionalDrivers" | translate }}
                    </div>
                  </div>
                </mat-checkbox>

                <mat-radio-group
                  *ngIf="isDriverChecked"
                  (change)="radioChange($event.value)"
                >
                  <mat-radio-button
                    *ngFor="let item of addOnList; let first = first"
                    [value]="item"
                    [checked]="first"
                  >
                    <ng-container *ngIf="item.code !== 'AdditionalDrivers'">
                      <div fxLayout="row" fxLayoutAlign=" center">
                        <div class="title" fxFlex>
                          {{ "addOn.UnlimitedDrivers" | translate }}
                        </div>
                        <div class="value sub-right">
                          <span>RM</span>
                          {{ getPrice(item) | number : "1.2-2" }}
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="item.code === 'AdditionalDrivers'">
                      <div fxLayout="row" fxLayoutAlign=" center">
                        <div class="title" fxFlex>
                          {{ "addOn.additionalDriver" | translate }}
                        </div>
                        <div
                          class="value"
                          *ngIf="
                            getFormArray()?.controls.length > 1;
                            else freeDriverText
                          "
                        >
                          <span>RM</span>
                          {{
                            getPrice(item, getFormArray()?.controls.length)
                              | number : "1.2-2"
                          }}
                        </div>
                        <ng-template #freeDriverText>
                          <div class="value text-right">
                            {{ "addOn.free" | translate }}
                            <div
                              class="sub-value"
                              [translate]="'addOn.additionalDriverDesc'"
                              [translateParams]="{
                                val:
                                  maxFreeDriver ?? translate.instant('number.1')
                              }"
                            ></div>
                          </div>
                        </ng-template>
                        <mat-icon
                          class="tooltip-icon"
                          [matMenuTriggerFor]="AdditionalDriversTooltip"
                          #AdditionalDriversTrigger="matMenuTrigger"
                          (mouseenter)="AdditionalDriversTrigger.openMenu()"
                        >
                          info
                        </mat-icon>
                      </div>

                      <mat-menu
                        #AdditionalDriversTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover"
                      >
                        <span
                          (mouseleave)="AdditionalDriversTrigger.closeMenu()"
                        >
                          {{ "addOn.AdditionalDriversTooltip" | translate }}
                        </span>
                      </mat-menu>
                    </ng-container>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
              <ng-container *ngIf="hasSelectedCode('AdditionalDrivers')">
                <div
                  formArrayName="drivers"
                  *ngFor="let item of getFormArray()?.controls; let i = index"
                >
                  <br />
                  <ng-container [formGroupName]="i">
                    <div fxLayout="row" fxLayoutAlign=" center">
                      <div class="driver-count">
                        {{ "addOn.additionalDriver" | translate }} #{{ i + 1 }}
                      </div>
                      <div class="driver-divider" fxFlex></div>
                      <button
                        class="driver-delete"
                        (click)="deleteDriver(i)"
                        mat-icon-button
                        *ngIf="i !== 0"
                        type="button"
                      >
                        <mat-icon>delete_outline</mat-icon>
                      </button>
                    </div>

                    <br />

                    <div class="form-wrapper">
                      <div>
                        <mat-form-field appearance="outline">
                          <mat-label
                            >{{ "common.name" | translate }}
                          </mat-label>
                          <input
                            matInput
                            autocomplete="off"
                            formControlName="name"
                          />
                          <mat-error>
                            {{ "common.name" | translate }}
                            {{
                              getFormArrayControlError(i, "name")
                                | inputError
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>

                        <div class="sub-form-wrapper">
                          <mat-form-field
                            class="form-dropdown"
                            appearance="outline"
                          >
                            <mat-label
                              >{{ "common.idType" | translate }}
                            </mat-label>
                            <mat-select formControlName="idType">
                              <mat-option
                                *ngFor="let item of idTypeList"
                                [value]="item.codeValue"
                              >
                                {{ item.codeDesc }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field
                            class="form-input"
                            appearance="outline"
                          >
                            <mat-label
                              >{{ "common.idNo" | translate }}
                            </mat-label>
                            <input
                              matInput
                              autocomplete="off"
                              [mask]="
                                item.get('idType').value === 'IC_NO'
                                  ? '000000000000'
                                  : ''
                              "
                              formControlName="identityNo"
                            />
                            <mat-error>
                              {{ "common.idNo" | translate }}
                              {{
                                getFormArrayControlError(i, "identityNo")
                                  | inputError
                                  | translate
                              }}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <mat-form-field appearance="outline">
                          <mat-label>{{ "common.dob" | translate }}</mat-label>
                          <input
                            matInput
                            readonly
                            [matDatepicker]="picker"
                            formControlName="dob"
                            (click)="picker.open()"
                            [max]="maxDob"
                          />
                          <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                          ></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <mat-error>
                            {{ "common.dob" | translate }}
                            {{
                              getFormArrayControlError(i, "dob")
                                | inputError
                                | translate
                            }}
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div class="additional-radio-btn">
                        <mat-label
                          >{{ "common.gender" | translate }} :
                        </mat-label>
                        <mat-radio-group
                          aria-label="Select an option"
                          formControlName="gender"
                        >
                          <mat-radio-button value="M">
                            {{ "common.M" | translate }}
                          </mat-radio-button>
                          <mat-radio-button value="F">
                            {{ "common.F" | translate }}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <div class="additional-radio-btn">
                        <mat-label
                          >{{ "common.maritalStatus" | translate }} :
                        </mat-label>
                        <mat-radio-group
                          aria-label="Select an option"
                          formControlName="maritalStatus"
                        >
                          <mat-radio-button value="Single">
                            {{ "common.Single" | translate }}
                          </mat-radio-button>
                          <mat-radio-button value="Married">
                            {{ "common.Married" | translate }}
                          </mat-radio-button>
                        </mat-radio-group>
                      </div>

                      <mat-form-field appearance="outline">
                        <mat-label>{{
                          "common.relationship" | translate
                        }}</mat-label>
                        <mat-select
                          formControlName="relationship"
                          placeholder="Please select"
                        >
                          <mat-option
                            *ngFor="let item of relationshipList"
                            [value]="item.id"
                          >
                            {{ item.name | translate }}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ "common.relationship" | translate }}
                          {{
                            getFormArrayControlError(i, "relationship")
                              | inputError
                              | translate
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </ng-container>
                </div>
                <button
                  *ngIf="
                    !maxAdditionalDriver ||
                    getFormArray()?.length < maxAdditionalDriver
                  "
                  (click)="addDriver()"
                  type="button"
                  mat-stroked-button
                  class="add-driver-btn"
                >
                  <mat-icon>add_circle</mat-icon>
                  {{ "addOn.addDriver" | translate }}
                </button>
              </ng-container>
            </mat-card>
          </ng-container>

          <ng-container *ngSwitchCase="'MERW'">
            <ng-template
              #MERW
              [ngTemplateOutlet]="MERW"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="MERWTooltip"
                      #MERWTrigger="matMenuTrigger"
                      (mouseenter)="MERWTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #MERWTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="MERWTrigger.closeMenu()">
                        {{ "addOn.MERWTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                </mat-checkbox>
                <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                  <section class="box-selection mv-sm">
                    <mat-radio-group
                      class="b2c-radio fgap-radio-btn"
                      formControlName="merw"
                    >
                      <mat-radio-button
                        [value]="getPrice(addOn)"
                        selected
                        [checked]="true"
                      >
                        <div fxLayout="row" fxLayoutAlign="space-between ">
                          <div>
                            <div class="value-desc">Plan A</div>
                          </div>
                          <div class="mdTitle-f">
                            <span>RM </span>
                            {{ getPrice(addOn, "0") | number : "1.2-2" || "-" }}
                          </div>
                        </div>
                      </mat-radio-button>
                      <!-- For future Plan B -->
                      <!-- <mat-radio-button
												[value]="getPrice(addOn, '1')"
												(change)="fgapAddOnsChanged('1')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan B</div>
													</div>

													<div class="mdTitle-f">
														<span>RM </span
														>{{
															getPrice(addOn, "1") | number : "1.2-2" || "-"
														}}
													</div>
												</div>
											</mat-radio-button> -->
                      <!-- For future Plan C -->
                      <!-- <mat-radio-button
												[value]="getPrice(addOn, '2')"
												(change)="fgapAddOnsChanged('2')"
											>
												<div fxLayout="row" fxLayoutAlign="space-between ">
													<div>
														<div class="value-desc">Plan C</div>
													</div>
													<div class="mdTitle-f">
														<span>RM </span>
														{{ getPrice(addOn, "2") | number : "1.2-2" || "-" }}
													</div>
												</div>
											</mat-radio-button> -->
                    </mat-radio-group>
                  </section>
                </div>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'VBET'">
            <mat-card
              class="addon-card"
              [class.selected]="hasSelectedCategory('VBET')"
              *ngIf="addOn"
            >
              <ng-container *ngIf="addOnList.length === 1">
                <ng-template
                  #VBET
                  [ngTemplateOutlet]="VBET"
                  let-addOn="addOn"
                  [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
                >
                  <mat-checkbox
                    [value]="addOn"
                    (change)="toggleAddOn($event)"
                    [checked]="isAddOnSelected(addOn)"
                  >
                    <div fxLayout="row">
                      <div fxFlex class="title">
                        {{ "addOn." + addOn?.code | translate }}
                      </div>
                      <div class="value">
                        <span>RM</span>
                        {{ getPrice(addOn) | number : "1.2-2" }}
                      </div>
                      <mat-icon
                        class="tooltip-icon"
                        [matMenuTriggerFor]="VBETTooltip"
                        #VBETTrigger="matMenuTrigger"
                        (mouseenter)="VBETTrigger.openMenu()"
                      >
                        info</mat-icon
                      >
                      <mat-menu
                        #VBETTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover"
                      >
                        <span (mouseleave)="VBETTrigger.closeMenu()">
                          {{ "addOn.VBETTooltip" | translate }}
                        </span>
                      </mat-menu>
                    </div>
                  </mat-checkbox>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="addOnList.length !== 1">
                <ng-template
                  #VBET
                  [ngTemplateOutlet]="VBET"
                  let-addOn="addOn"
                  [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
                >
                  <mat-checkbox
                    [(ngModel)]="isVBETChecked"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="addOn"
                    (change)="toggleAddOn($event)"
                    [checked]="isAddOnSelected(addOn)"
                  >
                    <div fxLayout="row">
                      <div fxFlex class="title">
                        {{ "addOn.VBET" | translate }}
                      </div>
                      <mat-icon
                        class="tooltip-icon"
                        [matMenuTriggerFor]="VBETTooltip"
                        #VBETTrigger="matMenuTrigger"
                        (mouseenter)="VBETTrigger.openMenu()"
                      >
                        info
                      </mat-icon>
                      <mat-menu
                        #VBETTooltip="matMenu"
                        [overlapTrigger]="false"
                        class="tooltip-popover"
                      >
                        <span (mouseleave)="VBETTrigger.closeMenu()">
                          {{ "addOn.VBETTooltip" | translate }}
                        </span>
                      </mat-menu>
                    </div>
                  </mat-checkbox>
                </ng-template>
              </ng-container>
            </mat-card>
          </ng-container>

          <ng-container *ngSwitchCase="'BISP'">
            <ng-template
              #BISP
              [ngTemplateOutlet]="BISP"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="BISPTooltip"
                      #BISPTrigger="matMenuTrigger"
                      (mouseenter)="BISPTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #BISPTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="BISPTrigger.closeMenu()">
                        {{ "addOn.BISPTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <ul>
                      <li>
                        {{
                          "addOn.BISPDesc"
                            | translate
                              : {
                                  sumInsured:
                                    selectedQuotation?.sumInsured
                                    | number : "1.2-2"
                                }
                        }}
                      </li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'MPAPlus'">
            <ng-template
              #MPAPlus
              [ngTemplateOutlet]="MPAPlus"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="MPAPlusTooltip"
                      #MPAPlusTrigger="matMenuTrigger"
                      (mouseenter)="MPAPlusTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #MPAPlusTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="MPAPlusTrigger.closeMenu()">
                        {{ "addOn.MPAPlusTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <ul>
                      <li>
                        {{
                          "addOn.MPAPlusDesc"
                            | translate
                              : {
                                  sumInsured:
                                    selectedQuotation?.sumInsured
                                    | number : "1.2-2"
                                }
                        }}
                      </li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="'PA'">
            <ng-template
              #PAPlan
              [ngTemplateOutlet]="PAPlan"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div class="title" fxFlex>
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <div class="value">
                      <span>RM</span>
                      {{ getPrice(addOn) | number : "1.2-2" }}
                    </div>
                    <mat-icon
                      class="tooltip-icon"
                      [matMenuTriggerFor]="PAPlanTooltip"
                      #PAPlanTrigger="matMenuTrigger"
                      (mouseenter)="PAPlanTrigger.openMenu()"
                    >
                      info
                    </mat-icon>
                    <mat-menu
                      #PAPlanTooltip="matMenu"
                      [overlapTrigger]="false"
                      class="tooltip-popover"
                    >
                      <span (mouseleave)="PAPlanTrigger.closeMenu()">
                        {{ "addOn.PAPlanTooltip" | translate }}
                      </span>
                    </mat-menu>
                  </div>
                  <div class="coverage-desc" *ngIf="isAddOnSelected(addOn)">
                    {{ "addOn.coverage" | translate }}:
                    <ul>
                      <li>
                        {{
                          "addOn.PAPlanDesc"
                            | translate
                              : {
                                  sumInsured:
                                    selectedQuotation?.sumInsured
                                    | number : "1.2-2"
                                }
                        }}
                      </li>
                    </ul>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isChubbInstantQuotation" [ngSwitch]="category">
          <ng-container *ngSwitchCase="'Windshield'">
            <ng-template
              #Windshield
              [ngTemplateOutlet]="Windshield"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row">
                    <div fxFlex class="title">
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                  </div>
                </mat-checkbox>

                <ng-container *ngIf="isAddOnSelected(addOn)">
                  <div class="slider-desc">
                    {{ "addOn.coverage" | translate }} :
                  </div>
                  <app-range-slider
                    [max]="getMinMaxWindscreenPrice(addOn, 'max')"
                    [min]="getMinMaxWindscreenPrice(addOn, 'min')"
                    [step]="50"
                    [variantSmall]="true"
                    formControlName="windshield"
                    [maxError]="
                      'addOn.maxWindscreenError'
                        | translate
                          : {
                              amount:
                                getMinMaxWindscreenPrice(addOn, 'max')
                                | currency : 'RM' : 'symbol' : '1.2-2'
                            }
                    "
                    [minError]="
                      'addOn.minWindscreenError'
                        | translate
                          : {
                              amount:
                                getMinMaxWindscreenPrice(addOn, 'min')
                                | currency : 'RM' : 'symbol' : '1.2-2'
                            }
                    "
                  ></app-range-slider>
                </ng-container>
              </mat-card>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-template
              #chubbAddOn
              [ngTemplateOutlet]="chubbAddOn"
              let-addOn="addOn"
              [ngTemplateOutletContext]="{ addOn: addOnList[0] }"
            >
              <mat-card
                class="addon-card"
                [class.selected]="isAddOnSelected(addOn)"
                *ngIf="addOn"
              >
                <mat-checkbox
                  [value]="addOn"
                  (change)="toggleAddOn($event)"
                  [checked]="isAddOnSelected(addOn)"
                >
                  <div fxLayout="row wrap">
                    <div class="title" fxFlex="70">
                      {{ "addOn." + addOn?.code | translate }}
                    </div>
                    <span fxFlex></span>
                  </div>
                </mat-checkbox>
              </mat-card>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <!-- Add-ons end -->
  </form>
</ng-template>

<ng-template #personalInfoFrom>
  <form [formGroup]="infoFrmGroup">
    <div class="section-container">
      <div class="title-sec">
        <div class="title-f">{{ "common.personalDetails" | translate }}</div>
        <div class="divider"></div>
      </div>

      <!-- ## Affiliate Exclusive (personal details text)  -->
      <div class="content-wrapper" *ngIf="partnerCode !== 'ps'">
        <ng-container *ngTemplateOutlet="personalDetail"> </ng-container>
      </div>
      <!-- ## End Affiliate Exclusive (personal details text)  -->

      <div class="form-field mt-sm">
        <mat-form-field appearance="outline">
          <mat-label>{{ getNameLabel() | translate }}</mat-label>
          <input matInput autocomplete="name" formControlName="name" />
          <mat-error>
            {{ getNameLabel() | translate }}
            {{ infoFrmGroup.controls?.name?.errors | inputError | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ "common.residentialPostcode" | translate }}</mat-label>
          <input matInput formControlName="postcode" [disabled]="true" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ "common.occupation" | translate }}</mat-label>
          <input matInput autocomplete="off" formControlName="occupation" />
          <mat-error>
            {{ "common.occupation" | translate }}
            {{
              infoFrmGroup.controls?.occupation?.errors | inputError | translate
            }}
          </mat-error>
        </mat-form-field>

        <section class="box-selection" *ngIf="requestData.maritalStatus">
          <mat-label>{{ "common.maritalStatus" | translate }}:</mat-label>
          <mat-radio-group
            class="b2c-radio disabled"
            [value]="requestData.maritalStatus"
            [disabled]="true"
          >
            <mat-radio-button value="Single"
              >{{ "common.Single" | translate }}
            </mat-radio-button>
            <mat-radio-button value="Married"
              >{{ "common.Married" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </section>

        <mat-form-field appearance="outline">
          <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
          <ngx-mat-intl-tel-input
            [enableSearch]="true"
            [preferredCountries]="['my']"
            formControlName="mobileNo"
          >
          </ngx-mat-intl-tel-input>
          <mat-error>
            {{ "common.mobileNo" | translate }}
            {{
              infoFrmGroup.controls?.mobileNo?.errors | inputError | translate
            }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ "common.email" | translate }}</mat-label>
          <input matInput formControlName="email" autocomplete="email" />
          <mat-error
            style="display: block !important"
            [hidden]="!infoFrmGroup.controls.email?.errors?.email"
          >
            {{ "error.invalidEmail" | translate }}</mat-error
          >
          <mat-error
            >{{ "common.email" | translate }}
            {{
              infoFrmGroup.controls?.email?.errors | inputError | translate
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="section-container">
      <div class="title-sec">
        <div class="title-f">{{ "common.residentialAddress" | translate }}</div>
        <div class="divider"></div>
      </div>
      <div class="form-field">
        <mat-form-field appearance="outline">
          <mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
          <input matInput formControlName="address" />
          <mat-error>
            {{ "common.addressLineOne" | translate }}
            {{
              infoFrmGroup.controls?.address?.errors | inputError | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
          <input matInput formControlName="address2" />
          <mat-error>
            {{ "common.addressLineTwo" | translate }}
            {{
              infoFrmGroup.controls?.address2?.errors | inputError | translate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "common.city" | translate }}</mat-label>
          <input matInput formControlName="city" />
          <mat-error>
            {{ "common.city" | translate }}
            {{ infoFrmGroup.controls?.city?.errors | inputError | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="left">
          <mat-label>{{ "common.postcode" | translate }}</mat-label>
          <input matInput formControlName="postcode" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="right">
          <mat-label>{{ "common.state" | translate }}</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let item of stateList" [value]="item">{{
              item
            }}</mat-option>
          </mat-select>
          <mat-error>
            {{ "common.state" | translate }}
            {{ infoFrmGroup.controls?.state?.errors | inputError | translate }}
          </mat-error>
        </mat-form-field>
        <section
          *ngIf="
            addOnFrmGroup.value?.isIncludeRoadtax && !isIncludeDigitalRoadtax
          "
        >
          <mat-checkbox (change)="differentAddressIsChecked($event)">
            <!-- [checked]="isSameAddressChecked()" -->
            {{ "common.differentAddress" | translate }}
          </mat-checkbox>
        </section>
      </div>
    </div>
    <div
      class="section-container"
      *ngIf="showMileagePlans && stepper?.selectedIndex === 2"
    >
      <div class="title-sec">
        <div class="title-f">{{ "Car's Mileage" | translate }}</div>
        <div class="divider"></div>
      </div>
      <div class="car-mileage">
        <mat-form-field appearance="outline">
          <mat-label>{{ "Current car's mileage" | translate }}</mat-label>
          <input
            matInput
            mask="separator.0"
            formControlName="mileage"
            thousandSeparator=","
          />
        </mat-form-field>
        <div class="car-mileage__suffix">KM</div>
      </div>
    </div>
    <div
      class="section-container"
      *ngIf="showMileagePlans && stepper?.selectedIndex === 2"
    >
      <div class="title-sec">
        <div class="title-f">{{ "common.upload" | translate }}</div>
        <div class="divider"></div>
      </div>
      <app-upload-mileage formControlName="mileagePhoto"></app-upload-mileage>
    </div>
    <ng-container>
      <div
        class="section-container"
        *ngIf="addOnFrmGroup.value?.isIncludeRoadtax && isDifferentAddressCheck"
      >
        <div class="title-sec">
          <div class="title-f">{{ "common.deliveryAddress" | translate }}</div>
          <div class="divider"></div>
        </div>
        <div class="form-field">
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.addressLineOne" | translate }}</mat-label>
            <input matInput formControlName="deliveryAddress" />
            <mat-error>
              {{ "common.addressLineOne" | translate }}
              {{
                infoFrmGroup.controls?.deliveryAddress?.errors
                  | inputError
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.addressLineTwo" | translate }}</mat-label>
            <input matInput formControlName="deliveryAddress2" />
            <mat-error>
              {{ "common.addressLineTwo" | translate }}
              {{
                infoFrmGroup.controls?.deliveryAddress2?.errors
                  | inputError
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ "common.city" | translate }}</mat-label>
            <input matInput formControlName="deliveryCity" />
            <mat-error>
              {{ "common.city" | translate }}
              {{
                infoFrmGroup.controls?.deliveryCity?.errors
                  | inputError
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="left">
            <mat-label>{{ "common.postcode" | translate }}</mat-label>
            <input matInput formControlName="deliveryPostcode" mask="00000" />
            <mat-error>
              {{ "common.postcode" | translate }}
              {{
                infoFrmGroup.controls?.deliveryPostcode?.errors
                  | inputError
                  | translate
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="right">
            <mat-label>{{ "common.state" | translate }}</mat-label>
            <mat-select formControlName="deliveryState">
              <mat-option *ngFor="let item of stateList" [value]="item">{{
                item
              }}</mat-option>
            </mat-select>
            <mat-error>
              {{ "common.state" | translate }}
              {{
                infoFrmGroup.controls?.deliveryState?.errors
                  | inputError
                  | translate
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<ng-template #mobileNextBtn>
  <div class="title">
    {{ "common.proceed" | translate }}
  </div>
  <div>
    <div class="value">RM {{ getTotal() | number : "1.2-2" }}</div>
    <!-- ## SPLIT TAG -->
    <!-- <div class="desc">
			{{ "common.pay" | translate }} RM {{ getTotal() / 3 | number : "1.2-2" }}
			{{ "common.everyMonth" | translate }} {{ "common.duration" | translate }}!
		</div> -->
    <!-- ## END SPLIT TAG -->
  </div>
</ng-template>

<ng-template #psMobileNextBtn>
  <div class="btn-content">
    <div class="btn-content__total-txt">
      {{ "common.totalAmount" | translate }}
    </div>
    <div class="btn-content__price">
      <div class="btn-content__price__txt">
        RM {{ getTotal() | number : "1.2-2" }}
      </div>
      <div
        class="btn-content__price__desc"
        [innerHTML]="getPaymentDurationLabel()"
      ></div>
    </div>
  </div>
</ng-template>

<ng-template #orderSummarySnippet>
  <div class="order-summary-container">
    <div class="total-amt">
      <div class="title">{{ "common.totalAmount" | translate }}</div>
      <div class="value">RM {{ getTotal() | number : "1.2-2" }}</div>
    </div>
    <div
      class="order-summary-container__note"
      *ngIf="getPaymentDurationLabel()"
      [innerHTML]="getPaymentDurationLabel()"
    ></div>
    <div class="divider"></div>
  </div>
</ng-template>

<ng-template #paymentOption>
  <div class="pt-xs" (click)="$event.stopPropagation()">
    <app-section-title
      title="plans.step3Content.paymentOption"
    ></app-section-title>
  </div>
  <app-payment-options
    *ngIf="stepper?.selectedIndex == 3"
    [totalPrice]="getTotal()"
    [totalPriceBeforeCharges]="getFinalTotalBeforeCharges()"
    [bankCharge]="bankInstallmentCharge"
    [paymentFormGroup]="paymentFormGroup"
    [eppChargeList]="eppChargeList"
  ></app-payment-options>
</ng-template>

<ng-template #psPaymentOption>
  <div class="section-container mt-md">
    <div class="title-sec">
      <div class="mdTitle-f">{{ "common.paymentOption" | translate }}</div>
      <div class="divider"></div>
    </div>
    <div fxLayout="row" class="ps-payment-option">
      <div
        class="card mr-sm"
        [ngClass]="{ active: selectedPayment === 'Razer' }"
        (click)="selectedPayment = 'Razer'"
      >
        <div>{{ "common.fullPrice" | translate }}</div>
        <div class="mdTitle-f">RM {{ getTotal() | number : "1.2-2" }}</div>
      </div>
      <div
        class="card"
        [ngClass]="{ active: selectedPayment === 'Shopback' }"
        (click)="selectedPayment = 'Shopback'"
      >
        <div>{{ "common.instalmentDuration" | translate }}</div>
        <div class="mdTitle-f">RM {{ getTotal() / 3 | number : "1.2-2" }}</div>
        <div style="padding: 0px; margin: 0px">
          {{ "common.perMonth" | translate }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiCheckbox let-form="form">
  <div class="multi-checkbox-section" [formGroup]="form">
    <div class="multi-checkbox-section__bg">
      <section className="allow-pointer">
        <mat-checkbox
          formControlName="agreeInfo"
          (click)="$event.stopPropagation()"
          >{{ "common.confirmationOne" | translate }}
        </mat-checkbox>
      </section>

      <section formArrayName="doc" className="allow-pointer">
        <ng-container
          *ngFor="let item of getAgreeFormArray()?.controls; let i = index"
        >
          <ng-container [formGroupName]="i">
            <mat-checkbox
              formControlName="checked"
              (click)="$event.stopPropagation()"
              >{{ "common.confirmationTwo" | translate
              }}<a
                [href]="getAgreeFormArray()?.controls[i].value?.url"
                target="blank"
              >
                {{ getAgreeFormArray()?.controls[i].value?.name }}</a
              ><b> {{ selectedQuotation?.name }}</b
              >.
            </mat-checkbox>
          </ng-container>
        </ng-container>
      </section>

      <section
        className="allow-pointer"
        *ngIf="partnerCode === 'ktmb' && selectedQuotation"
      >
        <mat-checkbox
          formControlName="acknowledge"
          (click)="$event.stopPropagation()"
          >{{ "common.confirmationThreeStart" | translate }}
          <a [href]="getAgreementLinks().pds" target="blank" translate
            >Product Disclosure Sheet</a
          >
          {{ "common.and" | translate }}
          <a [href]="getAgreementLinks().faq" target="blank" translate>FAQ</a>
          {{ "common.confirmationThreeEnd" | translate }}
        </mat-checkbox>
      </section>
    </div>
    <button
      mat-flat-button
      [disabled]="agreementFrmGroup.invalid || paymentFormGroup?.invalid"
      (click)="$event.stopPropagation(); submit()"
      className="allow-pointer"
      *ngIf="isTotalNotNegative; else disabledBtnTemplate"
    >
      {{ "plans.readyToPay" | translate }}
    </button>
    <ng-template #disabledBtnTemplate>
      <div
        matTooltip="Please contact PolicyStreet to proceed"
        #tooltip="matTooltip"
        (click)="$event.stopPropagation(); tooltip.toggle()"
      >
        <button mat-flat-button [disabled]="true" class="disabled-btn">
          {{ "plans.readyToPay" | translate }}
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>

<div class="view-dialog">
    <div class="view-dialog__header">
        <p class="view-dialog__header-title">{{ data.title }}</p>
        <app-psxtra-tag></app-psxtra-tag>
    </div>

    <div class="view-dialog__body">
        <img [src]="brochure + '.png'" alt="" class="view-dialog__body-brochure view-dialog--desktop"/>
        <img [src]="brochureMobile + '.png'" alt="" class="view-dialog__body-brochure view-dialog--mobile" />
    </div>
    
    <div class="view-dialog__footer">
        <div class="view-dialog__footer-container">
            <button class="view-dialog--desktop view-dialog__footer-btn view-dialog__footer-btn--filled" mat-button (click)="onDownload(brochure + '.pdf')">
                {{ "common.download" | translate }}
            </button>
            <button class="view-dialog--mobile view-dialog__footer-btn view-dialog__footer-btn--filled" mat-button (click)="onDownload(brochureMobile + '.pdf')">
                {{ "common.download" | translate }}
            </button>
            <button class="view-dialog__footer-btn view-dialog__footer-btn--outlined" mat-stroked-button mat-dialog-close>
                {{ "dialog.close" | translate }}
            </button>
        </div>
    </div>
</div>
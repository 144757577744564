import { PartnerInfoListInterface } from "../interface/partner.interface";

export const PARTNER_INFO: PartnerInfoListInterface = {
	carsome: {
		isDisplay: true,
		partner: "carsome",
		company: "Carsome",
		email: { value: "capital.insurance@carsome.my", isDisplay: true },
		mobile: { value: "", isDisplay: false },
		address: {
			value:
				"Level 9, KYM Tower, No. 8, Jalan PJU 7/6, Mutiara Damansara, 47800 Petaling Jaya, Selangor",
			isDisplay: false,
		},
		copyright: { value: "Carsome Sdn Bhd (201401025864)", isDisplay: false },
	},
	wapcar: {
		isDisplay: true,
		partner: "carsome",
		company: "Carsome",
		email: { value: "capital.insurance@carsome.my", isDisplay: true },
		mobile: { value: "", isDisplay: false },
		address: {
			value:
				"Level 9, KYM Tower, No. 8, Jalan PJU 7/6, Mutiara Damansara, 47800 Petaling Jaya, Selangor",
			isDisplay: false,
		},
		copyright: { value: "Carsome Sdn Bhd (201401025864)", isDisplay: false },
	},
	carlist: {
		isDisplay: true,
		partner: "carsome",
		company: "Carsome",
		email: { value: "capital.insurance@carsome.my", isDisplay: true },
		mobile: { value: "", isDisplay: false },
		address: {
			value:
				"Level 9, KYM Tower, No. 8, Jalan PJU 7/6, Mutiara Damansara, 47800 Petaling Jaya, Selangor",
			isDisplay: false,
		},
		copyright: { value: "Carsome Sdn Bhd (201401025864)", isDisplay: false },
	},
  ktmb: {
    isDisplay: true,
    partner: "ktmb",
    company: "KTMB",
    email: { value: "customerservice@policystreet.com", isDisplay: true },
    mobile: { value: "+6018-2822320", isDisplay: true },
    address: {
      value:
        "Block D, Platinum Sentral, Jalan Stesen Sentral 2, Level 3-5, Kuala Lumpur, 50470, Malaysia",
      isDisplay: true,
    },
    copyright: { value: "", isDisplay: false },
  }
};

// Angular Core
import {
  Component,
  HostListener,
  ViewChild,
  OnInit,
  OnDestroy
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  Validators
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Overlay } from "@angular/cdk/overlay";
// Services
import {
  CheckDuplicateRequest,
  CheckDuplicateResponse,
  MotorQuotationDataService,
  SubmitCustomerInfoRequest
} from "../../../share/data-service/motor/motor-quotation.data.service";
import { AlertService } from "../../../share/service/alert.service";
import { MasterDataDataService } from "../../../share/data-service/master-data.data.service";
import { PartnerService } from "src/app/share/service/partner.service";
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import { MotorQuotationInterface } from "../../../share/interface/motor-quotation.interface";
// Components
import { BeforeExpiredDialogComponent } from "../../../dashboard/before-expired-dialog/before-expired-dialog.component";
import { RemindMeDialogComponent } from "../../ui/remind-me-dialog/remind-me-dialog.component";

// Utilities
import * as moment from "moment";
import SwiperCore, {
  Pagination,
  Autoplay,
  Navigation,
  SwiperOptions
} from "swiper";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ValidatorFunction } from "../../../share/function/validator.function";
import {
  GENDER_TYPE,
  ID_TYPE,
  MARITAL_STATUS
} from "src/app/share/constants/common.types";
import clevertap from "clevertap-web-sdk";
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { lastValueFrom, of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import {
  IAvailableBanners,
  IAvailableSalesCampaign
} from "src/app/share/interface/motor.interface";
import {
  D2C_BANNER,
  D2C_MOBILE_BANNER,
  DEFAULT_CTA_BANNER
} from "src/app/share/constants/banner";
import { RequestStatusDialogComponent } from "../../ui/request-status-dialog/request-status-dialog.component";
import { SwiperComponent } from "swiper/angular";
import { FileService } from "src/app/share/service/file.service";
import { DropdownItem } from "src/app/share/interface/common.interface";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit, OnDestroy {
  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("swiper", { static: true }) swiper: SwiperComponent;
  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    grabCursor: true,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    },
    pagination: {
      clickable: true
    }
  };

  partnerCode: string;

  @ViewChild("vehicleForm") vehicleForm: NgForm;
  @ViewChild("custForm") custForm: NgForm;
  vehicleFormGroup: FormGroup;
  custFormGroup: FormGroup;

  idTypeOption = ID_TYPE;
  maritalStatusOption = MARITAL_STATUS;
  genderOption = GENDER_TYPE;

  countryList: string[] = [];
  filterCountryList: string[] = [];
  availableSalesCampaign: IAvailableSalesCampaign[] = [];
  availableBanners: IAvailableBanners[] = [];

  isCompany: boolean = false;
  isMalaysian: boolean = true;
  pdpaAgreement: boolean = false;
  isLoading: boolean = false;
  showSuccessGif: boolean = false;
  mobileView: boolean = false;
  quotationSubmitted: boolean = false;
  quotationInputData: MotorQuotationInterface;
  quotationFormData: any;

  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  maxDob: Date = new Date();
  contactInfo: string;
  successGifType: string;
  validPostcodes: string[] = [];

  // banner images
  banners = [
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-en.png",
      show: true,
      alt: "car-insurance-flood-protection"
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-en.png",
      show: true,
      alt: "car-insurance-female-protection"
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-en.png",
      show: true,
      alt: "car-insurance-family-protection"
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-en.png",
      show: true,
      alt: "car-free-digital-road-tax"
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-en.png",
      show: true,
      alt: "car-insurance-shopback-paylater"
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-en.png",
      show: true,
      alt: ""
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-bm.png",
      show: true,
      alt: "car-insurance-flood-protection"
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-bm.png",
      show: true,
      alt: "car-insurance-female-protection"
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-bm.png",
      show: true,
      alt: "car-insurance-family-protection"
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-bm.png",
      show: true,
      alt: "car-free-digital-road-tax"
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-bm.png",
      show: true,
      alt: "car-insurance-shopback-paylater"
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-bm.png",
      show: true,
      alt: ""
    }
  ];

  customBanner = {
    mainBanner: D2C_BANNER,
    mobileMainBanner: D2C_MOBILE_BANNER,
    ctaBanner: DEFAULT_CTA_BANNER,
    ctaUrl: "https://rebrand.ly/harga-insurans-lebih-rendah-policystreet"
  };

  idTypeList: OptionData[] = [
    { id: 1, name: "common.malaysian" },
    { id: 2, name: "common.company" },
    { id: 3, name: "common.foreigner" },
    { id: 4, name: "common.Police/Army" },
    { id: 5, name: "common.commercialInd" },
    { id: 6, name: "common.commercialComp" }
  ];

  insuranceTypeList: DropdownItem[] = [
    { id: "TPFT", description: "common.TPFT" },
    { id: "COM", description: "common.COM" }
  ];

  showWhatsappBtn: Boolean = true;
  hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist"];
  isOfficeHour: boolean;

  uspList = [
    {
      id: 1,
      icon: "/assets/icons/landing/usp1.svg",
      title: "dashboard.sellingPoint1",
      desc: "dashboard.sellingPoint1Desc"
    },
    {
      id: 2,
      icon: "/assets/icons/landing/usp2.svg",
      title: "dashboard.sellingPoint2",
      desc: "dashboard.sellingPoint2Desc"
    },
    {
      id: 3,
      icon: "/assets/icons/landing/usp3.svg",
      title: "dashboard.sellingPoint3",
      desc: "dashboard.sellingPoint3Desc"
    },
    {
      id: 4,
      icon: "/assets/icons/landing/usp4.svg",
      title: "dashboard.sellingPoint4",
      desc: "dashboard.sellingPoint4Desc"
    }
  ];

  carGrant: any;
  acceptFile: string = ".pdf,.png,.jpg,.jpeg";
  maxLength: number = 1; // Maximum number of files
  fileSize: number = 10; // Maximum file size
  fileList: File[] | any = [];

  subscriptions: Subscription[] = [];

  constructor(
    gtmService: GoogleTagManagerService,
    public overlay: Overlay,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    private partnerService: PartnerService,
    private masterDataDataService: MasterDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private motorDataService: MotorDataDataService,
    private fileService: FileService
  ) {
    gtmService.pushTag({
      event: "car_lp"
    });
    this.getParams();
    this.initForm();
    this.listenChanges();
  }

  ngOnInit() {
    if (window.screen.width < 960) {
      this.mobileView = true;
    }

    this.masterDataDataService.selectAllPostcodes().subscribe({
      next: (x) => {
        this.validPostcodes = x;
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      }
    });

    this.checkIfOfficeHour();
    this.getAvailableSalesCampaign();
    this.getAvailableBanners();
  }

  updateResetFlag(value: boolean): void {
    if (!value) return;

    this.vehicleFormGroup
      .get("insuranceType")
      .patchValue(this.insuranceTypeList[0].id);

    this.quotationSubmitted = false;
    this.isLoading = false;
  }

  private async getParams(): Promise<void> {
    let snapshotParam = this.activatedRoute.snapshot.queryParams;
    let url = window.location.href;
    let lang: string = snapshotParam.lang;
    this.partnerCode = this.partnerService.getPartnerCode();

    if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
      this.showWhatsappBtn = false;
    }

    !this.partnerCode ? (this.partnerCode = "ps") : null;

    if (lang) this.translate.use(lang);
    this.utmSource = snapshotParam.utm_source;
    this.utmMedium = snapshotParam.utm_medium;
    this.utmCampaign = snapshotParam.utm_campaign;
    this.utmContent = snapshotParam.utm_content;

    if (!this.utmSource) {
      let searchEngineList = ["google", "yahoo", "bing"];
      let referrer = document.referrer;
      if (
        referrer != window.location.href ||
        referrer.includes("policystreet.com")
      ) {
        let searchEngine = searchEngineList.find((x) =>
          referrer.toLowerCase().includes(x)
        );
        if (searchEngine) {
          this.utmSource = searchEngine;
          this.utmMedium = "organic";
        } else {
          if (!referrer) this.utmSource = "direct";
          else {
            let url = new URL(referrer);
            this.utmSource = url.hostname;
            this.utmMedium = "referral";
          }
        }
      } else {
        this.utmSource = "direct";
      }
    }
  }

  private initForm(): void {
    this.vehicleFormGroup = this.formBuilder.group({
      insuranceType: ["COM", Validators.required],
      carPlate: [null, Validators.required],
      mobileNo: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      insuredType: ["MV", Validators.required],
      includeRoadTax: [false],
      psIdentityType: [this.idTypeList[0].id],
      name: [null, Validators.required],
      psIdentityNo: [
        null,
        [Validators.required, ValidatorFunction.nricValidator()]
      ],
      psPolicIdentityNo: [null],
      psPostCode: [
        null,
        [
          Validators.required,
          (control: FormControl) => this.postcodeValidator(control)
        ]
      ],
      nationality: [null],
      dob: [null],
      gender: [null]
    });

    this.custFormGroup = this.formBuilder.group({
      eHailingUsed: [false],
      maritalStatus: ["Single", Validators.required],
      contactMethod: ["Email"],
      vehicleGrant: [null]
    });
  }

  listenChanges(): void {
    this.subscriptions.push(
      this.vehicleFormGroup
        .get("psIdentityNo")
        .valueChanges.subscribe((value) => {
          if (this.vehicleFormGroup.get("psIdentityNo").invalid) {
            ["dob", "gender"].forEach((key) => {
              this.vehicleFormGroup.get(key).reset();
            });
          }

          if (
            this.vehicleFormGroup.get("psIdentityNo").valid &&
            this.isMalaysian &&
            !this.isCompany
          ) {
            let date = moment(value.substring(0, 6), "YYMMDD");
            let lastChar = value.substring(11);
            let gender = lastChar % 2 ? "M" : "F";

            if (moment().isBefore(date)) {
              date.subtract(100, "year");
            }

            this.vehicleFormGroup.patchValue({
              dob: date.toDate(),
              gender: gender
            });
          }
        }),

      this.vehicleFormGroup
        .get("psIdentityType")
        .valueChanges.subscribe((value) => {
          switch (value) {
            case 1:
            case 5:
              this.setPSMalaysian();
              break;

            case 2:
            case 6:
              this.setPSIsCompany();
              break;

            case 3:
              this.setPSNonMalaysian();
              break;

            case 4:
              this.setPSPoliceArmy();
              break;
          }

          ["dob", "gender", "psIdentityNo", "nationality"].forEach((key) => {
            this.vehicleFormGroup.get(key).reset();
          });

          if ([1, 2, 3, 4].includes(value)) {
            this.carGrant = null;
          }

          if (value !== 1) {
            this.custFormGroup.get("eHailingUsed").patchValue(false);
          }
        }),

      this.vehicleFormGroup
        .get("insuranceType")
        .valueChanges.subscribe((value) => {
          if (value === "COM") return;
          this.custFormGroup.get("eHailingUsed").patchValue(false);
        })
    );
  }

  private setPSMalaysian(): void {
    this.isCompany = false;
    this.isMalaysian = true;

    ["psIdentityNo", "psPolicIdentityNo", "nationality"].forEach((key) => {
      if (key === "psIdentityNo") {
        this.vehicleFormGroup
          .get(key)
          .setValidators([
            Validators.required,
            ValidatorFunction.nricValidator()
          ]);
      } else {
        this.vehicleFormGroup.get(key).clearValidators();
      }

      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });

    this.setNonCompanyValidation();
  }

  private setPSNonMalaysian(): void {
    this.isCompany = false;
    this.isMalaysian = false;

    this.getCountryList();

    ["psIdentityNo", "psPolicIdentityNo", "nationality"].forEach((key) => {
      if (key === "psPolicIdentityNo") {
        this.vehicleFormGroup.get(key).clearValidators();
      } else {
        this.vehicleFormGroup.get(key).setValidators(Validators.required);
      }
      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });

    this.setNonCompanyValidation();
  }

  private setPSPoliceArmy(): void {
    this.isCompany = false;
    this.isMalaysian = true;

    ["psIdentityNo", "psPolicIdentityNo", "nationality"].forEach((key) => {
      switch (key) {
        case "psIdentityNo":
          this.vehicleFormGroup
            .get(key)
            .setValidators([
              Validators.required,
              ValidatorFunction.nricValidator()
            ]);
          break;

        case "psPolicIdentityNo":
          this.vehicleFormGroup.get(key).setValidators(Validators.required);
          break;

        default:
          this.vehicleFormGroup.get(key).clearValidators();
      }

      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });
  }

  private setPSIsCompany(): void {
    this.isCompany = true;
    this.isMalaysian = true;

    ["psIdentityNo", "psPolicIdentityNo", "name"].forEach((key) => {
      if (key === "psIdentityNo") {
        this.vehicleFormGroup.get(key).setValidators(Validators.required);
      } else {
        this.vehicleFormGroup.get(key).clearValidators();
      }

      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });

    this.setCompanyValidation();
  }

  private postcodeValidator(control: FormControl): ValidationErrors | null {
    if (control.value === null || control.value.length !== 5) return null;

    return this.validPostcodes.includes(control.value)
      ? null
      : { customError: true };
  }

  private setNonCompanyValidation(): void {
    ["dob", "gender"].forEach((key) => {
      this.vehicleFormGroup.get(key).setValidators(Validators.required);
      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });

    this.custFormGroup.get("maritalStatus").setValidators(Validators.required);
    this.custFormGroup.get("maritalStatus").updateValueAndValidity();
  }

  private setCompanyValidation(): void {
    ["dob", "gender", "nationality"].forEach((key) => {
      this.vehicleFormGroup.get(key).clearValidators();
      this.vehicleFormGroup.get(key).updateValueAndValidity();
    });

    this.custFormGroup.get("maritalStatus").clearValidators();
    this.custFormGroup.get("maritalStatus").updateValueAndValidity();
  }

  private getCountryList(): void {
    if (this.countryList.length != 0) {
      this.filterCountry();
      return;
    }

    this.masterDataDataService.getCountrySelection().subscribe({
      next: (x) => {
        const exclude = [
          "Iran",
          "Syria",
          "North Korea",
          "Sudan",
          "Crimea",
          "Venezuela",
          "Cuba",
          "Israel",
          "Russian Federation",
          "Ukraine"
        ];
        this.countryList = x.filter((country) => !exclude.includes(country));
        this.filterCountry();
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      }
    });
  }

  getIdentityNoLabel(): string {
    let identityLabel: string;

    if (this.isMalaysian && !this.isCompany) {
      identityLabel = "common.icNoLabel";
    } else if (!this.isMalaysian) {
      identityLabel = "common.passportLabel";
    } else {
      identityLabel = "common.businessRegistrationNoLabel";
    }

    return identityLabel;
  }

  getNameLabel(): string {
    let nameLabel: string;

    if (this.isMalaysian && !this.isCompany) {
      nameLabel = "common.fullNamePerICPassport";
    } else if (!this.isMalaysian) {
      nameLabel = "common.namePerPassport";
    } else {
      nameLabel = "common.companyName";
    }

    return nameLabel;
  }

  getIdentityNoMasking(): string {
    if (this.isMalaysian && !this.isCompany) return "000000000000";
    return "";
  }

  filterCountry(val: string = null): void {
    if (val) {
      let txt = val.trim().toLowerCase();
      this.filterCountryList = this.countryList.filter((x) =>
        x.trim().toLowerCase().startsWith(txt)
      );
    } else {
      this.filterCountryList = Object.assign([], this.countryList);
    }
  }

  // private updateCustomBanner(): void {
  //   let newBanner = [];
  //   const campaigns = this.availableSalesCampaign || [];

  //   // Helper to check if a campaign is active
  //   const isActive = (code: string) => campaigns.some((c) => c.code === code);

  //   // Default banners and CTA
  //   this.customBanner.mainBanner = [...D2C_BANNER];
  //   this.customBanner.mobileMainBanner = [...D2C_MOBILE_BANNER];
  //   this.customBanner.ctaBanner = [...DEFAULT_CTA_BANNER];

  //   if (!campaigns.length) return; // No campaigns, use defaults

  // Filter out hidden banners based on active campaigns
  // const filterBanners = (banners: any[]) =>
  //   banners.filter(
  //     (b) => !(b.hideIf && b.hideIf.some((code) => isActive(code)))
  //   );

  //   const filteredMainBanner = filterBanners(D2C_BANNER);
  //   const filteredMobileBanner = filterBanners(D2C_MOBILE_BANNER);

  // Handle roadtaxSept14 banner
  //   if (isActive(CAMPAIGN_TYPE.roadtaxSept14)) {
  //     newBanner.push(
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/14-sept-banner-en.gif",
  //         show: true,
  //         alt: ""
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/14-sept-banner-bm.gif",
  //         show: true,
  //         alt: ""
  //       }
  //     );
  //   }

  //   // Handle roadtaxSept19 banner
  //   if (isActive(CAMPAIGN_TYPE.roadtaxSept19)) {
  //     newBanner.push(
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-landing-banner-en.png",
  //         show: true,
  //         alt: ""
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-landing-banner-bm.png",
  //         show: true,
  //         alt: ""
  //       }
  //     );
  //     this.customBanner.ctaBanner = [
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-en.png",
  //         show: true,
  //         alt: ""
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-bm.png",
  //         show: true,
  //         alt: ""
  //       }
  //     ];
  //   }

  //   // Combine filtered banners with new ones
  //   this.customBanner.mainBanner = [...newBanner, ...filteredMainBanner];
  //   this.customBanner.mobileMainBanner = [
  //     ...newBanner,
  //     ...filteredMobileBanner
  //   ];
  // }

  private getAvailableBanners(): void {
    this.motorDataService.getAvailableBanners().subscribe({
      next: (x: IAvailableBanners[]) => {
        this.availableBanners = x.filter(
          (banner) => banner.status === "Active"
        );
      },
      error: () => {
        console.log("Failed to retrieve available campaigns.");
      }
    });
  }

  private getAvailableSalesCampaign(): void {
    this.motorDataService.getAvailableSalesCampaign().subscribe({
      next: (x: IAvailableSalesCampaign[]) => {
        this.availableSalesCampaign = x;
      },
      error: () => {
        console.log("Failed to retrieve available campaigns.");
      }
    });
  }

  async checkIfOfficeHour(): Promise<void> {
    const response: string = await lastValueFrom(
      this.motorDataService.checkIsOfficeHour()
    );
    this.isOfficeHour = response === "YES";
  }

  submitInsight(): void {
    const vehicleForm = this.vehicleFormGroup.getRawValue();

    let request: SubmitCustomerInfoRequest = {
      vehicleNo: vehicleForm.carPlate,
      postcode: vehicleForm.psPostCode,
      mobileNo: vehicleForm.mobileNo,
      email: vehicleForm.email,
      isMalaysian: this.isMalaysian,
      isCompany: this.isCompany,
      isCommercialIndividual: vehicleForm.psIdentityType === 5 ? true : false,
      isCommercialCompany: vehicleForm.psIdentityType === 6 ? true : false,
      name: vehicleForm.name,
      nationality: this.isMalaysian ? "Malaysian" : vehicleForm.nationality,
      dob: vehicleForm.dob,
      gender: vehicleForm.gender,
      identityNo: vehicleForm.psIdentityNo,
      policIdentityNo: vehicleForm.psPolicIdentityNo
    };

    let form: any = new FormData();
    for (const [key, value] of Object.entries(request) as [string, any][]) {
      if (value === null || value === undefined || value === "") continue;

      if (key === "dob") {
        form.append(key, value.toISOString());
      } else {
        form.append(key, value);
      }
    }

    window.scrollTo(0, 0);
    this.motorQuotationDataService.submitCustomerInfo(form).subscribe();
  }

  async submitQuotation(): Promise<void> {
    if (this.vehicleFormGroup.invalid || this.custFormGroup.invalid) return;

    let vehicleForm = this.vehicleFormGroup.getRawValue();
    let custForm = this.custFormGroup.getRawValue();

    this.successGifType = custForm.contactMethod;
    custForm.contactMethod == "Mobile"
      ? (this.contactInfo = vehicleForm.mobileNo)
      : (this.contactInfo = vehicleForm.email);

    let contactMethod =
      custForm.contactMethod == "Mobile"
        ? "Mobile (Whatsapp)"
        : custForm.contactMethod;

    let input: MotorQuotationInterface = {
      insuranceType: vehicleForm.insuranceType,
      vehicleNo: vehicleForm.carPlate,
      typeofSum: vehicleForm.insuredType,
      includeRoadTax: vehicleForm.includeRoadTax,
      eHailingUsed: custForm.eHailingUsed,
      isMalaysian: this.isMalaysian,
      isCompany: this.isCompany,
      identityNo: vehicleForm.psIdentityNo,
      postcode: vehicleForm.psPostCode,
      policIdentityNo: vehicleForm.psPolicIdentityNo,
      preferredChannel: contactMethod,
      name: vehicleForm.name,
      nationality: vehicleForm.nationality,
      dob: vehicleForm.dob,
      gender: vehicleForm.gender,
      maritalStatus: custForm.maritalStatus,
      mobileNo: vehicleForm.mobileNo,
      email: vehicleForm.email,
      language: this.translate.currentLang,
      utmSouce: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmContent: this.utmContent,
      isRenewal: false,
      motorLinkId: null,
      vehicleGrant: this.carGrant ?? null,
      isCommercialIndividual: vehicleForm.psIdentityType === 5 ? true : false,
      isCommercialCompany: vehicleForm.psIdentityType === 6 ? true : false
    };

    this.isLoading = true;

    let duplicateRequest: CheckDuplicateRequest = {
      vehicleNo: vehicleForm.carPlate,
      identityNo: vehicleForm.psIdentityNo,
      email: vehicleForm.email,
      mobileNo: vehicleForm.mobileNo,
      postcode: vehicleForm.psPostCode,
      eHailingUsed: custForm.eHailingUsed,
      preferredChannel: contactMethod
    };

    const isDuplicateQuote = await this.checkDuplicateQuote(duplicateRequest);
    if (isDuplicateQuote) return;

    let form: any = new FormData();
    for (const [key, value] of Object.entries(input) as [string, any][]) {
      if (value === null || value === undefined || value === "") continue;

      if (key === "dob") {
        form.append(key, value.toISOString());
      } else {
        form.append(key, value);
      }
    }

    this.quotationInputData = input;
    this.quotationFormData = form;
    this.quotationSubmitted = true;

    this.pushCleverTapLogin();
  }

  async checkDuplicateQuote(request: CheckDuplicateRequest): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.motorQuotationDataService.checkDuplicateRequest(request).subscribe({
        next: (x: CheckDuplicateResponse) => {
          if (x.isExist === "Y") {
            this.isLoading = false;
            this.dialog.open(RequestStatusDialogComponent, {
              panelClass: "no-padding-border-radius-dialog",
              data: {
                content: request.vehicleNo
              }
            });
            resolve(true);
          }

          resolve(false);
        },
        error: (_error: any) => {
          this.isLoading = false;
          resolve(true);
        }
      });
    });
  }

  async handleFileInput(e: any): Promise<void> {
    const files = (e.target as HTMLInputElement).files;
    const fileType = (e.target as HTMLInputElement).name;

    if (files.length === 0) return;

    for (let file of Array.from(files)) {
      const isUploadedFilesValid = await this.fileService.isUploadedFilesValid({
        file: file,
        files: files,
        currLength: this.fileList.length,
        maxLength: this.maxLength,
        accept: this.acceptFile,
        name: fileType,
        size: this.fileSize
      });

      if (!isUploadedFilesValid) {
        e.target.value = null;
        return;
      }

      this[fileType] = file;
    }

    e.target.value = null;
  }

  deleteFile(): void {
    this.carGrant = null;
  }

  whatsappNumber = 60182822320;
  entityName = "Policystreet";
  whatsappContent = "";
  backToHome() {
    window.location.href = "/";
  }
  whatsppRedirect(): void {
    const whatsappNumber = 60182822320;
    const entityName = "Policystreet";
    let whatsappContent = "";
    let lang = this.translate.currentLang == "en" ? "en" : "ms";
    whatsappContent =
      lang == "ms"
        ? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
        : `Hi ${entityName}, I would like to know more about road tax and car insurance`;

    let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappContent
    )}&source=&data=&app_absent=&lang=${lang}`;

    window.open(url, "_blank");
  }

  onHomepage(): void {
    this.contactInfo = "";
    this.successGifType = "";
    this.showSuccessGif = false;
  }

  // Function to disable all form controls in a given form group
  private disableFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).disable();
    });
  }

  // Function to enable all form controls in a given form group
  private enableFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).enable();
    });
  }

  pushCleverTapLogin() {
    //CleverTap analytics
    let vehicleValue = this.vehicleFormGroup.getRawValue();
    let custValue = this.custFormGroup.getRawValue();

    const identityNo =
      vehicleValue.psIdentityNo == null ? "NA" : vehicleValue.psIdentityNo;
    const partnerCode =
      this.partnerCode == null || this.partnerCode.trim().toUpperCase() == "PS"
        ? "D2C"
        : this.partnerCode.charAt(0).toUpperCase() +
          this.partnerCode.slice(1).toLowerCase();

    const identity =
      identityNo +
      "_" +
      "Car" +
      "_" +
      partnerCode +
      "_" +
      vehicleValue.carPlate +
      "_" +
      vehicleValue.mobileNo.replace("+", "");

    clevertap.onUserLogin.push({
      Site: {
        Identity: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        CT_PS_ID: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        Name: vehicleValue.name,
        Phone: vehicleValue.mobileNo,
        IdentityNo: vehicleValue.psIdentityNo,
        Gender: vehicleValue.gender,
        "Marital Status": custValue.maritalStatus,
        "Preferred Channel": custValue.contactMethod,
        "Quotation Link": null,
        "Address Line 1": null,
        "Address Line 2": null,
        Postcode: vehicleValue.psPostCode,
        State: null,
        Country: null,
        City: null,
        Email: vehicleValue.email,
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
        Origin: null,
        "Car Make": null,
        "Car Model": null,
        "Vehicle No": vehicleValue.carPlate,
        NCD: null,
        Expired_Date: null,
        "Quotation Date": null,
        "Renewed Date": null,
        "Quotation Status": null,
        "Include Roadtax": null,
        "Excess Type": null,
        "Effective Date": null,
        EhailingUsed: custValue.eHailingUsed,
        Package: null,
        "Add-Ons Added": null,
        "Add-ons Name": null,
        "Partner Name": null,
        "Total Revenue": null,
        "Type of Insurance": vehicleValue.insuranceType,
        "Insurer Name": null,
        "Payment Type": null,
        "Payment Status": null
      }
    });
  }

  private handleSuccessSubmitQuotation(
    expiryDate: string,
    customerInput: MotorQuotationInterface
  ): void {
    const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
      panelClass: "success-dialog",
      data: { expiryDate, customerInput }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.backToHome();
    });
  }

  private handleTooEarlySubmitQuotation(expiryDate: string): void {
    const dialogRef = this.dialog.open(BeforeExpiredDialogComponent, {
      data: { expiryDate }
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((resp) => {
          if (!resp) {
            return of(true);
          }

          return this.dialog
            .open(RemindMeDialogComponent, { panelClass: "success-dialog" })
            .afterClosed();
        })
      )
      .subscribe(() => this.backToHome());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.mobileView = width < 960;
  }
}

interface OptionData {
  id: string | number;
  name: string;
}

export const COMMON_INFO = {
  policystreet: {
    name: "PolicyStreet",
    regNo: "1212085-T",
    email: "customerservice@policystreet.com",
    whatsapp: "+6018-282 2320",
    address:
      "D-3A-06 & D-3A-07, Menara Suezcap 1, KL Gateway, No. 2 Jalan Kerinchi, Gerbang Kerinchi Lestari, 59200 Kuala Lumpur, Malaysia",
    logoUrl: "https://www.policystreet.com/wp-content/uploads/ps-logo-1.png",
  },
};

export const INSURER_FULL_NAME = {
  PolicyStreet: "Pacific & Orient Insurance Co. Bhd.",
  MSIG: "MSIG Insurance (Malaysia) Berhad",
  Pando: "Pacific & Orient Insurance Co. Bhd.",
  AXA: "Generali Insurance Malaysia Berhad",
  Sompo: "Berjaya Sompo Insurance Berhad",
  Liberty: "Liberty General Insurance Berhad",
  Ikhlas: "Takaful Ikhlas General Berhad",
  Allianz: "Allianz General Insurance Company (Malaysia) Berhad",
  Etiqa: "Etiqa General Takaful Berhad",
  Tune: "Tune Insurance Malaysia Berhad",
  Zurich: "Zurich General Insurance Malaysia Berhad",
  RHB: "RHB Insurance Berhad",
  Kurnia: "Liberty General Insurance Berhad",
  Pacific: "Pacific Insurance Bhd",
  Chubb: "Chubb Insurance Malaysia Berhad",
  TMIM: "Tokio Marine Insurans (Malaysia) Berhad",
  Takaful_Malaysia: "Syarikat Takaful Malaysia Am Berhad"
};

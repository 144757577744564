import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PartnerService } from "src/app/share/service/partner.service";
import { TranslateService } from "@ngx-translate/core";
import { PartnerInfoInterface } from "src/app/share/interface/partner.interface";
import { PARTNER_INFO } from "src/app/share/constants/partner.info";
import { COMMON_INFO } from "src/app/share/constants/common.info";
import { FooterMarginService } from "src/app/share/service/footer-margin.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	readonly _commonInfo = COMMON_INFO;
	readonly _partnerInfo = PARTNER_INFO;
	partnerInfo: PartnerInfoInterface;
	isCustomFooter: Boolean = false;
	partnerCode: string;
	stepperIndex: number;
	component: string;

	pathName: string = window.location.pathname;

	constructor(
		private partnerService: PartnerService,
		public translate: TranslateService,
		private footerMarginService: FooterMarginService,
	) {
		this.partnerCode = this.partnerService.getPartnerCode() || "ps";

		//If the partner requires a custom footer information to be displayed
		if (this._partnerInfo[this.partnerCode] !== undefined) {
			this.isCustomFooter = true;
			this.partnerInfo = this._partnerInfo[this.partnerCode];
		}
	}

	isCarsomeGroup(): boolean {
		return ['carsome', 'wapcar', 'carlist'].includes(this.partnerCode);
	}

	ngOnInit(): void {
		this.footerMarginService.stepperIndex$.subscribe((index) => {
			this.stepperIndex = index;
		});
		this.footerMarginService.component$.subscribe((component) => {
			this.component = component;
		});
	}

	getFooterClasses(): any {
		if (this.component === "ComparisonComponent" && (this.stepperIndex >= 1 && this.stepperIndex != 3)) {
			return "comparison-mobile-view-footer";
		} else if (this.component === "PlansComponent") {
			return "plans-mobile-view-footer";
		} else if (this.component === "TopupRequestComponent") {
			return "topup-request-mobile-view-footer";
		} else {
			return;
		}
	}

	// private getPartnerCode() {
	// 	this.partnerCode = this.partnerService.getPartnerCode();
	// 	!this.partnerCode ? (this.partnerCode = "ps") : null;
	// 	if (this.customFooterContact.includes(this.partnerCode)) {
	// 		this.isCustomFooterContact = true;
	// 	}
	// 	if (this.hideFooterList.includes(this.partnerCode)) {
	// 		this.hideFooter = true;
	// 	}
	// }
}

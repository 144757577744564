<div class="dialog-header" [ngClass]="{ 'green-bg': data.isAffiliate }">
  <img src="assets/image/submit-dialog.svg" />
  <div>{{ "fail.requestFailed" | translate }}</div>
</div>

<div class="dialog-content">
  <ng-container *ngIf="data.isWithEHailing; else notEhailing">
    <div
      [innerHTML]="
        'fail.vehicleAgeLimitWithEHailing' | translate : { numOfYears: maxYear }
      "
    ></div>
  </ng-container>

  <ng-template #notEhailing>
    <ng-container *ngIf="data.isTpftVehicleOverage; else notTpft">
      <div [innerHTML]="'fail.tpftVehicleAgeLimit' | translate"></div>
    </ng-container>

    <ng-template #notTpft>
      <div
        [innerHTML]="
          'fail.vehicleAgeLimit' | translate : { numOfYears: maxYear }
        "
      ></div>

      <ng-container *ngIf="data.partnerCode !== 'ktmb'">
        <div [innerHTML]="'fail.vehicleAgeLimit2' | translate"></div>

        <div>{{ "common.thankyou" | translate }}.</div>
      </ng-container>
    </ng-template>
  </ng-template>
</div>

<div class="dialog-footer">
  <button
    *ngIf="showReqBtn"
    class="btn"
    [ngClass]="data.isAffiliate ? 'green-btn' : 'btn-primary'"
    mat-stroked-button
    mat-dialog-close
  >
    {{ "fail.requestQuote" | translate }}
  </button>
  <button
    class="btn"
    [ngClass]="{ 'green-btn-outline': data.isAffiliate }"
    mat-stroked-button
    (click)="onBackToHome()"
  >
    {{ "success.close" | translate }}
  </button>
</div>

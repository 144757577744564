import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-submit-fail-dialog",
  templateUrl: "./submit-fail-dialog.component.html",
  styleUrls: ["./submit-fail-dialog.component.scss"]
})
export class SubmitFailDialogComponent {
  maxYear: number;
  showReqBtn: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isWithEHailing: boolean;
      isAffiliate?: boolean;
      isTpftVehicleOverage?: boolean;
      partnerCode?: string;
    }
  ) {
    this.maxYear = data.isWithEHailing ? 15 : 20;

    if (
      !data.isWithEHailing &&
      !data.isTpftVehicleOverage &&
      data.partnerCode !== "ktmb"
    ) {
      this.showReqBtn = true;
    }
  }

  onBackToHome(): void {
    window.location.reload();
  }
}

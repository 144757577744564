import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { Component, HostListener, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { PAYMENT_OPTION_EWALLET, PAYMENT_OPTION_FP, PAYMENT_OPTION_RAZER, PAYMENT_OPTION_VISA } from "src/app/share/constants/payment-options";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import {
	QuotationInterface,
	ProductDocumentInterface,
	TRPaymentPayloadInterface
} from "src/app/share/interface/motor.interface";
import { AlertService } from "src/app/share/service/alert.service";
import { FooterMarginService } from "src/app/share/service/footer-margin.service";
import { ScreenSizeService } from "src/app/share/service/screen-size.service";
import { TopupRequestDataInterface } from "./topup-request.interface";
import { STATE_LIST } from "src/app/d2c/static/general-data";

@Component({
	selector: "app-topup-request",
	templateUrl: "./topup-request.component.html",
	styleUrls: ["./topup-request.component.scss"],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { displayDefaultIndicatorType: false },
		},
	],
})
export class TopupRequestComponent implements OnDestroy {
	screenWidth: number;
	paymentFormGroup: FormGroup;
	paymentOptionFullPrice: string = PAYMENT_OPTION_FP;
	paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
	paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
	paymentOptionVisa: string = PAYMENT_OPTION_VISA;

	id: string;
	topupUserData: TopupRequestDataInterface;


	@ViewChild("stepper") private stepper: MatStepper;

	stateList: string[] = STATE_LIST;

	filterStateList: string[] = Object.assign([], this.stateList);
	filterDeliveryStateList: string[] = Object.assign([], this.stateList);

	productDocumentInterface: ProductDocumentInterface[] = [];

	selectedPayment: string = "IPay88";
	allowEditQuotation: boolean = false;

	isSameAddressCheck: boolean;
	paymentChannelSub: Subscription;

	constructor(
		public translate: TranslateService,
		private router: Router,
		private alertService: AlertService,
		private activatedRoute: ActivatedRoute,
		private ngxSpinnerService: NgxSpinnerService,
		private motorDataDataService: MotorDataDataService,
		public screenSizeService: ScreenSizeService,
		private footerMarginService: FooterMarginService
	) {
		this.screenWidth = window.innerWidth;
		this.id = activatedRoute.snapshot.params.id;

		this.motorDataDataService.getPolicyTopUpDetail(this.id).subscribe({
			next: (x: any) => {
				this.topupUserData = x;
			},
			error: () => {
				this.alertService.openSnackBar("Something went wrong")
			},
		});
	}
		
	ngOnInit() {
		this.initPaymentFormGroup();
		this.screenSizeService.notifyResize();
		window.addEventListener('resize', () => this.screenSizeService.notifyResize());
		this.footerMarginService.updateComponent('TopupRequestComponent');
	}
	
	ngOnDestroy(): void {
		this.footerMarginService.updateComponent(null);
		this.paymentChannelSub.unsubscribe();
	}

	private initPaymentFormGroup() {
		this.paymentFormGroup = new FormGroup({
			paymentType: new FormControl(this.paymentOptionFullPrice, [Validators.required]),
			paymentProvider: new FormControl(this.paymentOptionRazer, [Validators.required]),
			paymentChannel: new FormControl('', []),
			ewalletType: new FormControl('', []),
			// For EPP only
			bank: new FormControl('', []),
			installmentMonth: new FormControl(0, []),
			paymentOptionFPType: new FormControl(this.paymentOptionRazer, [
				Validators.required,
			]),
		})
		let ewalletType = this.paymentFormGroup.controls.ewalletType;

		// To toggle e-wallet dropdown to be required/not
		this.paymentChannelSub = this.paymentFormGroup.controls.paymentChannel.valueChanges.subscribe((x) => {
			if(x == this.paymentOptionEwallet){
				ewalletType?.setValidators([Validators.required]);
			} else {
				ewalletType?.setValidators([]);
			}
			ewalletType?.updateValueAndValidity();
		})
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.screenWidth = event.target.innerWidth;
	}

	paymentTypeChange(paymentType: string) {
		this.selectedPayment = paymentType;
	}

	handleSubmit() {
		this.router.navigate([], {
			queryParams: {
				id: this.id,
				pay: true,
			},
		});
		this.ngxSpinnerService.show();

		let paymentFGroup = this.paymentFormGroup?.value;
		// To change payment channel for e-wallet and EPP
		if(paymentFGroup?.paymentChannel == this.paymentOptionEwallet) 
			paymentFGroup.paymentChannel = this.paymentFormGroup?.value?.ewalletType;

		var value: TRPaymentPayloadInterface = {
			paymentType: "FP",
			paymentChannel: paymentFGroup.paymentChannel || this.paymentOptionVisa,
		}

		this.motorDataDataService.generateTopupPayment(this.id, value).subscribe({
			next: (x) => {
				let navigationExtras: NavigationExtras = {
					queryParams: {
						id: x.id,
					},
				};
				this.ngxSpinnerService.hide();
				this.router.navigate(["/payment"], navigationExtras);
			},
			error: (err) => {
				this.alertService.openSnackBar(err.error);
				this.ngxSpinnerService.hide();
			},
		});
	}

	getTotalPromo() {
		return this.topupUserData?.policyDetail?.promoCodePrice;
	}

	getIdentityNoLabel() {
		if (this.topupUserData?.policyDetail?.isMalaysian && !this.topupUserData?.policyDetail?.isCompany)
			return "common.icNo";
		else if (!this.topupUserData?.policyDetail?.isMalaysian) return "common.passport";
		return "common.businessRegistrationNo";
	}

	getTax() {
		if (!this.topupUserData?.policyDetail) return 0;
		let total = this.getGrossPremium();
	  
		// Round the result to two decimal places
		const tax = total * this.topupUserData?.policyDetail?.taxRate;
		return Number(tax.toFixed(2));
	}

	// Get total base premium
	getBasePremium() {
		return this.topupUserData?.policyDetail?.totalBasePremium;
	}

	getTotalNetPremium() {
		return (
			this.getGrossPremium() + this.getTax() + this.topupUserData?.policyDetail?.stampDutyPrice
		);
	}

	getPlatformDiscountRate() {
		return this.topupUserData?.policyDetail?.platformPrice
			? this.topupUserData?.policyDetail?.platformPrice
			: 0;
	}

	getTotalCharge() {
		let total = this.getTotalNetPremium() - this.getPlatformDiscountRate();
		if (this.topupUserData?.policyDetail?.includeRoadTax) {
			total =
				total +
				this.topupUserData?.policyDetail?.roadTaxPrice +
				this.topupUserData?.policyDetail?.roadTaxDeliveryPrice;
		}
		if (this.topupUserData?.policyDetail?.includeDigitalRoadTax) {
			total = total - this.topupUserData?.policyDetail?.roadTaxDeliveryPrice;
		}
		return total;
	}

	getServiceFee() {
		if (!this.topupUserData?.policyDetail) return 0;

		// note service tax rate changed to tax rate
		return this.topupUserData?.policyDetail?.servicePrice;
	}

	getTotal() {
		return (
			this.getTotalCharge() +
			this.getServiceFee() -
			this.getTotalPromo() +
			this.topupUserData?.topUpRequest?.amount
		);
	}

	getTotalPaid() {
		return this.getTotal() - this.topupUserData?.topUpRequest?.amount;
	}

	getTotalAddOnPrice() {
		let total = 0;

		this.topupUserData?.addOn.forEach((x: any) => {
			total += x.amount;
		});
		return total;
	}

	getTotalBundlePrice() {
		let total = 0;
		this.topupUserData?.policyBundle.forEach((x) => {
			total += x.amount;
		});
		return total;
	}

	getTotalBundleAddonPrice() {
		let total = 0;
		this.topupUserData?.policyBundleAddOn.forEach((x) => {
			total += x.amount;
		});
		return total;
	}

	getGrossPremium() {
		if (!this.topupUserData?.policyDetail) return 0;

		return (
			this.getBasePremium() +
		 	this.getTotalAddOnPrice()+
			this.getTotalBundlePrice() +
			this.getTotalBundleAddonPrice()
		);
	}

	getNameLabel() {
		if (this.topupUserData?.policyDetail?.isMalaysian && !this.topupUserData?.policyDetail?.isCompany)
			return "common.namePerIc";
		else if (!this.topupUserData?.policyDetail?.isMalaysian) return "common.namePerPassport";
		return "common.companyName";
	}

	getNCD() {
		if (!this.topupUserData?.policyDetail) return 0;
		return this.topupUserData?.policyDetail?.basePremium - this.topupUserData?.policyDetail?.totalBasePremium;
	}

	getTopupAmount() {
		if (!this.topupUserData?.policyDetail) return 0;
		return this.getTotal() - this.getTotalPaid();
	}

	getServiceCharge() {
		//const digiRoadTaxServiceFeeRateIncrease = 0.002;
		let defaultServiceCharge = this.topupUserData?.policyDetail?.serviceTaxRate;
		let serviceCharge;

		// if(this.topupUserData?.policyDetail?.includeDigitalRoadTax) {
		// 	defaultServiceCharge = this.topupUserData?.policyDetail?.serviceTaxRate +  digiRoadTaxServiceFeeRateIncrease;
		// }
		serviceCharge = defaultServiceCharge * this.getTopupAmount();

		return Number(serviceCharge.toFixed(2));
	}

	getUnpaidRemainingAmount() {
		return this.getTopupAmount() + this.getServiceCharge();
	}

	getAddOnCode(code) {
		return `addOn.${code}`;
	}

	addonLabel(value: number) {
		return "RM " + value.toFixed(2).toLocaleString();
	}
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import * as moment from "moment";
import { MotorDataDataService } from "../../data-service/motor.data.service";

@Component({
	selector: "app-submit-success-av-dialog",
	templateUrl: "./submit-success-av-dialog.component.html",
	styleUrls: ["./submit-success-av-dialog.component.css"],
})
export class SubmitSuccessAvDialogComponent {
	onOperation: boolean;

	constructor(
		gtmService: GoogleTagManagerService,
		private motorDataService: MotorDataDataService
	) {
		gtmService.pushTag({
			event: "car_leads",
		});

    this.isOnOperation();
	}

  private isOnOperation() {
    this.motorDataService.checkIsOfficeHour().subscribe((response) => {
      this.onOperation = response === "YES";
    });
  }
}
